import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { fetchCurrentUserInfos } from "../../api/userAPI";
import { fetchSubscription } from "../../api/subsciptionAPI";
import { fetchParticipantByUserId } from "../../api/groupOrdersParticipantsAPI";

import ClientOrdersList from "../../components/client-dashboard/ClientOrdersList";
import ClientNavbar from "../../components/client-dashboard/ClientNavbar";
import ClientSubscription from "../../components/client-dashboard/ClientSubscription";
import Recipes from "../admin-dashboard/recipes/Recipes";
import MenuList from "../admin-dashboard/menu-maker/MenuList";
import MobileMenuList from "../../components/admin-dashboard/menus/MobileMenuList";
import MenuMaker from "../admin-dashboard/menu-maker/menu/MenuMaker";
import MobileOrderList from "../../components/client-dashboard/MobileOrderList";
import MobileRecipeList from "../../components/admin-dashboard/recipes/mobile/MobileRecipeList";
import GroupOrdersTracking from "./GroupOrdersTracking";
import MobileProduct from "../../components/admin-dashboard/stock/MobileProduct";
import Stock from "../admin-dashboard/stock/Stock";
import ClientProfil from "../../components/client-dashboard/ClientProfil";

import "../admin-dashboard/AdminDashboard.css";

function DashboardClient() {
    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");
    const { id } = useParams();

    const [userInfo, setUserInfo] = useState({});
    const [subscriptionExists, setSubscriptionExists] = useState(false);
    const [subscriptionInfos, setSubscriptionInfos] = useState(null);
    const [selectedSection, setSelectedSection] = useState("profil");
    const [groupOrderExist, setGroupOrderExist] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAllData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const userInfoData = await fetchCurrentUserInfos();
                setUserInfo(userInfoData);

                const [subscriptionData, participantData] = await Promise.all([
                    fetchSubscription(userInfoData.id),
                    fetchParticipantByUserId(userInfoData.id),
                ]);

                setSubscriptionExists(Boolean(subscriptionData?.active));
                setSubscriptionInfos(subscriptionData?.active ? subscriptionData : null);
                setGroupOrderExist(Boolean(participantData));

            } catch (err) {
                setError("Une erreur est survenue lors de la récupération des données.");
                console.error("Erreur:", err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAllData();
    }, []);

    const renderSection = () => {
        if (isLoading) return <div>Chargement de vos données...</div>;
        if (error) return <div className="error-message">{error}</div>;

        switch (selectedSection) {
            case "orders":
                return isTablet ? <MobileOrderList /> : <ClientOrdersList />;
            case "group-orders":
                return groupOrderExist ? <GroupOrdersTracking /> : <div>Vous n'avez pas de commandes de groupe en cours.</div>;
            case "profil":
                return <ClientProfil userInfo={userInfo} />;
            case "subscriptions":
                return subscriptionExists ? (
                    <div>
                        <h2>Abonnement actif</h2>
                        <p>Type d'abonnement : {subscriptionInfos.subscription_type}</p>
                    </div>
                ) : (
                    <ClientSubscription userInfo={userInfo} />
                );
            case "stocks":
                return subscriptionExists && subscriptionInfos?.subscription_type === "pro_chef" ? (
                    <Stock userInfo={userInfo} />
                ) : (
                    <div>Accès réservé aux abonnés Pro Chef.</div>
                );
            case "recipes":
                return subscriptionExists ? (
                    isTablet ? <MobileRecipeList /> : <Recipes />
                ) : (
                    <div>Accès réservé aux abonnés actifs. Veuillez souscrire à un abonnement pour accéder aux recettes.</div>
                );
            case "menulist":
                return subscriptionExists ? (
                    isTablet ? <MobileMenuList /> : <MenuList />
                ) : (
                    <div>Accès réservé aux abonnés actifs. Veuillez souscrire à un abonnement pour accéder aux menus.</div>
                );
            case "menumaker":
                return subscriptionExists ? (
                    <MenuMaker menuId={id} />
                ) : (
                    <div>Accès réservé aux abonnés actifs. Veuillez souscrire à un abonnement pour accéder au créateur de menus.</div>
                );
            default:
                return <ClientProfil userInfo={userInfo} />;
        }
    };

    return (
        <section className="admin-panel">
            <ClientNavbar
                onSelectSection={setSelectedSection}
                subscriptionExists={subscriptionExists}
                userInfo={userInfo} // Passer userInfo ici
                groupOrderExist={groupOrderExist} // Passer groupOrderExist ici
            />
            <section className="admin-dashboard container">
                {renderSection()}
            </section>
        </section>
    );
}

export default DashboardClient;