import apiInstance from "./api";

/**
 * Initiates a Stripe payment session.
 * @param {string} order_num - The order number.
 * @param {string} token - The user's access token.
 * @returns {Promise<string>} - The URL to redirect the user for payment.
 */
export const initiateStripePayment = async (order_num, token, currentCart) => {
  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.post(
      `${process.env.REACT_APP_API_URL}/stripe/create-payment-intent`,
      { order_num, currentCart}, // Send only the order_num
      { headers }
    );

    return response.data.url; // Assuming the API returns the payment URL in response.data.url
  } catch (error) {
    console.error(
      "Error initiating Stripe payment:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
