import React, { useState, useEffect } from 'react';

// ReactJS Popup
import Popup from 'reactjs-popup';

// MUI components
import Checkbox from '@mui/joy/Checkbox';

// Toast
import toast from 'react-hot-toast';

// API
import { fetchAllAllergies } from "../../../../api/allergiesAPI";
import { fetchCurrentUserAllergies } from "../../../../api/userAPI";
import { updateAllergiesByUserId } from "../../../../api/allergiesAPI"; // Import of the update function

function PopupAllergy({ trigger, userId, onClose, onConfirm }) {
    // Local states
    const [allAllergies, setAllAllergies] = useState([]);
    const [selectedAllergies, setSelectedAllergies] = useState([]);

    // Fetch all allergies and current user's allergies
    useEffect(() => {
        const fetchData = async () => {
            try {
                const allAllergiesData = await fetchAllAllergies();
                setAllAllergies(allAllergiesData);

                const currentUserAllergiesData = await fetchCurrentUserAllergies(userId);
                setSelectedAllergies(currentUserAllergiesData.map(allergy => allergy.id));
            } catch (error) {
                toast.error("Erreur lors de la récupération des allergies.");
            }
        };

        fetchData();
    }, [userId]);

    // Handle checkbox change
    const handleCheckboxChange = (allergyId) => {
        setSelectedAllergies(prevState =>
            prevState.includes(allergyId)
                ? prevState.filter(id => id !== allergyId)
                : [...prevState, allergyId]
        );
    };

    // Save updated allergies
    const handleSave = async (close) => {
        try {
            await updateAllergiesByUserId(userId, selectedAllergies);
            toast.success("Les allergies ont été mises à jour");
            if (onConfirm) {
                onConfirm(selectedAllergies); // Call the onConfirm callback with the selected allergies
            }
            close();
        } catch (error) {
            console.error("Erreur lors de la mise à jour des allergies", error);
            toast.error("Erreur lors de la mise à jour des allergies");
        }
    };

    return (
        <Popup
            trigger={trigger}
            position="right center"
            modal
            contentStyle={{ zIndex: 99999 }}
            onClose={onClose} // Call the onClose callback when the popup is closed
        >
            {(close) => (
                <div className="popup-allergy-content">
                    <div className="popup-title">
                        <h1>Gérer vos allergies</h1>
                    </div>
                    <div className="popup-allergy-checkboxes">
                        {allAllergies.map((allergy) => (
                            <div key={allergy.id} className="popup-allergy-item">
                                <Checkbox
                                    checked={selectedAllergies.includes(allergy.id)}
                                    onChange={() => handleCheckboxChange(allergy.id)}
                                    label={allergy.name}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="popup-actions flex-center">
                        <button className="btn-full btn-sm" onClick={() => handleSave(close)}>
                            Sauvegarder
                        </button>
                        <button className='btn-border btn-sm' onClick={close}>
                            Annuler
                        </button>
                    </div>
                </div>
            )}
        </Popup>
    );
}

export default PopupAllergy;
