import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

//API
import { fetchCurrentUserInfos } from "../../../api/userAPI";
import { pikizyCheckInAdmin } from "../../../api/pikizyAPI"

//Elements
import Button from '../../elements/button/Button';
import PopupConfirm from "../../elements/popup/popup-confirm/PopupConfirm";

//Icons
import { LuUserCircle } from "react-icons/lu";
import { TbTruckDelivery } from "react-icons/tb";
import { CiBoxes } from "react-icons/ci";
import { IoRestaurantOutline } from "react-icons/io5";
import { PiBowlFood } from "react-icons/pi";

//toast
import toast from 'react-hot-toast';

// Styles
import '../../../views/admin-dashboard/AdminDashboard.css';

function AdminNavbar({ onSelectSection }) {
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const data = await fetchCurrentUserInfos();
                setUserInfo(data);
            } catch (error) {
                console.error(
                    "Erreur lors de la récupération des informations de l'utilisateur:",
                    error
                );
            }
        };

        fetchUserInfo();
    }, []);

    const handleOpenFridge = async () => {
        try {
            await pikizyCheckInAdmin();
            toast.success('Frigo ouvert avec succès');
        } catch (error) {
            toast.error("Erreur lors de l'ouverture du frigo");
        }
    };

    const handleSectionClick = (section) => {
        onSelectSection(section);
    };

    return (
        <nav className="admin-navbar">
            <div className="admin-title">
                <h1>Bonjour {userInfo.firstname || "Stranger"}</h1>
            </div>
            <Link className='admin-navbar-links' onClick={() => handleSectionClick("users")}>
                <LuUserCircle className='admin-navbar-icons' size={15} />
                Utilisateurs
            </Link>
            <Link className='admin-navbar-links' onClick={() => handleSectionClick("orders")}>
                <TbTruckDelivery className='admin-navbar-icons' size={15} />
                Commandes
            </Link>
            <Link className='admin-navbar-links' onClick={() => handleSectionClick("group-orders")}>
                <TbTruckDelivery className='admin-navbar-icons' size={15} />
                Commandes groupées
            </Link>
            <Link className='admin-navbar-links' onClick={() => handleSectionClick("stock")}>
                <CiBoxes className='admin-navbar-icons' size={15} />
                Stocks
            </Link>
            <Link className='admin-navbar-links' onClick={() => handleSectionClick("recipes")}>
                <IoRestaurantOutline className='admin-navbar-icons' size={15} />
                Recettes
            </Link>
            <Link className='admin-navbar-links' onClick={() => handleSectionClick("menulist")}>
                <PiBowlFood className='admin-navbar-icons' size={15} />
                Menu Maker
            </Link>
            <PopupConfirm
                trigger={
                    <Button className="admin-navbar-btn" type="button" text="Ouvrir le frigo" />
                }
                onConfirm={handleOpenFridge}
                message={`Êtes-vous sûr de vouloir ouvrir le frigo ?`}
            />
        </nav>
    );
}

export default AdminNavbar;
