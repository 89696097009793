import { useState, useEffect, useMemo } from "react";
import { Helmet } from 'react-helmet';

//Components
import RecipeHeader from "./RecipeHeader";
import RecipeImage from "./RecipeImage";
import RecipeNameForm from "./RecipeNameForm";
import RecipeDetailsContainer from "./RecipeDetailsContainer";
import toast from "react-hot-toast";

// API
import { fetchRecipeById, updateRecipeById, updateRecipeImage } from "../../../api/recipesAPI";

const partIdLabels = {
    1: "1 — Entrées",
    2: "2 — Plats",
    3: "3 — Desserts",
    4: "4 — Boissons",
};

function RecipesDetails({ recipeId, pictures, nutriFacts }) {
    // Local States
    const [recipe, setRecipe] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editingField, setEditingField] = useState(null);
    const [imageKey, setImageKey] = useState(0);

    const [editedFields, setEditedFields] = useState({
        name: "",
        price: "",
        barcode: "",
        partId: "",
        visibility: false,
        description: "",
        steps: "",
        prep_time: "",
    });

    useEffect(() => {
        handleSaveClick();
    }, [editedFields.visibility]);

    // Get the recipe image URL
    const imageUrl = useMemo(() => {
        // Log the pictures data to check what is being filtered
        console.log("Liste des images pour cette recette:", pictures);
        // Filter images by recipe ID
        const filteredPictures = pictures.filter(pic => pic.recipe_id == recipeId);
        // Check if there are filtered images
        if (filteredPictures.length > 0) {
            // Log the filtered pictures
            console.log("Images filtrées:", filteredPictures);
            // Return the full URL of the first filtered image
            return `${process.env.REACT_APP_API_URL}${filteredPictures[0].path}`;
        }
        return undefined;
    }, [recipeId, pictures]);

    // Fetch recipe data from API when the component mounts
    useEffect(() => {
        const getRecipeData = async () => {
            try {
                const recipeData = await fetchRecipeById(recipeId);
                setRecipe(recipeData);

                // Set the initial edited fields with the fetched recipe data
                setEditedFields({
                    name: recipeData.name,
                    price: recipeData.price.toString(),
                    barcode: recipeData.barcode,
                    partId: recipeData.partId.toString(),
                    visibility: recipeData.visibility,
                    description: recipeData.description,
                    steps: recipeData.steps,
                    prep_time: recipeData.prep_time,
                });
            } catch (error) {
                console.error("Erreur lors de la récupération de la recette :", error);
            }
        };

        getRecipeData();
    }, [recipeId]);

    // Handle the click on the edit button
    const handleEditClick = (field) => {
        setEditingField(field);
        setEditedFields({
            name: recipe.name,
            price: recipe.price.toString(),
            barcode: recipe.barcode,
            partId: recipe.partId.toString(),
            visibility: recipe.visibility,
            description: recipe.description,
            steps: recipe.steps,
            prep_time: recipe.prep_time
        });
        setIsEditing(true);
    };

    // Send the new editable fields to the API
    const handleSaveClick = async () => {
        try {
            const updatedRecipeData = {
                name: editedFields.name,
                price: parseFloat(editedFields.price),
                barcode: editedFields.barcode,
                partId: parseInt(editedFields.partId),
                visibility: editedFields.visibility,
                description: editedFields.description,
                steps: editedFields.steps,
                prep_time: editedFields.prep_time
            };

            await updateRecipeById(recipeId, updatedRecipeData);

            setRecipe((prevRecipe) => ({
                ...prevRecipe,
                ...updatedRecipeData,
            }));

            setIsEditing(false);
            setEditingField(null);
        } catch (error) {
            console.error("Error while saving the recipe:", error);
        }
    };

    const handleInputChange = (field, value) => {
        setEditedFields((prevFields) => ({
            ...prevFields,
            [field]: value,
        }));
    };

    // Handle image update 
    const handleImageUpdate = async () => {
        try {
            if (!selectedImage) {
                console.error("Please select an image");
                return;
            }
            await updateRecipeImage(recipeId);
            setImageKey((prevKey) => prevKey + 1);
            toast.success("L'image a été mise à jour");
        } catch (error) {
            console.error("Error updating image:", error);
        }
    };

    if (!recipe) {
        return <div>Loading...</div>;
    }

    return (
        <section className="recipes-details container">
            <Helmet>
                <title>{recipe ? recipe.name : "Chargement..."}</title>
            </Helmet>
            <RecipeHeader recipe={recipe} isEditing={isEditing} nutriFacts={nutriFacts} onEditClick={() => handleEditClick("name")} />
            {isEditing && editingField === "name" && (
                <RecipeNameForm
                    recipe={recipe}
                    editedFields={editedFields}
                    onSaveClick={handleSaveClick}
                    onInputChange={handleInputChange}
                />
            )}
            <RecipeImage key={imageKey} recipe={recipe} pictures={pictures} recipeId={recipeId} imageUrl={imageUrl} onImageUpdated={handleImageUpdate} />
            <RecipeDetailsContainer
                recipe={recipe}
                isEditing={isEditing}
                editingField={editingField}
                editedFields={editedFields}
                partIdLabels={partIdLabels}
                handleEditClick={handleEditClick}
                handleSaveClick={handleSaveClick}
                handleInputChange={handleInputChange}
            />
        </section>
    );
}

export default RecipesDetails;
