//Components
import OrdersTable from "../../../components/admin-dashboard/order-list/OrdersTable";

export default function AllOrders() {


    return (
        <div className="orders-table">
            <div className="orders-table-title flex-center">
                <h1 style={{ margin: "1rem 0" }}>Liste des commandes</h1>
            </div>
            <OrdersTable />
        </div>
    );
}
