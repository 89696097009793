import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedAddress, setSelectedAddressAsync } from '../../features/preorder/preorderSlice';
import { fetchUserAddresses, createAddress } from "../../api/addressesAPI";
import { FormControl, FormControlLabel, Radio, RadioGroup, FormLabel } from "@mui/material";
import PopupAddress from '../elements/popup/popup-address/PopupAddress';
import toast from 'react-hot-toast';

function Addresses() {
    const [addresses, setAddresses] = useState([]);
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const dispatch = useDispatch();

    // Initialiser les adresses et synchroniser l'adresse sélectionnée avec le Redux store au chargement du composant
    useEffect(() => {
        const getAllAddresses = async () => {
            try {
                const fetchedUserAddresses = await fetchUserAddresses();
                setAddresses(fetchedUserAddresses);
                const storedAddressId = localStorage.getItem('selectedAddress');
                if (storedAddressId) {
                    setSelectedAddressId(storedAddressId);
                    dispatch(setSelectedAddress(storedAddressId)); // Mise à jour de Redux avec l'adresse du localStorage
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des adresses:", error);
                toast.error("Erreur lors de la récupération des adresses.");
            }
        };
        getAllAddresses();
    }, [dispatch]);

    const handleAddressChange = (event) => {
        const newSelectedAddressId = event.target.value;
        setSelectedAddressId(newSelectedAddressId);
        dispatch(setSelectedAddressAsync(newSelectedAddressId));
        localStorage.setItem('selectedAddress', newSelectedAddressId); // Mise à jour du localStorage
    };

    const addNewAddressToList = (newAddress) => {
        setAddresses((prevAddresses) => [...prevAddresses, newAddress]);
    };

    return (
        <section className='order-addresses'>
            <div className="order-addresses-title">
                <h2>Où voulez-vous être livré ?</h2>
            </div>
            <div className="order-addresses-actions">
                <PopupAddress
                    trigger={
                        <button className="order-addresses-add btn-add">+ Ajouter une adresse</button>
                    }
                    onCreate={async (formData) => {
                        const newAddress = await createAddress(formData);
                        addNewAddressToList(newAddress);
                    }}
                />
            </div>
            <div className="order-addresses-select">
                <FormControl component="fieldset">
                    <FormLabel component="legend">Sélectionnez une adresse :</FormLabel>
                    <RadioGroup
                        aria-label="addresses"
                        name="addresses"
                        value={selectedAddressId}
                        onChange={handleAddressChange}
                    >
                        {addresses.map((address) => (
                            <FormControlLabel
                                key={address.id}
                                value={address.id.toString()}
                                control={<Radio />}
                                label={`${address.address}, ${address.zip_code}, ${address.city}`}
                                className='address-radio'
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </div>
        </section >
    );
}

export default Addresses;
