import React, { useState, useEffect } from "react";
import { initiateStripePayment } from "../../api/stripeAPI";
import { useDispatch } from "react-redux";
import { resetCart } from "../../features/cart/cartSlice";

function Checkout() {
  const [order_num, setOrderNum] = useState(null);
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
  const dispatch = useDispatch();

  const [paymentUrl, setPaymentUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initiatePayment = async () => {
      setLoading(true);
      console.log("Initiating payment...");

      try {
        const currentOrder = JSON.parse(localStorage.getItem("currentOrder"));
        const currentCart = JSON.parse(localStorage.getItem("cart"));
        if (currentOrder) {
          const orderData = currentOrder.data; // Adjust this line
          setOrderNum(orderData.order_num);
        
          // Pass only the order_num to initiateStripePayment
          const url = await initiateStripePayment(orderData.order_num, token, currentCart);
          setPaymentUrl(url);
        } else {
          console.error("No current order data found in localStorage.");
        }
      } catch (error) {
        console.error("Error initiating payment:", error);
      } finally {
        setLoading(false);
      }
    };

    initiatePayment();
  }, [token, dispatch]);

  if (loading) {
    return <p>Initializing payment...</p>;
  }

  if (paymentUrl) {
    localStorage.removeItem("cart");
    dispatch(resetCart());

    window.location.href = paymentUrl;
    return null;
  }

  return <div></div>;
}

export default Checkout;
