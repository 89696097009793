import React, { useEffect } from 'react';

const Paygreen = () => {
    const handleClick = () => {
        window.paygreenjs.submitPayment();
    };

    useEffect(() => {
        window.addEventListener("load", function () {
            window.paygreenjs.attachEventListener(
                window.paygreenjs.Events.FULL_PAYMENT_DONE,
                (event) => console.log("Payment success"),
            );

            window.paygreenjs.attachEventListener(
                window.paygreenjs.Events.REUSABLE_ALLOWED_CHANGE,
                (event) => console.log('REUSABLE_ALLOWED_CHANGE:', event.detail.reusable_allowed),
            );

            window.paygreenjs.attachEventListener(
                window.paygreenjs.Events.PAYMENT_FLOW_ONCHANGE,
                () => {
                    const flows = window.paygreenjs.status().flows;
                    const lastFlow = flows[flows.length - 1];
                    console.log('PAYMENT_FLOW_ONCHANGE - Flows:', flows);
                    console.log('PAYMENT_FLOW_ONCHANGE - Last Flow:', lastFlow);
                    if (lastFlow?.method) {
                        console.log('PAYMENT_FLOW_ONCHANGE - Display Pay Button');
                        document.querySelector('#payBtn').style.display = 'block';
                    } else {
                        console.log('PAYMENT_FLOW_ONCHANGE - Hide Pay Button');
                        document.querySelector('#payBtn').style.display = 'none';
                    }
                },
            );

            window.paygreenjs.attachEventListener(
                window.paygreenjs.Events.ERROR,
                (event) => {
                    console.error('Your custom error handling', event?.detail);
                }
            );

            window.paygreenjs.init({
                paymentOrderID: "po_a530cce49d944337acf619392726a9e9", // Ajoutez votre ID de commande de paiement
                objectSecret: "8618245cbd6136d4", // Ajoutez l'objectsecret de commande de paiement ici
                publicKey: "pk_e96d895b8ff04adc89eaa8b15e4184a9", // Ajoutez votre clé publique ici
                mode: "payment",
                style: {
                    input: {
                        base: {
                            color: 'black',
                            fontSize: '18px',
                        },
                    },
                }
            });
        });
    }, []);

    return (
        <div>
            Paygreen
            <div id="paygreen-container"></div>
            <div id="paygreen-methods-container"></div>
            <div id="yourCustomDiv">
                <div id="paygreen-pan-frame"></div>
                <div id="paygreen-cvv-frame"></div>
                <div id="paygreen-exp-frame"></div>
                <div id="paygreen-reuse-checkbox-container"></div>
                <button id="payBtn" type="button" onClick={handleClick} style={{ display: 'none' }}>
                    Pay
                </button>
            </div>
        </div>
    );
};

export default Paygreen;
