import * as React from 'react';

function PricingPage() {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("userId", user.id)
    console.log("user mail", user.email)

    return (
        <stripe-pricing-table 
    pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
    publishable-key={process.env.REACT_APP_PUBLISHABLE_KEY}
    client-reference-id={user ? user.id : ''}
    customer-email={user ? user.email : ''}>
</stripe-pricing-table>

    );
}

export default PricingPage;
