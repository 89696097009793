
import { FormControl, TextField, Button } from "@mui/material";

function RecipesNameForm({ recipe, editedFields, onSaveClick, onInputChange }) {

    return (
        <div className="recipes-details-name-form">
            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                <TextField
                    id="outlined-multiline-static"
                    className="recipes-details-description-field"
                    label="Editer le nom"
                    color="success"
                    defaultValue={recipe.name}
                    value={editedFields.name}
                    onChange={(e) => onInputChange("name", e.target.value)}
                />
            </FormControl>
            <Button onClick={onSaveClick} variant="text" color="success">
                Sauvegarder
            </Button>
        </div>
    );
}

export default RecipesNameForm;
