import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useMediaQuery } from "@mui/material";

//Components
import Dropdown from './dropdown/Dropdown';
import SideCart from '../side-cart/SideCart';

//Style
import './Navbar.css';

//Images and logos
import Badge from "@mui/material/Badge";
import Logo from '../../assets/logos/mamossa-logo.png';
import { VscAccount } from 'react-icons/vsc';
import { PiShoppingCartSimple } from 'react-icons/pi';

function Navbar() {
    //Global States
    const cartItems = useSelector(state => state.cart.items);
    const totalItemsInCart = cartItems?.reduce((acc, item) => acc + item.quantity, 0);

    // Local states
    const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
    const [cartDropdownOpen, setCartDropdownOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");



    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleAccountDropdown = () => {
        setAccountDropdownOpen(!accountDropdownOpen);
        if (cartDropdownOpen) {
            setCartDropdownOpen(false);
        }
    };

    const toggleCartDropdown = () => {
        setCartDropdownOpen(!cartDropdownOpen);
        if (accountDropdownOpen && isMobile) {
            setAccountDropdownOpen(false);
        }
        if (accountDropdownOpen && isTablet) {
            setAccountDropdownOpen(false);
        }
    };

    const closeCartDropdown = () => {
        if (isMobile || isTablet) {
            setCartDropdownOpen(false);
        }
    };

    const closeAccountDropdown = () => {
        setAccountDropdownOpen(false);
    };

    return (
        <div className="nav-container container">
            <nav className="navbar">
                <div className="navbar-left">
                    <a href="/" className="">
                        <img src={Logo} alt="Logo" className="navbar-logo" />
                    </a>
                </div>
                <div className="navbar-right">
                    <div className="navbar-icon" onClick={toggleAccountDropdown}>
                        <VscAccount size={27} />
                        {accountDropdownOpen && (
                            <div className="dropdown-desktop">
                                <Dropdown closeDropdown={closeAccountDropdown} />
                            </div>
                        )}
                    </div>

                    {(isMobile || isTablet) && (
                        <div className="navbar-icon-cart" onClick={toggleCartDropdown}>
                            <Badge badgeContent={totalItemsInCart} color="success">
                                <PiShoppingCartSimple size={27} />
                            </Badge>
                        </div>
                    )}
                </div>

                {(isMobile || isTablet) && (
                    <div
                        className={`dropdown-mobile-container ${accountDropdownOpen || cartDropdownOpen ? 'open' : ''}`}
                    >
                        {accountDropdownOpen && (
                            <div className="dropdown-mobile">
                                <Dropdown closeDropdown={closeAccountDropdown} />
                            </div>
                        )}

                        {cartDropdownOpen && (
                            <div className="dropdown-mobile-cart">
                                <SideCart closeCartDropdown={closeCartDropdown} isMobile={isMobile} />
                            </div>
                        )}
                    </div>
                )}
            </nav>
        </div>
    );
}

export default Navbar;
