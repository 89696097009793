import React, { useState, useEffect } from "react";
import { useNavigate, Route, Routes, useParams } from "react-router-dom";

//API
import { fetchCurrentUserInfos } from "../../api/userAPI";
import { pikizyCheckInAdmin } from "../../api/pikizyAPI";

//MUI Components
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';

//Toast
//toast
import toast from 'react-hot-toast';

// Styles
import "./AdminDashboard.css";

function AdminMobile() {

    const { id } = useParams();
    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const data = await fetchCurrentUserInfos();
                setUserInfo(data);
            } catch (error) {
                console.error(
                    "Erreur lors de la récupération des informations de l'utilisateur:",
                    error
                );
            }
        };

        fetchUserInfo();
    }, []);

    const handleUserClick = () => {
        navigate("/account/users-list");
    }

    const handleOrderClick = () => {
        navigate("/account/orders-list");
    }

    const handleGroupOrderClick = () => {
        navigate("/account/group-orders");
    }

    const handleProductClick = () => {
        navigate("/account/stock");
    }

    const handleRecipeClick = () => {
        navigate("/recipes");
    }

    const handleMenuMakerClick = () => {
        navigate("/account/menulist");
    }

    const handleOpenFridge = async () => {
        try {
            await pikizyCheckInAdmin();
            toast.success('Frigo ouvert avec succès');
        } catch (error) {
            toast.error("Erreur lors de l'ouverture du frigo");
        }
    };

    return (
        <>
            <div className="admin-mobile-header">
                <h2>Hello {userInfo.firstname} 👋</h2>
                <Typography level="body-ms" textColor={"#f5f5f5"} paddingLeft={'1rem'}> Que voulez-vous faire aujourd'hui?</Typography>
            </div>
            <div className="admin-mobile-actions">
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleUserClick}>
                    Gérer les utilisateurs
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleOrderClick}>
                    Gérer les commandes
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleGroupOrderClick}>
                    Commandes groupées
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleProductClick}>
                    Gérer les produits
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleRecipeClick}>
                    Gérer les recettes
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleMenuMakerClick}>
                    Menu Maker
                </Button>
                <Button color="danger" sx={{ width: "270px", height: '50px' }} variant="outlined" size="lg" onClick={handleOpenFridge}>
                    Ouvrir le frigo
                </Button>
            </div>
        </>
    );
}

export default AdminMobile;

const buttonStyle = {
    borderColor: "#006d26",
    color: "#006d26",
    width: "270px",
    height: '50px'
}