import React, { useState } from 'react';

// MUI
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// MUI Icons
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import KitchenOutlinedIcon from '@mui/icons-material/KitchenOutlined';

import "./Order.css"

function TypeOfDelivery() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isInDeliveryZone = JSON.parse(sessionStorage.getItem('isInDeliveryZone'));
    const preorderEnabled = JSON.parse(localStorage?.getItem('preorderEnabled'));
    const [selectedCard, setSelectedCard] = useState(null);

    const getCardStyles = (disabled) => ({
        width: 210,
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? 'none' : 'auto',
    });

    const handleCardClick = (card) => {
        if (isInDeliveryZone && !preorderEnabled) {
            setSelectedCard(card);
        }
    };

    const getGroupOrderStyles = () => {
        const disabled = isInDeliveryZone || preorderEnabled;
        return {
            color: !isInDeliveryZone ? "success" : "neutral",
            styles: getCardStyles(disabled),
        };
    };

    const getPreorderStyles = () => {
        const disabled = !isInDeliveryZone || !preorderEnabled;
        return {
            color: "neutral",
            styles: getCardStyles(disabled),
        };
    };

    const getUberDirectStyles = () => {
        const disabled = !isInDeliveryZone || preorderEnabled;
        const selected = selectedCard === 'uberDirect';
        return {
            color: selected ? "success" : "neutral",
            styles: getCardStyles(disabled),
        };
    };

    const getClickCollectStyles = () => {
        const disabled = !isInDeliveryZone || preorderEnabled;
        const selected = selectedCard === 'clickCollect';
        return {
            color: selected ? "success" : "neutral",
            styles: getCardStyles(disabled),
        };
    };

    return (
        <div className="delivery-type">
            {/* group order option */}
            <Card
                variant="soft"
                color={getGroupOrderStyles().color}
                sx={getGroupOrderStyles().styles}
            >
                <div className="delivery-card-title">
                    <GroupsOutlinedIcon sx={{ fontSize: "70px", color: !isInDeliveryZone ? "#006d26" : "inherit" }} />
                    <Typography sx={{ color: !isInDeliveryZone ? '#006d26' : 'inherit' }} level="title-sm">
                        Commande groupée
                    </Typography>
                </div>
                <CardContent orientation="horizontal">
                    <div className="delivery-card-title">
                        <Typography level="body-xs" sx={{ textAlign: "center", color: !isInDeliveryZone ? '#006d26' : '#000' }}>
                            Nous préparons votre commande une fois la nombre de participants atteints. Livraison 48 à 72h après préparation.
                        </Typography>
                    </div>
                </CardContent>
            </Card>

            {/* preorder option */}
            <Card
                variant="soft"
                color={getPreorderStyles().color}
                sx={getPreorderStyles().styles}
            >
                <div className="delivery-card-title">
                    <BallotOutlinedIcon sx={{ fontSize: "70px", color: "#006d26" }} />
                    <Typography level="title-sm" sx={{ color: preorderEnabled ? '#006d26' : 'inherit' }}>
                        Précommande
                    </Typography>
                </div>
                <CardContent orientation="horizontal">
                    <div className="delivery-card-title">
                        <Typography level="body-xs" sx={{ textAlign: "center", color: preorderEnabled ? '#006d26' : 'inherit' }}>
                            Commandez à l'avance des recettes qui ne sont pas actuellement disponibles, venez les retirer ou faites-vous livrer.
                        </Typography>
                    </div>
                </CardContent>
            </Card>

            {/* uber direct option */}
            {/* <Card
                variant="soft"
                color={getUberDirectStyles().color}
                sx={getUberDirectStyles().styles}
                onClick={() => handleCardClick('uberDirect')}
            >
                <div className="delivery-card-title">
                    <BoltOutlinedIcon sx={{ fontSize: "70px", color: "#006d26" }} />
                    <Typography sx={{ color: isInDeliveryZone && !preorderEnabled ? '#006d26' : 'inherit' }} level="title-sm">
                        Livraison instantanée
                    </Typography>
                </div>
                <CardContent orientation="horizontal">
                    <div className="delivery-card-title">
                        <Typography level="body-xs" sx={{ textAlign: "center", color: isInDeliveryZone && !preorderEnabled ? '#006d26' : 'inherit' }}>
                            Si vous êtes dans la zone, faites-vous livrer directement après avoir validé et payé votre commande.
                        </Typography>
                    </div>
                </CardContent>
            </Card> */}

            {/* click & collect option */}
            <Card
                variant="soft"
                color={getClickCollectStyles().color}
                sx={getClickCollectStyles().styles}
                onClick={() => handleCardClick('clickCollect')}
            >
                <div className="delivery-card-title">
                    <KitchenOutlinedIcon sx={{ fontSize: "70px", color: "#006d26" }} />
                    <Typography level="title-sm">Click & Collect</Typography>
                </div>
                <CardContent orientation="horizontal">
                    <div className="delivery-card-title">
                        <Typography level="body-xs" sx={{ textAlign: "center" }}>
                            Sélectionnez les recettes actuellement disponibles et venez récupérer votre commande 24h/7j.
                        </Typography>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}

export default TypeOfDelivery;
