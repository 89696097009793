import { useState } from "react";

//Icons
import { GoPlus } from "react-icons/go";

//Toast
import toast from 'react-hot-toast';

//API
import { updateRecipeImage } from "../../../../api/recipesAPI";

function SharedRecipeImage({ imageUrl }) {


    return (
        <>

            <div className="flex-center">
                <div className="recipes-details-img-container">
                    {imageUrl && <img src={imageUrl} alt="recipes-details" className="recipes-details-img" />}
                </div>
            </div>
        </>
    );
}

export default SharedRecipeImage;
