import React, { useState, useEffect } from "react";

// API
import { getItemsByOrderId } from "../../../../api/orderItemsAPI";
import { fetchRecipeById } from "../../../../api/recipesAPI";
import { fetchGroupOrdersParticipants, deleteGroupOrderParticipantById } from "../../../../api/groupOrdersParticipantsAPI";
import { fetchOrderById } from "../../../../api/ordersAPI";
import { fetchAddressById } from "../../../../api/addressesAPI";

//MUI
import Stack from '@mui/joy/Stack';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';

//toast
import toast from 'react-hot-toast';

// Style
import '../popup-infos/PopupInfos.css';
import { findUserbyId } from "../../../../api/userAPI";

export default function GroupOrdersInfos({ open, onClose, data, title, updateOrders }) {
    // LocalStates
    const [participants, setParticipants] = useState([]);
    const [loadingParticipants, setLoadingParticipants] = useState(false);
    const [errorItems, setErrorItems] = useState(null);
    const [usersData, setUsersData] = useState([]);

    // Fetch group participants and their data
    const fetchGroupParticipantsData = async () => {
        try {
            setLoadingParticipants(true);
            const groupOrderId = data.id;
            const groupParticipants = await fetchGroupOrdersParticipants(groupOrderId);
            setParticipants(groupParticipants);

            const combinedData = await Promise.all(groupParticipants.map(async (participant) => {
                try {
                    const [userData, orderData, addressData, orderItemsData] = await Promise.all([
                        findUserbyId(participant.user_id),
                        fetchOrderById(participant.order_id),
                        fetchAddressById(participant.address_id),
                        getItemsByOrderId(participant.order_id)
                    ]);

                    const orderItemsWithRecipes = await Promise.all(orderItemsData.map(async (item) => {
                        const recipe = await fetchRecipeById(item.recipeId);
                        return { ...item, recipe };
                    }));

                    return {
                        participant,
                        userData,
                        orderData,
                        addressData,
                        orderItemsData: orderItemsWithRecipes
                    };
                } catch (error) {
                    setErrorItems(error);
                    return null;
                }
            }));

            const validData = combinedData.filter(data => data !== null);
            setUsersData(validData);
            setLoadingParticipants(false);
        } catch (error) {
            setErrorItems(error);
            setLoadingParticipants(false);
        }
    };

    useEffect(() => {
        if (open && data) {
            fetchGroupParticipantsData();
        }
    }, [open, data]);

    // Delete participant from DB
    const handleConfirmDelete = async (userData) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer le participant ?")) {
            try {
                const userId = userData.id;
                await deleteGroupOrderParticipantById(userId);
                toast.success('Participant supprimé avec succès');
                await fetchGroupParticipantsData();
                updateOrders();  // Mise à jour des commandes
            } catch (error) {
                toast.error('Erreur lors de la suppression du participant');
            }
        }
    };

    return (
        <>
            {open && (
                <>
                    <div className="overlay" onClick={onClose}></div>
                    <div className="infos-modal">
                        <div className="edit-modal-title">
                            <h2>{title}</h2>
                        </div>
                        <div className="infos-modal-content">
                            <h4 className="infos-modal-title">Liste des participants</h4>
                            <div className="items-list">
                                {loadingParticipants && <p>Loading participants...</p>}
                                {errorItems && <p>Error: {errorItems.message}</p>}
                                {!loadingParticipants && !errorItems && (
                                    <ul className="order-items-list">
                                        {usersData.map(({ participant, userData, orderData, addressData, orderItemsData }, index) => (
                                            <li key={participant.id} style={{ marginBottom: "1rem" }}>
                                                {userData ? (
                                                    <Stack spacing={1}>
                                                        <Card size="sm">
                                                            <Typography level="h2" fontSize="sm" sx={{ mb: 0.5 }}>
                                                                {userData.firstname} {userData.lastname}
                                                            </Typography>
                                                            <Typography level="h3" fontSize="xs" sx={{ mb: -1 }}>
                                                                Email
                                                            </Typography>
                                                            <Typography fontSize="xs">{userData.email}</Typography>
                                                            <Typography level="h3" fontSize="xs" sx={{ mb: -1 }}>
                                                                Adresse
                                                            </Typography>
                                                            <Typography fontSize="xs">{addressData.street_num} {addressData.street} {addressData.name}, {addressData.zip_code} {addressData.city}</Typography>
                                                            <Typography level="h3" fontSize="xs" sx={{ mb: -1 }}>
                                                                Numéro de commande
                                                            </Typography>
                                                            <Typography fontSize="xs">{orderData.order_num}</Typography>
                                                            <Typography level="h3" fontSize="xs" sx={{ mb: -1 }}>
                                                                Items de commande
                                                            </Typography>
                                                            <List
                                                                variant="soft"
                                                                size='sm'
                                                                sx={{
                                                                    minWidth: 240,
                                                                    borderRadius: 'sm',
                                                                }}
                                                            >
                                                                {orderItemsData.map(item => (
                                                                    <ListItem key={item.id}>
                                                                        <Typography fontSize="xs">{item?.recipe.name} x {item?.quantity}</Typography>
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                            <Typography fontSize="xs" onClick={() => handleConfirmDelete(userData)} sx={{ cursor: "pointer", color: "red", fontWeight: "bold" }}>Supprimer le participant</Typography>
                                                        </Card>
                                                    </Stack>
                                                ) : (
                                                    <p>User data not found</p>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            <div className="infos-modal-action">
                                <button
                                    className="btn-border"
                                    variant="outlined"
                                    onClick={onClose}
                                >
                                    Fermer
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
