import { useNavigate } from 'react-router-dom';

//Elements
import Button from '../../components/elements/button/Button';

//Styles
import "./Error.css"

function Error() {

    const navigate = useNavigate();

    return (
        <div className="error-container container ">
            <h1 className='error-title'>OUPS!</h1>
            <div className="error-description">
                <p>Nous n'avons pas pu trouvé la page que vous cherchez.</p>
            </div>
            <div className='error-action flex-center'>
                <Button className="btn-full" type="button" text="Retouner à la page d'accueil" onClick={() => navigate('/home')} />
            </div>
        </div>
    );
}

export default Error;
