import React, { useState, useEffect } from "react";
import { fetchNutriFacts } from '../../../api/nutriFactsAPI'

// MUI
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";

function NutritionFacts({ recipeId }) {
    const [nutriFacts, setNutriFacts] = useState({});
    const [error, setError] = useState(null);

    const fetchNutriInfos = async () => {
        try {
            const data = await fetchNutriFacts(recipeId);
            setNutriFacts(data);
        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => {
        fetchNutriInfos(recipeId);
    }, [recipeId]);

    const excludedKeys = ["ecoscore", "createdAt", "updatedAt", "id", "recipe_id", "portions"];

    const unitsMap = {
        total_weight: 'g',
        calories: 'kcal',
        proteins: 'g',
        carbohydrates: 'g',
        sugar: 'g',
        lipids: 'g',
        fibers: 'g',
        salt: 'g',
        saturated_fat: 'g',
    };

    // Objet de traduction pour les noms des valeurs nutritionnelles en français
    const translationMap = {
        total_weight: 'Poids total',
        calories: 'Calories',
        proteins: 'Protéines',
        carbohydrates: 'Glucides',
        sugar: 'Sucre',
        lipids: 'Lipides',
        fibers: 'Fibres',
        salt: 'Sel',
        saturated_fat: 'Graisses saturées',
    };

    const portionSize = nutriFacts?.portions || 1;
    const totalWeight = nutriFacts?.total_weight || 100;

    return (
        <div className="nutri-facts">
            <div className="nutri-facts-title">
                <h1>Valeurs Nutritionnelles</h1>
                <span>Les valeurs nutritionnelles sont données pour une portion</span>
            </div>
            <div className="nutri-facts-details">
                {nutriFacts ? (
                    <TableContainer component={Paper} sx={{ overflow: "hidden", borderRadius: 5 }}>
                        <Table sx={{ m: 1 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "bold" }}>Nutriment</TableCell>
                                    <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>Pour une portion</TableCell>
                                    <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>Pour 100g</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.entries(nutriFacts).map(([key, value]) => (
                                    excludedKeys.includes(key) ? null : (
                                        <TableRow key={key}>
                                            <TableCell sx={{ fontWeight: "bold" }}>
                                                {translationMap[key] || key}
                                            </TableCell>
                                            {(key !== 'nutriscore' && key !== 'value_per_100g') && (
                                                <>
                                                    <TableCell sx={{ textAlign: "right", paddingRight: 5 }}>
                                                        {key === 'salt' ? (parseFloat((value / 1000) / portionSize).toFixed(1)) : (parseFloat(value / portionSize).toFixed(1))} {unitsMap[key] || ''}
                                                    </TableCell>
                                                    <TableCell sx={{ textAlign: "right", paddingRight: 5 }}>
                                                        {key === 'salt' ? (parseFloat((value / 1000) / totalWeight * 100).toFixed(1)) : (parseFloat(value / totalWeight * 100).toFixed(1))} {unitsMap[key] || ''}
                                                    </TableCell>
                                                </>
                                            )}
                                            {key === 'nutriscore' && (
                                                <TableCell sx={{ textAlign: "right", paddingRight: 5 }}>
                                                    {value}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    )
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <p className="flex-center">Oups ! Valeurs nutritionnelles indisponibles pour le moment 🫢</p>
                )}
            </div>
        </div>
    );
}

export default NutritionFacts;
