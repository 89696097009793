import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { incrementQuantity, decrementQuantity, removeFromCart } from '../../features/cart/cartSlice';  // Import actions from your cartSlice
import Pic from '../../assets/recipes/bowl.avif';
import { PiMinus, PiPlus } from 'react-icons/pi';
import { TiDeleteOutline } from 'react-icons/ti';
import toast from "react-hot-toast";
import PopupConfirm from '../../components/elements/popup/popup-confirm/PopupConfirm';

function CartItem({ item }) {
    const dispatch = useDispatch();
    const preorderEnabled = useSelector((state) => state.preorder.preorderEnabled); // Vérification si la précommande est activée
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn); // Vérifier si l'utilisateur est connecté
    const cart = useSelector((state) => isLoggedIn ? state.cart.items : state.cart.guestItems); // Utiliser le guestCart si l'utilisateur n'est pas connecté

    const imageUrl = item.pictures?.length > 0
        ? `${process.env.REACT_APP_API_URL}${item.pictures[0].path}`
        : Pic;

    // Handler for incrementing item quantity
    const handleIncrement = () => {
        if (preorderEnabled || item.quantity < item.qty_available) {
            dispatch(incrementQuantity(item));  // Utilise l'action incrementQuantity
            toast.success(`La quantité de ${item.name} a été augmentée !`);
        } else {
            toast.error(`La quantité maximale a été atteinte pour ${item.name}`);
        }
    };

    // Handler for decrementing item quantity (remove if quantity goes below 1)
    const handleDecrement = () => {
        if (item.quantity > 1) {
            dispatch(decrementQuantity(item));  // Utilise l'action decrementQuantity
            toast.success(`La quantité de ${item.name} a été diminuée !`);
        } else {
            handleRemove(); // Supprimer l'article si la quantité est égale à 1
        }
    };

    // Handler for removing item from cart
    const handleRemove = () => {
        dispatch(removeFromCart(item));  // Utilise l'action removeFromCart
        toast.success(`${item.name} a été retiré du panier !`);
    };

    return (
        <div className="cart-item-container">
    <div className="item-img-container">
        <img src={imageUrl} alt={item.name} className="item-img" />
    </div>
    <div className="item-text">
        <div className="item-details">
            <span className="item-name">{item.name}</span>
            <span className="item-price">{item.price}€</span>
        </div>
        <div className="item-quantity">
            <PiMinus className="item-qty-icon" onClick={handleDecrement} />
            {item.quantity}
            <PiPlus className="item-qty-icon" onClick={handleIncrement} />
        </div>
        <TiDeleteOutline size={20} className="item-delete-icon" onClick={handleRemove} />
    </div>
</div>
    );
}

export default CartItem;
