import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearUnavailableItems, setCartFromLocalStorage } from '../../features/cart/cartSlice'; 
import { fetchCurrentUserOrders } from '../../api/userAPI';
import { createOrder, updateOrderById } from '../../api/ordersAPI'; 
import toast from 'react-hot-toast'; 

import CircularProgress from '@mui/material/CircularProgress';
import Button from '../elements/button/Button';
import CartItem from './CartItem';
import './SideCart.css';

function SideCart({ closeCartDropdown, isMobile }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    // Utiliser Redux pour récupérer le panier et le total TTC
    const cart = useSelector((state) => state.cart.items); 
    const totalTTC = useSelector((state) => state.cart.totalTTC); 
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn); 

    // Synchroniser le panier avec le localStorage au premier chargement
    useEffect(() => {
        dispatch(setCartFromLocalStorage()); 
    }, [dispatch]);

    // Gestion de l'expiration du panier
    useEffect(() => {
        try {
            const cartExpiration = localStorage.getItem('cartExpiration');
            if (cartExpiration && !isNaN(cartExpiration) && new Date().getTime() > parseInt(cartExpiration)) {
                dispatch(clearUnavailableItems()); 
                localStorage.removeItem('cart');
                localStorage.removeItem('cartExpiration');
                toast.info("Votre panier a expiré.");
            }
        } catch (error) {
            console.error("Error handling cart expiration:", error);
        }
    }, [dispatch]);

    // Calcul du total en euros pour affichage
    const total = useMemo(() => cart.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2), [cart]);

    // Conversion du total en centimes pour la base de données
    const totalInCents = Math.round(total * 100);

    const handleCheckout = async () => {
        setIsLoading(true); // Début du chargement
        console.log("Panier total (en euros):", total);
        console.log("Panier total (en centimes):", totalInCents);

        if (cart.length > 0) {
            if (isLoggedIn) {
                const userId = JSON.parse(localStorage.getItem('user')).id;

                try {
                    const orders = await fetchCurrentUserOrders(userId); 
                    console.log("Commandes récupérées pour l'utilisateur:", orders);

                    const currentNewOrder = orders.find(order => order.status === 'new');

                    let orderResult;
                    if (currentNewOrder) {
                        console.log("Mise à jour de la commande existante avec le statut 'new'");
                        const updatedOrder = {
                            ...currentNewOrder,
                            items: [...cart],
                            price: totalInCents // Utilisation de totalInCents en centimes
                        };
                        orderResult = await updateOrderById(currentNewOrder.id, updatedOrder);
                    } else {
                        console.log("Création d'une nouvelle commande avec le statut 'new'");
                        const newOrder = {
                            userId: userId,
                            status: 'new',
                            items: [...cart],
                            totalTTC: totalInCents // Utilisation de totalInCents en centimes
                        };
                        orderResult = await createOrder(newOrder);
                    }

                    console.log("Commande créée ou mise à jour:", orderResult);
                    localStorage.setItem('currentOrder', JSON.stringify(orderResult)); 
                    navigate('/order-validation');
                    if (isMobile) {
                        closeCartDropdown();
                    }

                } catch (error) {
                    console.error("Erreur lors du traitement de la commande:", error);
                    toast.error("Une erreur est survenue lors du traitement de la commande.");
                    setIsLoading(false); 
                }
            } else {
                toast.error('Veuillez vous connecter pour valider votre commande.');
                navigate('/login');
                setIsLoading(false); 
            }
        } else {
            alert('Votre panier est vide.');
            setIsLoading(false); // Fin du chargement
        }
    };

    return (
        <div className="side-cart-container">
            <div className="side-cart-items-list">
                <h2 className="side-cart-title flex-center">Ma commande</h2>
                <div className="side-cart-items">
                    {cart.length === 0 ? (
                        <p className="flex-center">Votre panier est vide.</p>
                    ) : (
                        cart.map((item) => <CartItem key={item.id} item={item} />)
                    )}
                </div>
            </div>
            {cart.length > 0 && (
                <>
                    <div className="side-cart-recap">
                        <h2 className="side-cart-total-title">Total de la commande</h2>
                        <div className="side-cart-total">{total} €</div>
                    </div>
                    <div className="side-cart-action">
                    <Button
                        className="btn-border btn-xl"
                        onClick={handleCheckout}
                        disabled={isLoading} // Désactiver le bouton pendant le chargement
                        text={
                            isLoading ? (
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                            <CircularProgress size={24} color="inherit" style={{ marginRight: 8 }} />
                            Traitement en cours...</span>) : ("Valider la commande")
                        }
                    />
                    </div>
                </>
            )}
        </div>
    );
}

export default SideCart;
