import apiInstance from "./api";

export const createGroupOrder = async (groupOrderData) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.post(`${process.env.REACT_APP_API_URL}/group-orders/create`, groupOrderData, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error while creating group order", error);
    throw error;
  }
};

export const fetchAllGroupOrders = async () => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/group-orders/get-all`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error while fetching all group orders", error);
    throw error;
  }
};

export const fetchGroupOrderById = async (id) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/group-orders/get/${id}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error while fetching all group orders", error);
    throw error;
  }
};

export const deleteGroupOrderById = async (id) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.delete(`${process.env.REACT_APP_API_URL}/group-orders/delete/${id}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error while deleting group order", error);
    throw error;
  }
};

export const updateGroupOrderById = async (id, updatedOrderData) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.put(`${process.env.REACT_APP_API_URL}/group-orders/update/${id}`, updatedOrderData, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error while updating group order", error);
    throw error;
  }
};