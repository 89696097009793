import { useState } from 'react';
import Autocomplete from '@mui/joy/Autocomplete';
import SelectRecipeCard from './SelectRecipeCard';
import './PopupMenu.css';

const PopupMenu = ({ open, onClose, onRecipeSelect, recipes = [], onAddRecipesToMenu }) => {
    const [selectedRecipes, setSelectedRecipes] = useState([]);
    const [searchText, setSearchText] = useState("");

    const getRecipeCategory = (partId) => {
        switch (partId) {
            case 1: return "ENTRÉES";
            case 2: return "PLATS";
            case 3: return "DESSERTS";
            case 4: return "BOISSONS";
            default: return "AUTRES";
        }
    };

    // Ajout des catégories aux options de recettes
    const optionsWithCategories = recipes
        .filter(recipe => recipe && recipe.id && recipe.partId && recipe.name && recipe.description)
        .map(recipe => ({
            ...recipe,
            category: getRecipeCategory(recipe.partId)
        }));

    // Filtrage des recettes par le texte de recherche
    const filteredRecipes = optionsWithCategories.filter(recipe =>
        recipe.name.toLowerCase().includes(searchText.toLowerCase()) ||
        recipe.description.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleRecipeSelect = (recipeId) => {
        setSelectedRecipes(prevSelected =>
            prevSelected.includes(recipeId)
                ? prevSelected.filter(id => id !== recipeId)
                : [...prevSelected, recipeId]
        );
    };

    const handleAddClick = () => {
        onRecipeSelect(selectedRecipes);
        onAddRecipesToMenu(selectedRecipes);
        onClose();
    };

    return (
        <>
            {open && (
                <>
                    <div className="welcome-overlay" onClick={onClose}></div>
                    <div className='popup-menu'>
                        <div className="popup-menus-title">
                            <h2>Choisir des recettes</h2>
                        </div>
                        <div className="popup-menus-content">
                            <input
                                type="text"
                                placeholder="Rechercher une recette..."
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    fontSize: "16px",
                                    boxSizing: "border-box"
                                }}
                            />
                            <div className="popup-menu-actions">
                                <button className="btn-full btn-sm" onClick={handleAddClick}>
                                    Ajouter les recettes
                                </button>
                                <button
                                    className="btn-border"
                                    onClick={onClose}
                                >
                                    Fermer
                                </button>
                            </div>
                            <div className="recipe-catalog">
                                {filteredRecipes.map(recipe => (
                                    <div
                                        key={recipe.id}
                                        className={`recipe-card ${selectedRecipes.includes(recipe.id) ? 'selected' : ''}`}
                                        onClick={() => handleRecipeSelect(recipe.id)}
                                    >
                                        <SelectRecipeCard selectedRecipe={recipe} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default PopupMenu;
