import { useState, useEffect } from 'react';

import { fetchCurrentUserAllergies } from '../../api/userAPI';


function ClientAllergies() {

    //Local States
    const [allergies, setAllergies] = useState([]);

    /**
     * Fetches the current user's allergies and sets them in state.
     */
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
        if (token) {
            const getUserAllergies = async () => {
                try {
                    const fetchedAllergies = await fetchCurrentUserAllergies(token);
                    setAllergies(fetchedAllergies);
                } catch (error) {
                    console.error("Erreur lors de la récupération des allergies:", error);
                }
            };

            getUserAllergies();
        }
    }, []);

    return (

        <div className='flex-center'>
            {allergies.length === 0 ? (
                <div>Aucune allergie</div>
            ) : (
                <ul>
                    {allergies.map(allergy => (
                        <li key={allergy.id}>
                            {allergy.name === "Gluten" && "🌾 "}
                            {allergy.name === "Arachide" && "🥜 "}
                            {allergy.name === "Lait" && "🥛 "}
                            {allergy.name === "Fruits à coques" && "🌰 "}
                            {allergy.name === "Mollusques" && "🐚 "}
                            {allergy.name === "Moutarde" && "🌭 "}
                            {allergy.name === "Poisson" && "🐠 "}
                            {allergy.name === "Céleri" && "🥬 "}
                            {allergy.name === "Soja" && "🌱 "}
                            {allergy.name === "Sésame" && "🫘 "}
                            {allergy.name === "Lupin" && "🫘 "}
                            {allergy.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>


    );
}

export default ClientAllergies;