// src/features/delivery-zones/DeliverySlice.js

import { createSlice } from '@reduxjs/toolkit';

const deliveryZones = ['93220', '93340', '93250', '93370', '93330'];
const localStorageKey = 'isInDeliveryZone';
const postalCodeStorageKey = 'userPostalCode';

const getLocalStorageValue = () => {
    const storedValue = localStorage.getItem(localStorageKey);
    return storedValue ? JSON.parse(storedValue) : false;
};

const getStoredPostalCode = () => {
    return sessionStorage?.getItem(postalCodeStorageKey) || '';
};

export const setUserPostalCodeAsync = (newPostalCode) => async (dispatch) => {
    dispatch(setUserPostalCode(newPostalCode));
};

const deliveryZoneSlice = createSlice({
    name: 'deliveryZone',
    initialState: {
        isInDeliveryZone: getLocalStorageValue(),
        userPostalCode: getStoredPostalCode(),
    },
    reducers: {
        setUserPostalCode: (state, action) => {
            const newPostalCode = action.payload;
            state.userPostalCode = newPostalCode;
            sessionStorage.setItem(postalCodeStorageKey, newPostalCode);

            const isInZone = deliveryZones.includes(newPostalCode);
            state.isInDeliveryZone = isInZone;
            sessionStorage.setItem(localStorageKey, JSON.stringify(isInZone));
        },
    },
});

export const { setUserPostalCode } = deliveryZoneSlice.actions;
export default deliveryZoneSlice.reducer;
