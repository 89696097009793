// RecipesHeader.js
import React from "react";
import { CiEdit } from "react-icons/ci";
import NutriA from '../../../../assets/nutri-score/NutriscoreA.png'
import NutriB from '../../../../assets/nutri-score/NutriscoreB.png'
import NutriC from '../../../../assets/nutri-score/NutriscoreC.png'
import NutriD from '../../../../assets/nutri-score/NutriscoreD.png'
import NutriE from '../../../../assets/nutri-score/NutriscoreE.png'

function SharedRecipesHeader({ recipe, nutriFacts }) {

    let nutriScoreImage;

    switch (nutriFacts?.nutriscore) {
        case 'A':
            nutriScoreImage = <img src={NutriA} alt="NutriA" />;
            break;
        case 'B':
            nutriScoreImage = <img src={NutriB} alt="NutriB" />;
            break;
        case 'C':
            nutriScoreImage = <img src={NutriC} alt="NutriC" />;
            break;
        case 'D':
            nutriScoreImage = <img src={NutriD} alt="NutriD" />;
            break;
        case 'E':
            nutriScoreImage = <img src={NutriE} alt="NutriE" />;
            break;
        default:
            nutriScoreImage = null;
    }



    return (
        <div className="recipes-details-name flex-center">
            <div>
                <h1>{recipe.name}</h1>
            </div>
            <div className="recipes-details-nutriscore">
                {nutriScoreImage}
            </div>
        </div>
    );
}

export default SharedRecipesHeader;
