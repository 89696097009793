import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { login as loginAPI,verifyToken } from "../../api/authAPI"; 
import toast from 'react-hot-toast';

export const login = createAsyncThunk("auth/login", async (body, thunkAPI) => {
  try {
    // TODO
    const response = await loginAPI(body.email, body.password);
    if (!response.role) {
      response.role = "client";
    }
    // const accessToken = response.accessToken!;
    // const decodedToken = jwtDecode<JwtUserPayload>(accessToken);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const checkAuthStatus = createAsyncThunk(
  "/auth/validate-token",
  async (_, thunkAPI) => {
    try {
      const response = await verifyToken(); 
      return response.data;
    } catch (error) {
      if (error.response && (error.response.status === 403 || error.response.status === 401 || error.response.data.message === "Token expired")) {
        toast.error("Session expirée. Veuillez vous reconnecter.")
        thunkAPI.dispatch(logoutAction()); 
        return thunkAPI.rejectWithValue(error.response.data);
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);



export const authSlice = createSlice({
  name: "auth",
  initialState: {
    roles: "",
    isAdmin: false,
    isClient: false,
    isLivreur: false,
    isLoggedIn:
      !!localStorage.getItem("user") &&
      !!JSON.parse(localStorage.getItem("user")).accessToken,
    error: null,
  },
  reducers: {
    setRoles: (state, action) => {

      state.roles = action.payload;
      state.isLoggedIn = true;

      switch (action.payload) {
        case "client":
          state.isClient = true;
          break;
        case "livreur":
          state.isLivreur = true;
          break;
        case "admin":
          state.isAdmin = true;
          break;
        default:
          state.isClient = false;
          state.isLivreur = false;
          state.isAdmin = false;
          state.error = `Role not recognized: ${action.payload}`;
          break;
      }

  
    },
    setError: (state, action) => {


      state.error = action.payload;
    },
    loginSuccess: (state) => {
      state.isLoggedIn = true;
    },
    logoutAction: (state) => {


      state.isLoggedIn = false;
      state.roles = "";
      state.isClient = false;
      state.isLivreur = false;
      state.isAdmin = false;

      // In case of SSR
      if (typeof window !== "undefined") {
        localStorage.clear();
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {})
      .addCase(login.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        setRoles(state, action.payload.role); 
      })
      .addCase(login.rejected, (state) => {
        state.isLoggedIn = false;
      });
  },
});

export const { setRoles, setError, loginSuccess, logoutAction } =
  authSlice.actions;

export const selectUser = (state) => state.auth;

export default authSlice.reducer;
