import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { fetchAllAllergies } from "../../../../api/allergiesAPI";
import toast from 'react-hot-toast';

// Styles
import './PopupCreate.css';

function PopupCreate({ trigger, onCreate, products = null }) {

    const [allergiesOptions, setAllergiesOptions] = useState([]);
    const [selectedAllergen, setSelectedAllergen] = useState(null);

    useEffect(() => {
        const fetchAllergiesData = async () => {
            try {
                const allergies = await fetchAllAllergies();
                const allergiesOptions = allergies.map(allergy => ({
                    value: allergy.id,
                    label: allergy.name
                }));
                setAllergiesOptions(allergiesOptions);
            } catch (error) {
                console.error("Error fetching allergies:", error);
            }
        };

        fetchAllergiesData();
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        unitType: '',
        price: '0.0',
        totalQuantity: 0,
        category: null,
        label: null,
    });

    const unitType = [
        { value: 'G', label: 'G' },
    ];

    const categories = [
        { value: 'Fruits et Légumes', label: 'Fruits et Légumes' },
        { value: 'Conserves', label: 'Conserves' },
        { value: 'Epices et Condiments', label: 'Epices et Condiments' },
        { value: 'Céréales et Féculents', label: 'Céréales et Féculents' },
        { value: 'Boissons', label: 'Boissons' },
        { value: 'Huiles et matières grasses', label: 'Huiles et matières grasses' },
        { value: 'Légumineuses', label: 'Légumineuses' },
        { value: 'Sucreries snacks', label: 'Sucreries snacks' },
        { value: 'Oléagineux et Fruits secs', label: 'Oléagineux et Fruits secs' },
        { value: 'Aliments transformés', label: 'Aliments transformés' },
    ];

    const labels = [
        { value: '', label: '' },
        { value: 'bio', label: 'bio' },
        { value: 'local', label: 'local' },
        { value: 'national', label: 'national' },
    ];

    const handleSubmit = async (event, close) => {
        event.preventDefault();

        // Check for duplicate product names
        const isDuplicate = products.some(item => {
            let itemName = item.name.toLowerCase().trim();
            let enteredName = formData.name.toLowerCase().trim();
            return itemName.includes(enteredName) || enteredName.includes(itemName);
        });

        if (!isDuplicate) {
            onCreate(formData, selectedAllergen);
            close();
            // Reset the form after successful creation
            setFormData({
                name: '',
                unitType: '',
                price: '0.0',
                totalQuantity: 0,
                category: null,
                label: null,
            });
        } else {
            toast.error('Un produit avec le même nom existe déjà.');
        }
    };

    return (
        <Popup
            trigger={trigger}
            position="right center"
            modal
            contentStyle={{ zIndex: 99999 }}
        >
            {(close) => (
                <div className="popup-create-content flex-center">
                    <div className="popup-title">
                        <h1>Nouveau produit</h1>
                    </div>
                    <div className="popup-create-form">
                        <form onSubmit={(e) => handleSubmit(e, close)}>
                            <label htmlFor="outlined-basic" className='create-product-label'>Nom du produit</label>
                            <input 
                                id="outlined-basic" 
                                label="Nom du produit" 
                                variant="outlined" 
                                type="text"
                                className="popup-create-name"
                                placeholder="Nom du produit"
                                color="success"
                                value={formData.name}
                                onChange={(e) =>
                                    setFormData({ ...formData, name: e.target.value })
                                }
                                required
                            />
                            <label htmlFor="outlined-number" className='create-product-label'>Prix du produit</label>
                            <input
                                id="outlined-number"
                                label="Prix du produit"
                                type="number"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="popup-create-price"
                                placeholder="Prix du produit"
                                color="success"
                                value={formData.price}
                                onChange={(e) =>
                                    setFormData({ ...formData, price: e.target.value })
                                }
                                required
                            />

                            <label htmlFor="unitType" className='create-product-label'>Unités du produit</label>
                            <select
                                id="unitType"
                                value={formData.unitType || ""}
                                onChange={(e) =>
                                    setFormData({ ...formData, unitType: e.target.value })
                                }
                                className="edit-input"
                            >
                                <option value="" disabled hidden>
                                    Sélectionnez une option
                                </option>
                                {unitType.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>

                            <label htmlFor="category" className='create-product-label'>Catégorie du produit</label>
                            <select
                                id="category"
                                value={formData.category || ""}
                                onChange={(e) =>
                                    setFormData({ ...formData, category: e.target.value })
                                }
                                className="edit-input"
                            >
                                <option value="" disabled hidden>
                                    Sélectionnez une option
                                </option>
                                {categories.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>

                            <label htmlFor="labels" className='create-product-label'>Label du produit</label>
                            <select
                                id="labels"
                                value={formData.label || ""}
                                onChange={(e) =>
                                    setFormData({ ...formData, label: e.target.value })
                                }
                                className="edit-input"
                            >
                                <option value="" disabled hidden>
                                    Aucun Label
                                </option>
                                {labels.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>

                            <label htmlFor="outlined-number-qty" className='create-product-label'>Quantité en stock</label>
                            <input
                                id="outlined-number-qty"
                                label="Quantité Totale"
                                type="number"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="popup-create-totalQty"
                                color="success"
                                placeholder="Quantité Totale"
                                value={formData.totalQuantity}
                                onChange={(e) =>
                                    setFormData({ ...formData, totalQuantity: e.target.value })
                                }
                                required
                            />

                            <label htmlFor="allergies" className='create-product-label'>Allergènes</label>
                            <select
                                id="allergies"
                                value={selectedAllergen || ""}
                                onChange={(e) => setSelectedAllergen(e.target.value === "" ? null : e.target.value)}
                                className="edit-input"
                            >
                                <option value="">Aucun</option>
                                {allergiesOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>

                            <div className="popup-actions">
                                <button type="submit" className="btn-full">
                                    Créer
                                </button>
                                <button className="btn-border " onClick={close}>
                                    Annuler
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Popup>
    );
}

export default PopupCreate;
