import axios from 'axios';
import { refreshToken } from './authAPI';
import { onUnauthorized } from './authService';


/**
 * Creates an axios instance with the base URL set to the API base URL.
 * @type {AxiosInstance}
 */
const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});


apiInstance.interceptors.response.use(response => response, async error => {
  const originalRequest = error.config;
  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    try {
      await refreshToken();
      return apiInstance(originalRequest); 
    } catch (refreshError) {
      if (typeof onUnauthorized === 'function') {
        onUnauthorized(); 
      }
      return Promise.reject(refreshError);
    }
  }
  return Promise.reject(error);
});


export default apiInstance;