import apiInstance from "./api";

export const fetchSubscription = async (userId) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/subscriptions/list/${userId}`, {
      headers: headers,
    });

    // Vérifie si le statut est 200 mais les données sont null
    if (response.status === 200 && (!response.data || response.data.length === 0)) {
      console.warn("Aucun abonnement trouvé, mais la requête a réussi (statut 200).");
      return {
        status: 200,
        data: null,
        message: "Aucun abonnement trouvé pour cet utilisateur."
      };
    }

    // Retourne les données si elles existent
    if (response.status === 200 && response.data) {
      return {
        status: 200,
        data: response.data,
      };
    }
  } catch (error) {
    // Capture les erreurs de fetch ou toute autre erreur
    console.error("Erreur lors de la récupération de l'abonnement :", error);
    return {
      status: error.response?.status || 500,
      data: null,
      message: "Erreur lors de la récupération de l'abonnement. Veuillez réessayer plus tard.",
    };
  }
};
