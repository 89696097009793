import React, { useState, useEffect } from "react";

// MUI
import Chip from '@mui/joy/Chip';
import Typography from '@mui/joy/Typography';

//API
import { fetchProductById, verifyIfAllergen } from '../../../../api/stockAPI'

// Style
import '../popup-infos/PopupInfos.css';

export default function PopupRecipeInfos({ open, onClose, ingredients, title, description }) {

    const [ingredientDetails, setIngredientDetails] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchIngredientsDetails = async () => {
            try {
                const details = await Promise.all(
                    ingredients.productIds.map(async ingredient => {
                        const detail = await fetchProductById(ingredient.productId);
                        const isAllergen = await verifyIfAllergen(ingredient.productId);
                        return { ...detail, isAllergen };
                    })
                );
                setIngredientDetails(details);
            } catch (error) {
                setError(error);
            }
        };

        if (ingredients.productIds) {
            fetchIngredientsDetails();
        }
    }, [ingredients]);


    const getColorAndEmoji = (ingredientDetail) => {
        if (ingredientDetail?.isAllergen?.exists) {
            return { sx: { bgcolor: "#FFD1D1", color: "#3B3636" }, emoji: "❗️" };
        }
        switch (ingredientDetail.label) {
            case "bio":
                return { sx: { bgcolor: "#CFFFCE", color: "#3B3636" }, emoji: "🌿" };
            case "national":
                return { sx: { bgcolor: "#D7EAFF", color: "#3B3636" }, emoji: "🇫🇷" };
            case "local":
                return { sx: { bgcolor: "#D2F1F3", color: "#3B3636" }, emoji: "🏡" };
            default:
                return { sx: {}, emoji: "" };
        }
    };

    if (error) {
        return <div>Error loading ingredients: {error.message}</div>;
    }

    return (
        <>
            <>
                {open && (
                    <>
                        <div className="overlay" onClick={onClose}></div>
                        <div className="infos-modal">
                            <div className="edit-modal-title">
                                <h2>{title}</h2>
                            </div>
                            <div className="infos-modal-descriptions">
                                <Typography level="body-sm" sx={{ fontWeight: "bold" }}>
                                    Description
                                </Typography>
                                <p>{description}</p>
                            </div>
                            <div className="infos-modal-content">
                                <div className="items-list">

                                    <Typography level="body-sm" sx={{ fontWeight: "bold", marginBottom: "1rem" }}>
                                        Ingrédients
                                    </Typography>

                                    <div className="ingredient-list">
                                        {ingredientDetails.map((ingredientDetail, index) => (
                                            ingredientDetail && (

                                                <div key={index} className="ingredient-item">
                                                    <Chip sx={getColorAndEmoji(ingredientDetail).sx}>
                                                        {ingredientDetail.name} {getColorAndEmoji(ingredientDetail).emoji}
                                                    </Chip>
                                                </div>

                                            )
                                        ))}
                                    </div>
                                </div>
                                <div className="ingredients-legend">
                                    <Chip sx={{ bgcolor: "#CFFFCE", color: "#3B3636" }}>
                                        Bio 🌿
                                    </Chip>
                                    <Chip sx={{ bgcolor: "#D2F1F3", color: "#3B3636" }}>
                                        Local 🏡
                                    </Chip>
                                    <Chip sx={{ bgcolor: "#D7EAFF", color: "#3B3636" }}>
                                        Français 🇫🇷
                                    </Chip>
                                    <Chip sx={{ bgcolor: "#FFD1D1", color: "#3B3636" }}>
                                        Allergène ❗️
                                    </Chip>
                                </div>
                                <div className="infos-modal-action">
                                    <button
                                        className="btn-border"
                                        variant="outlined"
                                        onClick={onClose}
                                    >
                                        Fermer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        </>
    );
}
