import apiInstance from "./api";
import {updateOrderStatusbyId} from './ordersAPI'

/**
 * Fetches Pikizy token and performs check-in for an order,then update order.status to "completed".
 *
 * @param {string} orderId - The ID of the order.
 * @returns {Promise} - A promise that resolves to the check-in response data.
 * @throws {Error} - If an error occurs during the process.
 */
export const fetchPikizyTokenAndCheckIn = async (orderId) => {


  const user = JSON.parse(localStorage.getItem("user"));
  const accessToken = user && user.accessToken;
  if (!accessToken) {
    console.error("Erreur : access token manquant.");
    return;
  }

  try {
    const tokenResponse = await apiInstance({
      method: "post",
      url: "https://passport.selflystore.com/connect/token",
      headers: {
        "x-access-token": accessToken,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: new URLSearchParams({
        grant_type: "password",
        username: process.env.REACT_APP_USERNAME,
        password: process.env.REACT_APP_PASSWORD,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        scope: "openid profile ipa_api3 offline_access",
      }).toString(),
    });

    if (tokenResponse.data && tokenResponse.data.access_token) {
      localStorage.setItem("pikizyToken", tokenResponse.data.access_token);
    }

    const pikizyToken = tokenResponse.data.access_token;
    const userId = user ? user.id : null;

    if (!pikizyToken) {
      console.error("Erreur : Pikizy token manquant après la requête.");
      return;
    }

    if (!userId) {
      console.error("Erreur : userId manquant.");
      return;
    }

    const requestBody = {
      deviceCode: process.env.REACT_APP_DEVICE_CODE,
      userId: String(userId),
    };

    const checkInResponse = await apiInstance.post(
      "https://api.selflystore.com/api/v1/user-check-in",
      requestBody,
      {
        headers: {
          "X-Application-Access-Key":
            process.env.REACT_APP_X_APPLICATION_ACCESS_KEY,
          Authorization: `Bearer ${pikizyToken}`,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      }
    );



    // if CheckinResponse is a success, update order.status
    if (checkInResponse.data) {
      await updateOrderStatusbyId(orderId);
    }

    return checkInResponse.data;
  } catch (error) {
    console.error("Erreur lors de la réalisation du processus:", error);
    throw error;


  }
};

/**
 * Fetches Pikizy token and performs check-in for an admin.
 */
export const pikizyCheckInAdmin = async () => {


  const user = JSON.parse(localStorage.getItem("user"));
  const accessToken = user && user.accessToken;

  if (!accessToken) {
    return;
  }

  try {
    const tokenResponse = await apiInstance({
      method: "post",
      url: "https://passport.selflystore.com/connect/token",
      headers: {
        "x-access-token": accessToken,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: new URLSearchParams({
        grant_type: "password",
        username: process.env.REACT_APP_USERNAME,
        password: process.env.REACT_APP_PASSWORD,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        scope: "openid profile ipa_api3 offline_access",
      }).toString(),
    });

    if (tokenResponse.data && tokenResponse.data.access_token) {
      localStorage.setItem("pikizyToken", tokenResponse.data.access_token);
    }

    const pikizyToken = tokenResponse.data.access_token;
    const userId = user ? user.id : null;

    if (!pikizyToken) {
      console.error("Erreur : Pikizy token manquant après la requête.");
      return;
    }

    if (!userId) {
      console.error("Erreur : userId manquant.");
      return;
    }

    const requestBody = {
      deviceCode: process.env.REACT_APP_DEVICE_CODE,
      userId: String(userId),
    };

    const checkInResponse = await apiInstance.post(
      "https://api.selflystore.com/api/v1/user-check-in",
      requestBody,
      {
        headers: {
          "X-Application-Access-Key":
            process.env.REACT_APP_X_APPLICATION_ACCESS_KEY,
          Authorization: `Bearer ${pikizyToken}`,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      }
    );



    return checkInResponse.data;
  } catch (error) {
    console.error("Erreur lors de la réalisation du processus:", error);
    throw error;

  }
};

