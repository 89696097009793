import React, { useEffect, useState } from 'react';

// Components
import SideCart from '../../components/side-cart/SideCart';
import Recipes from '../../components/recipes/Recipes';
import PopupWelcome from '../../components/elements/popup/popup-welcome/popupWelcome';

// Styles
import './Home.css';

function Home() {
    const [showPopup, setShowPopup] = useState(false);
    const [openWelcomePopup, setOpenWelcomePopup] = useState(false);

    useEffect(() => {
        const popupShownBefore = sessionStorage.getItem('popupShown');

        if (!popupShownBefore) {
            setOpenWelcomePopup(true);
            sessionStorage.setItem('popupShown', 'true');
        }
    }, []);
    return (
        <div className="home">
            <div className="recipes">
                <Recipes />
            </div>
            <div className="side-cart">
                <SideCart />
            </div>
            <PopupWelcome
                open={openWelcomePopup}
                onClose={() => setOpenWelcomePopup(false)}
                message={
                    <div style={{ textAlign: 'justify' }}>
                        <p>Nous sommes ravis de vous annoncer que notre fonctionnalité de <strong>calcul des calories et du Nutri-Score</strong> est désormais disponible en phase de bêta test. Cette innovation vise à vous aider à mieux comprendre l'impact nutritionnel de vos recettes préférées.</p>
                        <p>Nous sommes impatients de recueillir vos impressions et vos retours pour améliorer cette fonctionnalité. Votre avis est précieux pour nous aider à l'optimiser et à l'adapter encore mieux à vos besoins.</p>
                        <p>Pour toute suggestion ou commentaire, n'hésitez pas à nous écrire à : <a style={{ color: '#006d26' }} href="mailto:hello@mamossa.com">hello@mamossa.com</a></p>
                        <p>Au plaisir de lire vos retours !</p>
                    </div>
                }
            />
        </div>
    );
}

export default Home;
