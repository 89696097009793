import { createSlice } from '@reduxjs/toolkit';

// Function to calculate the total TTC
function calculateTotalTTC(items) {
  return items.reduce((acc, item) => acc + item.price * item.quantity, 0);
}

const initialState = {
  items: JSON.parse(localStorage.getItem('cart')) || [], // Load items from localStorage
  totalTTC: calculateTotalTTC(JSON.parse(localStorage.getItem('cart')) || []), // Initial total TTC calculation
  status: 'new', // Order status starts as 'new'
  orderId: null, // ID of the active order
  error: null,
  isGuest: !localStorage.getItem('user') // Check if the user is logged in or not
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartFromLocalStorage: (state) => {
      const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
      state.items = storedCart;
      state.totalTTC = calculateTotalTTC(storedCart);
    },
    addToCart: (state, action) => {
      console.log("Action addToCart triggered:", action.payload);
      if (!action.payload) return;
      const itemIndex = state.items.findIndex(item => item.id === action.payload.id);
      if (itemIndex !== -1) {
        state.items[itemIndex].quantity += 1;
      } else {
        state.items.push({...action.payload, quantity: 1});
      }
      state.totalTTC = calculateTotalTTC(state.items);
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    incrementQuantity(state, action) {
      const itemIndex = state.items.findIndex(item => item.id === action.payload.id);
      if (itemIndex !== -1) {
        state.items[itemIndex].quantity += 1;
      }
      state.totalTTC = calculateTotalTTC(state.items);
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    decrementQuantity(state, action) {
      const itemIndex = state.items.findIndex(item => item.id === action.payload.id);
      if (itemIndex !== -1 && state.items[itemIndex].quantity > 1) {
        state.items[itemIndex].quantity -= 1;
      }
      state.totalTTC = calculateTotalTTC(state.items);
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    removeFromCart(state, action) {
      state.items = state.items.filter(item => item.id !== action.payload.id);
      state.totalTTC = calculateTotalTTC(state.items);
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    resetCart(state) {
      state.items = [];
      state.totalTTC = 0;
      state.orderId = null;
      localStorage.removeItem('cart');
      localStorage.removeItem('currentOrder');
      localStorage.removeItem('cartExpiration');
    },
    clearUnavailableItems(state) {
      state.items = state.items.filter(item => item.isAvailable);
      state.totalTTC = calculateTotalTTC(state.items);
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    checkCartExpiration(state) {
      const cartExpiration = localStorage.getItem('cartExpiration');
      if (cartExpiration && new Date().getTime() > cartExpiration) {
        state.items = [];
        state.totalTTC = 0;
        state.orderId = null;
        localStorage.removeItem('cart');
        localStorage.removeItem('currentOrder');
        localStorage.removeItem('cartExpiration');
      }
    },
    setOrderId(state, action) {
      state.orderId = action.payload;
    }
  }
});

export const {
  addToCart,
  incrementQuantity,
  decrementQuantity,
  removeFromCart,
  resetCart,
  clearUnavailableItems,
  checkCartExpiration,
  setOrderId,
  setCartFromLocalStorage
} = cartSlice.actions;

export const selectCartItems = state => state.cart.items;

export default cartSlice.reducer;
