import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

//API  
import { fetchCurrentUserInfos, fetchCurrentUserOrders } from "../../api/userAPI";
import { fetchParticipantByUserId } from "../../api/groupOrdersParticipantsAPI";
import { fetchSubscription } from "../../api/subsciptionAPI";
import { setSubscriptionExists, resetSubscription } from '../../features/subscriptions/subscriptionSlice';

//Elements
import Button from '../elements/button/Button';
import PopupFridge from '../elements/popup/popup-fridge/PopupFridge';

//Icons
import { FaRegUser, FaRegStar } from "react-icons/fa";
import { TbTruckDelivery } from "react-icons/tb";
import { IoRestaurantOutline } from "react-icons/io5";
import { PiBowlFood } from "react-icons/pi";

// Styles
import '../../views/admin-dashboard/AdminDashboard.css';

function ClientNavbar({ onSelectSection }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { subscriptionExists, subscriptionInfos } = useSelector(state => state.subscription);
    const [userInfo, setUserInfo] = useState({});
    const [unpaidOrdersExist, setUnpaidOrdersExist] = useState(false);
    const [readyOrderExist, setReadyOrderExist] = useState(false);
    const [showPopupFridge, setShowPopupFridge] = useState(false);
    const [readyOrderNum, setReadyOrderNum] = useState(null);
    const [groupOrderExist, setGroupOrderExist] = useState(false);

    // Récupérer toutes les informations utilisateur, commandes et abonnement
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch user info
                const userInfoData = await fetchCurrentUserInfos();
                setUserInfo(userInfoData);

                // Fetch subscription info
                const subscriptionData = await fetchSubscription(userInfoData.id);
                if (subscriptionData?.active) {
                    dispatch(setSubscriptionExists({
                        exists: true,
                        subscriptionInfos: subscriptionData,
                    }));
                } else {
                    dispatch(resetSubscription());
                }

                // Fetch orders info
                const ordersData = await fetchCurrentUserOrders();
                if (ordersData && ordersData.length > 0) {
                    const lastOrder = ordersData[0];
                    setUnpaidOrdersExist(!lastOrder.payment_status);
                    setReadyOrderExist(lastOrder.status === "accepted");
                    setReadyOrderNum(lastOrder.order_num);
                }

                // Fetch group order participant status
                const isParticipant = await fetchParticipantByUserId(userInfoData.id);
                setGroupOrderExist(Boolean(isParticipant && !ordersData[0].payment_status));

            } catch (error) {
                console.error("Erreur lors de la récupération des données utilisateur:", error);
            }
        };

        fetchData();
    }, [dispatch]);

    const handleSectionClick = (section) => {
        console.log("Section clicked:", section);
    
        // Redirection basée sur l'abonnement actif
        if ((section === "recipes" || section === "menulist") && (!subscriptionExists || !subscriptionInfos?.active)) {
            onSelectSection("subscriptions");
        } else {
            onSelectSection(section);
        }
    };

    const handleButtonClick = () => {
        if (unpaidOrdersExist) {
            navigate("/checkout");
        } else if (readyOrderExist) {
            setShowPopupFridge(true);
        } else {
            navigate("/home");
        }
    };

    const handleActionSuccess = () => {
        setReadyOrderExist(false);
    };

    return (
        <nav className="admin-navbar">
            <div className="admin-title">
                <h1>Bonjour {userInfo.firstname || "Stranger"}</h1>
            </div>
            <Link className="admin-navbar-links" onClick={() => handleSectionClick("profil")}>
                <FaRegUser className="admin-navbar-icons" size={15} /> Profil
            </Link>
            <Link className="admin-navbar-links" onClick={() => handleSectionClick("orders")}>
                <TbTruckDelivery className="admin-navbar-icons" size={15} /> Commandes
            </Link>
            {groupOrderExist && (
                <Link className="admin-navbar-links" onClick={() => handleSectionClick("group-orders")}>
                    <TbTruckDelivery className="admin-navbar-icons" size={15} /> Suivi de commande
                </Link>
            )}
            <Link className="admin-navbar-links" onClick={() => handleSectionClick("subscriptions")}>
                <FaRegStar className="admin-navbar-icons" size={15} /> Abonnements
            </Link>
            {subscriptionExists && subscriptionInfos.subscription_type === "pro_chef" && (
                <Link className="admin-navbar-links" onClick={() => handleSectionClick("stocks")}>
                    <IoRestaurantOutline className="admin-navbar-icons" size={15} /> Produits
                </Link>
            )}
            <Link className="admin-navbar-links" onClick={() => handleSectionClick("recipes")}>
                <IoRestaurantOutline className="admin-navbar-icons" size={15} /> Recettes
            </Link>
            <Link className="admin-navbar-links" onClick={() => handleSectionClick("menulist")}>
                <PiBowlFood className="admin-navbar-icons" size={15} /> Menu Maker
            </Link>
            <div className="client-action">
                {(unpaidOrdersExist || readyOrderExist) && (
                    <button className="btn-navbar" onClick={handleButtonClick}>
                        {unpaidOrdersExist ? "Payer la commande" : "Ouvrir le frigo"}
                    </button>
                )}
            </div>
            {showPopupFridge && (
                <PopupFridge
                    isOpen={showPopupFridge}
                    onConfirm={() => setShowPopupFridge(false)}
                    onActionSuccess={handleActionSuccess}
                    readyOrderNum={readyOrderNum}
                />
            )}
        </nav>
    );
}

export default ClientNavbar;
