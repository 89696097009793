import { useState, useRef, useEffect } from "react";

// Elements
import ButtonPerso from "../elements/button/Button"; 
import PopupUpdate from "../elements/popup/popup-update/PopupUpdate";
import ClientAllergies from "./ClientAllergies";
import ClientImpact from "./ClientImpact"; 
import PopupAllergy from "../elements/popup/popup-allergy/PopupAllergy";
import BackButton from "../elements/backbutton/BackButton";

// Images
import DefautProfilPicture from "../../assets/profil/profil-picture.webp"; 

// MUI Components
import Button from '@mui/joy/Button'; 
import Add from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

// API
import { uploadProfilPicture } from "../../api/userAPI";

// Toast
import toast from 'react-hot-toast';


function ClientInfos({ userInfo, onClose }) {
  // Local States
  const [userInfos, setUserInfos] = useState(userInfo);
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [isAllergyPopupOpen, setIsAllergyPopupOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(userInfo);
  const [profilPicture, setProfilPicture] = useState("");

  // Format date to DD/MM/YYYY
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  // Verify and update profile picture state
  useEffect(() => {
    if (userInfo.profilePicture) {
      setProfilPicture(userInfo.profilePicture);
    }
    setUserInfos(userInfo); // Reinject updated user info when it changes
  }, [userInfo]);

  // Reference for the hidden input file
  const fileInputRef = useRef(null);

  // Open hidden input file when user clicks
  const handleEditIconClick = () => {
    fileInputRef.current.click();
  };

  // Update userInfos state when personal info is updated by the user
  function handleUpdateConfirm(updatedData) {
    setUserInfos(updatedData); // Update locally the user info
    setOpenUpdatePopup(false);
  }

  // Handle the change of profile picture
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const modifiedFileName = file.name.replace(/\s+/g, '_');
        const renamedFile = new File([file], modifiedFileName, { type: file.type });

        const userId = userInfo.id;
        const response = await uploadProfilPicture(userId, renamedFile);
        toast.success("Votre photo de profil sera mise à jour");

        const updatedUserInfo = {
          ...userInfos,
          profilePicture: `${process.env.REACT_APP_API_URL}${response.profilePictureUrl}`
        };

        setUserInfos(updatedUserInfo); // Update the user info locally
        setProfilPicture(`${process.env.REACT_APP_API_URL}${response.profilePictureUrl}`);
      } catch (error) {
        console.error("Erreur lors de l'upload de la photo de profil", error);
        toast.error("Erreur lors de l'upload de la photo de profil");
      }
    }
  };

  // Open update popup for user information
  const handleInfosClick = () => {
    setSelectedUser(userInfos); // Select current user info
    setOpenUpdatePopup(true);
  };

  // Set fields displayed in UpdatePopup
  const profilFields = [
    { name: 'firstname', label: 'Prénom' },
    { name: 'lastname', label: 'Nom' },
    { name: 'email', label: 'Email' },
  ];

  return (
    <div className="client-infos-container container flex-center" style={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center',
        textAlign: 'center',
        padding: '20px',  
      }}>
      
      <div className="client-infos-picture" style={{ position: 'relative' }}>
        <img
          src={userInfo.picture ? `${process.env.REACT_APP_API_URL}${userInfo.picture}` : DefautProfilPicture}
          alt="Client Profil"
          className="client-picture"
          style={{
            width: '150px',       
            height: '150px',      
            borderRadius: '50%',  
            objectFit: 'cover',   
          }}
        />
        <div className="client-picture-actions" style={{
          position: 'absolute',
          top: '0', 
          right: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          borderRadius: '50%',
          padding: '5px',
          cursor: 'pointer',
        }}>
          <EditIcon sx={{ color: "#fff", fontSize: "24px", cursor: "pointer" }} onClick={handleEditIconClick} />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleFileChange}
          />
        </div>
      </div>

      {/* User Info Section */}
     <div className="client-infos-perso" style={{marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', width: '100%'}}>
  
  {/* <div className="client-infos-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0rem 3rem' }}> */}
    <h2 style={{ flex: 1, textAlign: 'center', margin: 0 }}>Mes Informations</h2> 
  {/* </div> */}

  <div className="client-full-name" style={{ marginBottom: '10px', fontSize: '18px' }}>
    {userInfos.firstname} {userInfo.lastname}
  </div>
  
  <div className="client-mail" style={{ marginBottom: '10px', fontSize: '16px' }}>
    {userInfo.email}
  </div>
  
  <div className="client-inscription" style={{ marginBottom: '10px', fontSize: '14px', color: 'gray' }}>
    Inscrit depuis le {formatDate(userInfo.createdAt)}
  </div>
</div>


      <div className="client-infos-action flex-center" style={{ marginTop: '20px' }}>
        <ButtonPerso
          text="Modifier mes informations"
          className="btn-border"
          onClick={() => handleInfosClick(userInfo)}
        />
        {openUpdatePopup && (
          <PopupUpdate
            open={openUpdatePopup}
            onClose={() => setOpenUpdatePopup(false)}
            initialData={selectedUser}
            fields={profilFields}
            title="Modifier le profil"
            onConfirm={handleUpdateConfirm}
          />
        )}
      </div>

      {/* Allergies Section */}
      <div className="client-infos-allergies" style={{ marginTop: '20px' }}>
        <div className="client-allergies-title">
          <h2 className="flex-center" style={{ marginBottom: 4 }}>Mes allergies</h2>
          <ClientAllergies />
          <PopupAllergy
            trigger={
              <Button
                color="success"
                variant="plain"
                size="sm"
                sx={{ width: "200px" }}
                startDecorator={<Add />}
                onClick={() => setIsAllergyPopupOpen(true)}
              >
                Ajouter une allergie
              </Button>
            }
            userId={userInfo.id}
            onClose={() => setIsAllergyPopupOpen(false)}
          />
        </div>
      </div>

      {/* Impact Section */}
      <div className="flex-center" style={{ marginTop: "20px" }}>
        <ClientImpact />
      </div>

      <div style={{ marginTop: "20px" }}>
      <BackButton onClick={() => window.history.back()} />
      </div>

    </div>
  );
}

export default ClientInfos;
