import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
    Accordion, AccordionSummary, AccordionDetails, Typography, IconButton, Button, InputAdornment, Badge, styled
} from "@mui/material";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import { CiEdit } from 'react-icons/ci';
import { TiDeleteOutline } from 'react-icons/ti';
import Chip from '@mui/joy/Chip';

import { fetchAllGroupOrders, deleteGroupOrderById, updateGroupOrderById } from "../../../api/groupOrdersAPI";
import { fetchGroupOrdersParticipants } from "../../../api/groupOrdersParticipantsAPI";
import PopupConfirm from '../../elements/popup/popup-confirm/PopupConfirm';
import EditPopup from "../../elements/popup/popup-edit/popupEdit";
import GroupOrdersInfos from "../../elements/popup/popup-group-orders/GroupOrdersInfos";
import toast from 'react-hot-toast';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    marginBottom: "0rem",
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    color: theme.palette.common.black,
    fontWeight: "bold",
    cursor: "pointer",
    padding: "0 1rem",
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    padding: "0rem 2rem",
}));

const GroupOrdersMobile = () => {
    const [orders, setOrders] = useState([]);
    const [sortedOrders, setSortedOrders] = useState([]);
    const [openEditPopup, setOpenEditPopup] = useState(false);
    const [openInfosPopup, setOpenInfosPopup] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'ascending' });
    const [searchTerm, setSearchTerm] = useState("");
    const ordersPerPage = 8;

    useEffect(() => {
        const getAllOrders = async () => {
            try {
                const fetchedOrders = await fetchAllGroupOrders();
                setOrders(fetchedOrders);
                const orderParticipantsPromises = fetchedOrders.map(async (order) => {
                    const groupOrderId = order.id;
                    if (groupOrderId) {
                        try {
                            await fetchGroupOrdersParticipants(groupOrderId);
                        } catch (error) {
                            console.error(`Erreur lors de la récupération des participants de la commande groupée avec l'ID ${groupOrderId}`, error);
                        }
                    }
                });
                await Promise.all(orderParticipantsPromises);
            } catch (error) {
                console.error("Erreur lors de la récupération des commandes:", error);
            }
        };
        getAllOrders();
    }, []);

    useEffect(() => {
        const ordersToSort = [...orders];
        ordersToSort.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setSortedOrders(ordersToSort);
    }, [sortConfig, orders]);

    const filteredOrders = useMemo(() => sortedOrders.filter(order =>
        (order.id?.toString() || "").includes(searchTerm) ||
        (order.postal_code?.toString() || "").includes(searchTerm) ||
        (order.status?.toLowerCase() || "").includes(searchTerm.toLowerCase())
    ), [sortedOrders, searchTerm]);

    const handleSort = useCallback(key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    }, [sortConfig]);

    const handleNextPage = useCallback(() => {
        if (currentPage < Math.ceil(orders.length / ordersPerPage)) {
            setCurrentPage(prev => prev + 1);
        }
    }, [currentPage, orders, ordersPerPage]);

    const handlePreviousPage = useCallback(() => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    }, [currentPage]);

    const handleEditClick = (order) => {
        setSelectedOrder(order);
        setOpenEditPopup(true);
    };

    const handleInfosClick = (order) => {
        setSelectedOrder(order);
        setOpenInfosPopup(true);
    };

    const handleSaveOrder = async (updatedOrderData) => {
        try {
            const id = updatedOrderData.id;
            await updateGroupOrderById(id, updatedOrderData);
            setOpenEditPopup(false);
            toast.success('Commande mise à jour avec succès');
            const updatedOrders = orders.map(order =>
                order.id === updatedOrderData.id ? updatedOrderData : order
            );
            setOrders(updatedOrders);
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la commande", error);
            toast.error('Erreur lors de la mise à jour de la commande');
        }
    };

    const handleConfirmDelete = useCallback(async (orderId) => {
        try {
            await deleteGroupOrderById(orderId);
            toast.success('Commande groupée supprimée avec succès');
            const updatedOrders = orders.filter(order => order.id !== orderId);
            setOrders(updatedOrders);
        } catch (error) {
            toast.error('Erreur lors de la suppression de la commande groupée');
        }
    }, [orders]);

    return (
        <div className="orders-table">
            <div className="flex-center" style={{ display: "flex", marginBottom: "1rem" }}>
                <input
                    placeholder="Rechercher"
                    variant="outlined"
                    className="search-bar"
                    color="success"
                    fullWidth
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon style={{ fontSize: "15px" }} />
                            </InputAdornment>
                        ),
                    }}
                    style={{ margin: '10px', width: '99%', backgroundColor: "transparent" }}
                />
            </div>
            {filteredOrders.slice((currentPage - 1) * ordersPerPage, currentPage * ordersPerPage).map(order => (
                <StyledAccordion key={order.id}>
                    <StyledAccordionSummary sx={{ width: "100%" }}>
                        <Typography style={{ flexBasis: '100%', display: "flex", alignItems: "center" }} sx={{ color: "#000" }}>
                            <Chip color="success">{order.postal_code}</Chip>

                            <IconButton onClick={() => handleInfosClick(order)}>
                                <InfoIcon style={{ fontSize: "15px" }} sx={{ color: "#006d26" }} />
                            </IconButton>
                        </Typography>
                    </StyledAccordionSummary>

                    <StyledAccordionDetails>
                        <Typography variant="outlined">Participants: {order.current_participants}/{order.max_participants}</Typography>
                        <Typography variant="outlined">Statut: {order.status}</Typography>
                        <Typography variant="outlined">
                            Date de création: {new Date(order.createdAt).toLocaleString('fr-FR', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                            })}
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem' }}>
                            <IconButton onClick={() => handleEditClick(order)}>
                                <CiEdit size={15} />
                                <Typography variant="outlined" sx={{ fontSize: "12px", paddingLeft: "1rem" }}> Modifier la commande groupée </Typography>
                            </IconButton>

                            <EditPopup
                                open={openEditPopup}
                                onClose={() => setOpenEditPopup(false)}
                                data={selectedOrder}
                                onSave={handleSaveOrder}
                                fields={[{ name: 'postal_code', label: 'Code Postal' }, { name: 'status', label: 'Statut' }]}
                            />



                            <GroupOrdersInfos
                                open={openInfosPopup}
                                onClose={() => setOpenInfosPopup(false)}
                                data={selectedOrder}
                            />

                        </div>
                    </StyledAccordionDetails>
                </StyledAccordion>
            ))}
            <div className="pagination" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "1rem" }}>
                <IconButton onClick={handlePreviousPage} disabled={currentPage === 1}>
                    <ArrowBackIosNewIcon />
                </IconButton>
                <div>{currentPage}</div>
                <IconButton onClick={handleNextPage} disabled={currentPage >= Math.ceil(orders.length / ordersPerPage)}>
                    <ArrowForwardIosIcon />
                </IconButton>
            </div>
        </div>
    );
};

export default GroupOrdersMobile;
