import { useEffect, useState } from "react";

// API
import { fetchParticipantByUserId } from "../../api/groupOrdersParticipantsAPI";
import { fetchGroupOrderById } from "../../api/groupOrdersAPI";
import { getItemsByOrderId } from "../../api/orderItemsAPI";
import { fetchRecipeById } from "../../api/recipesAPI";

// MUI
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import CardContent from '@mui/joy/CardContent';
import Card from '@mui/joy/Card';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function GroupOrdersTracking() {
    const [participant, setParticipant] = useState({});
    const [groupOrderInfos, setGroupOrderInfos] = useState({});
    const [orderInfos, setOrderInfos] = useState({});
    const [orderItems, setOrderItems] = useState([]);
    const [recipes, setRecipes] = useState([]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");

    useEffect(() => {
        const getParticipant = async () => {
            try {
                const userId = JSON.parse(localStorage.getItem("user")).id;
                const participantData = await fetchParticipantByUserId(userId);

                setParticipant(participantData);

                const id = participantData.group_order_id;
                const fetchedGroupOrder = await fetchGroupOrderById(id);

                setGroupOrderInfos(fetchedGroupOrder);

                const orderId = participantData.order_id;
                const orderData = await getItemsByOrderId(orderId);

                setOrderItems(orderData);

                // Fetch recipes for each recipeId in orderData
                const recipePromises = orderData.map(item => fetchRecipeById(item.recipeId).then(recipe => ({
                    ...recipe,
                    quantity: item.quantity
                })));
                const recipesData = await Promise.all(recipePromises);
                setRecipes(recipesData);


            } catch (error) {
                console.error("Erreur lors de la récupération du participant à la commande groupée:", error);
            }
        };

        getParticipant();
    }, []);

    const progress = groupOrderInfos.max_participants
        ? (groupOrderInfos.current_participants / groupOrderInfos.max_participants) * 100
        : 0;

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('fr-FR', options);
    };

    return (
        <div className="orders-table" style={{ marginBottom: isMobile ? 2 : 0 }}>
            <div className="orders-table-title flex-center">
                <h1 style={{ margin: "2rem 0" }}>Votre commande groupée</h1>
            </div>
            <div style={{ width: isMobile ? "90%" : isTablet ? "80%" : "60%", margin: 'auto' }}>
                <Stack spacing={2} sx={{ flex: 1, width: '100%' }}>
                    <div style={{ marginBottom: "1rem" }}>
                        <LinearProgress variant="determinate" value={progress} color="success" />
                        <Typography level="h3" fontSize="xs" sx={{ mb: 3, textAlign: "center", marginTop: "1rem" }}>
                            Nombre de participants : {groupOrderInfos.current_participants} / {groupOrderInfos.max_participants}
                        </Typography>
                    </div>
                    <Typography level="body" fontSize="sm" sx={{ marginTop: 2, textAlign: "justify" }}>
                        Vous avez passé une commande groupée le <strong>{formatDate(participant.createdAt)}</strong>.
                        <br />
                        <br />
                        Cette commande permet aux personnes résidant dans le {groupOrderInfos.postal_code} (en dehors de la zone de livraison) de pouvoir se faire livrer et déguster les plats Mamossapp dès que le nombre de participants atteindra la limite de <strong>{groupOrderInfos.max_participants} personnes.</strong>
                        <br />
                        <br />
                        Il manque actuellement <strong>{groupOrderInfos.max_participants - groupOrderInfos.current_participants} participants</strong> avant que votre commande ne soit traîtée.
                        <br />
                        <br />
                        L'équipe Mamossa vous informera de l'évolution de votre commande par e-mail 💌
                    </Typography>
                </Stack>
                <Stack spacing={2} sx={{ flex: 1, width: '100%', mt: 2 }}>

                    <Card variant="soft" color="success" sx={{ padding: "1rem", marginBottom: "2rem" }}>
                        <CardContent>
                            <Typography level="title-md" sx={{ mb: 2 }}>Récapitulatif de commande</Typography>
                            {recipes.map((recipe, index) => (
                                <CardContent key={index}>
                                    <Typography level="title-h3" fontSize="sm" sx={{ fontWeight: '600' }}>{recipe.name} </Typography>
                                    <Typography fontSize="xs" sx={{ fontWeight: '600' }}>Prix : {recipe.price * recipe.quantity + " €"} — Quantité : {recipe.quantity}</Typography>
                                    <Typography fontSize="xs">{recipe.description}</Typography>
                                </CardContent>
                            ))}
                        </CardContent>
                    </Card>

                </Stack>
            </div>
        </div >
    );
}
