import React, { useState, useEffect } from "react";

//API
import {
    fetchCombinedProductInfoById
} from "../../../../api/stockAPI";

//MUI
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper
} from "@mui/material";

function SharedRecipeProducts({ recipeId }) {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [recipeProductInfo, setRecipeProductInfo] = useState({});
    const [productData, setProductData] = useState(null);


    const fetchData = async () => {
        try {
            const data = await fetchCombinedProductInfoById(recipeId);
            setProducts(data.productInfos);
            setProductData(data);
            setRecipeProductInfo(
                data.recipeProductInfo.reduce((acc, info) => {
                    acc[info.productId] = info;
                    return acc;
                }, {})
            );
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [recipeId]);










    return (
        <>
            <h2 className="flex-center recipes-products-title">
                Ingrédients
            </h2>
            <Typography level="body-xs" sx={{ textAlign: "center", color: "b3b2b2", fontStyle: "italic" }}>Par défaut, les ingrédients sont donnés pour 4 portions</Typography>

            <div className="recipes-products-details flex-center" >
                <TableContainer component={Paper} style={{ marginBottom: "2rem" }} >
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell>Nom du produit</TableCell>
                                <TableCell>Quantité</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.map((productInfo) => (
                                <TableRow key={productInfo.id}>
                                    <TableCell>
                                        {productInfo.name}
                                    </TableCell>
                                    <TableCell>
                                        {recipeProductInfo[productInfo.id]?.unitQty + " " + productInfo.unitType}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}

export default SharedRecipeProducts;
