import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import Select from 'react-select';
import { fetchProduct, linkProductsToRecipe, createProduct } from '../../../../api/stockAPI';
import { TextField, Button, CircularProgress } from "@mui/material";
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import { fetchAllAllergies } from "../../../../api/allergiesAPI"

import { GoPlus } from "react-icons/go";
import { TiDeleteOutline } from 'react-icons/ti';
import toast from 'react-hot-toast';
import './PopupChoose.css';

// ... (previous imports)

const PopupChooseProduct = ({ trigger, onSelect, recipeId, recipeName }) => {
    const [productList, setProductList] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isNewProductCreated, setIsNewProductCreated] = useState(false);
    const [isAddingNewProduct, setIsAddingNewProduct] = useState(false);
    const [allergiesOptions, setAllergiesOptions] = useState([]);
    const [selectedAllergen, setSelectedAllergen] = useState(null);
    const [newProduct, setNewProduct] = useState({
        name: '',
        unitType: '',
        price: '0.0',
        totalQuantity: 0,
        category: null,
        label: null,
    });

    useEffect(() => {
        const fetchAllergiesData = async () => {
            try {
                const allergies = await fetchAllAllergies();
                const allergiesOptions = allergies.map(allergy => ({
                    value: allergy.id,
                    label: allergy.name
                }));
                setAllergiesOptions(allergiesOptions);
            } catch (error) {
                console.error("Error fetching allergies:", error);
            }
        };

        fetchAllergiesData();
    }, []);


    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const products = await fetchProduct();
                const formattedProducts = products.map((product) => ({
                    label: `${product.name}`,
                    value: product.id,
                    unitType: product.unitType,
                }));

                // Ajoutez l'option "Nouveau produit"
                formattedProducts.push({
                    label: 'Nouveau produit',
                    value: 'newProduct',
                    unitType: '',
                });

                setProductList(formattedProducts);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    const handleProductSelect = (selectedOptions) => {
        if (!selectedOptions || selectedOptions.length === 0) {
            setSelectedProducts([]);
            return;
        }

        const selectedProductsData = selectedOptions.map((selectedOption) => {
            if (selectedOption.value === 'newProduct') {
                return null; // Skip this option in the selected products list
            }

            const selectedProduct = productList.find((product) => product.value === selectedOption.value);
            const existingProduct = selectedProducts.find(
                (existingProduct) => existingProduct.product.value === selectedOption.value
            );
            const quantity = existingProduct ? existingProduct.quantity : 0;

            return {
                product: selectedProduct,
                quantity: quantity,
            };
        });

        const filteredSelectedProducts = selectedProductsData.filter((item) => item !== null);

        setSelectedProducts(filteredSelectedProducts);
    };

    const handleQuantityChange = (index, quantity) => {
        setSelectedProducts((prevList) => {
            const updatedList = [...prevList];
            updatedList[index].quantity = quantity;
            return updatedList;
        });
    };

    const handleLinkProducts = async () => {
        const productIds = selectedProducts.map((selectedProduct) => selectedProduct.product.value);
        const unitQtys = selectedProducts.map((selectedProduct) => selectedProduct.quantity);

        try {
            await linkProductsToRecipe(recipeId, productIds, unitQtys);
            toast.success('Produits liés avec succès');

            onSelect(selectedProducts);
            setSelectedProducts([]);
        } catch (error) {
            console.error('Error linking products to recipe:', error);
            toast.error(`Erreur lors de l'association des produits`);
        }
    };

    const handleSaveNewProduct = async () => {
        try {
            setIsNewProductCreated(true);

            const savedProduct = await createProduct(newProduct);
            toast.success('Produit sauvegardé avec succès');

            setProductList((prevList) => [
                ...prevList,
                {
                    label: savedProduct.name,
                    value: savedProduct.id,
                    unitType: savedProduct.unitType,
                },
            ]);

            setNewProduct({
                name: '',
                unitType: '',
                price: '0.0',
                totalQuantity: 0,
                category: null,
                label: null,
            });
        } catch (error) {
            console.error('Error saving new product:', error);
            toast.error(`Erreur lors de la sauvegarde du nouveau produit`);
        } finally {
            setIsNewProductCreated(false);
            setIsAddingNewProduct(false);
        }
    };


    const handleAddNewProduct = () => {
        setIsAddingNewProduct(true);
        setNewProduct({
            name: '',
            unitType: '',
            price: '0.0',
            totalQuantity: 0,
            category: null,
            label: null,

        });
    };


    const labels = [
        { value: 'bio', label: 'bio' },
        { value: 'local', label: 'local' },
        { value: 'national', label: 'national' },
    ];

    const categories = [
        { value: 'Fruits et Légumes', label: 'Fruits et Légumes' },
        { value: 'Conserves', label: 'Conserves' },
        { value: 'Epices et Condiments', label: 'Epices et Condiments' },
        { value: 'Céréales et Féculents', label: 'Céréales et Féculents' },
        { value: 'Boissons', label: 'Boissons' },
        { value: 'Huiles et matières grasses', label: 'Huiles et matières grasses' },
        { value: 'Légumineuses', label: 'Légumineuses' },
        { value: 'Sucreries snacks', label: 'Sucreries snacks' },
        { value: 'Oléagineux et Fruits secs', label: 'Oléagineux et Fruits secs' },
        { value: 'Aliments transformés', label: 'Aliments transformés' },
    ]

    return (
        <Popup trigger={trigger} modal className="popup-choose-product">
            {close => (
                <div className="popup-content">
                    <h2>Lier des produits à {recipeName}</h2>
                    <div className="selected-products-input">
                        <Select
                            defaultValue={null}
                            isMulti
                            placeholder="Recherchez des produits"
                            backspaceRemovesValue
                            name="products-list"
                            options={productList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(selectedOptions) => {
                                handleProductSelect(selectedOptions);
                            }}
                        />
                    </div>

                    {isNewProductCreated ? (
                        <div className="new-product-fields">
                            <CircularProgress />
                        </div>
                    ) : null}
                    {isAddingNewProduct ? (
                        <div className="new-product-fields">
                            <h3>Ajouter un nouveau produit</h3>
                            <FormControl>
                                <FormLabel>Nom du produit</FormLabel>
                                <Input
                                    className="new-product-fields-textfield"
                                    placeholder="Nouveau produit"
                                    sx={{ width: 300 }}
                                    variant="outlined"
                                    type="text"
                                    value={newProduct.name}
                                    onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
                                />
                            </FormControl>
                            <Select
                                className="new-product-fields-select"
                                size="small"
                                placeholder="Type d'unité"
                                options={[
                                    { value: 'G', label: 'G' },
                                ]}
                                value={newProduct.unitType ? { value: newProduct.unitType, label: newProduct.unitType } : null}
                                onChange={(selectedOption) => setNewProduct({ ...newProduct, unitType: selectedOption?.value || null })}
                            />

                            <FormControl>
                                <FormLabel>Prix au kilo</FormLabel>
                                <Input
                                    className="new-product-fields-textfield"
                                    placeholder="Prix"
                                    variant="outlined"
                                    type="number"
                                    value={newProduct.price}
                                    onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Quantité en stock</FormLabel>
                                <Input
                                    className="new-product-fields-textfield"
                                    placeholder="Quantité totale"
                                    variant="outlined"
                                    type="number"
                                    value={newProduct.totalQuantity}
                                    onChange={(e) => setNewProduct({ ...newProduct, totalQuantity: e.target.value })}
                                />
                            </FormControl>
                            <label htmlFor="labels" className='create-product-label'>Label du produit</label>
                            <select
                                id="labels"
                                value={newProduct.label || ""}
                                onChange={(e) => setNewProduct({ ...newProduct, label: e.target.value })}
                                className="edit-input"
                            >
                                <option value="" disabled hidden>
                                    Aucun Label
                                </option>
                                {labels.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor="category" className='create-product-label'>Catégorie du produit</label>
                            <select
                                id="category"
                                value={newProduct.category}
                                onChange={(e) => setNewProduct({ ...newProduct, category: e.target.value })}
                                className="edit-input"
                            >
                                <option value="" disabled hidden>
                                    Sélectionnez une option
                                </option>
                                {categories.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor="allergies" className='create-product-label'>Allergènes</label>
                            <select
                                id="allergies"
                                value={selectedAllergen || ""}
                                onChange={(e) => setSelectedAllergen(e.target.value === "" ? null : e.target.value)}
                                className="edit-input"
                            >
                                {/* Vos options ici */}

                                <option value="">Aucun</option>
                                {allergiesOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>

                            <Button variant="contained" color="success" onClick={handleSaveNewProduct}>
                                Sauvegarder
                            </Button>
                        </div>
                    ) : null}
                    {isAddingNewProduct ? (
                        <button className='btn-ms btn-add' onClick={() => setIsAddingNewProduct(false)}>
                            Annuler
                        </button>
                    ) : (
                        <button className='btn-ms btn-add btn-new-product' onClick={handleAddNewProduct}>
                            <GoPlus /> Ajouter un nouveau produit
                        </button>
                    )}
                    <div className="selected-products-list">
                        {selectedProducts.map((selectedProduct, index) => (
                            <div key={index} className='selected-products'>
                                <TextField
                                    id="outlined-number"
                                    label={`${selectedProduct.product.label} en ${selectedProduct.product.unitType}`}
                                    type="number"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className="selected-products-quantity"
                                    placeholder="Quantité du produit"
                                    color="success"
                                    value={selectedProduct.quantity}
                                    onChange={(e) => handleQuantityChange(index, e.target.value)}
                                    required
                                />
                            </div>
                        ))}
                    </div>
                    <div className="popup-choose-action">
                        <button className='btn-ms btn-full' onClick={() => { handleLinkProducts(); close() }} disabled={selectedProducts.length === 0}>
                            Sélectionner
                        </button>
                        <button className='btn-ms btn-border' onClick={() => { setSelectedProducts([]); close() }}>
                            Fermer
                        </button>
                    </div>
                </div>
            )}
        </Popup>
    );
};

export default PopupChooseProduct;
