import apiInstance from "./api";
import { getApiHeaders } from "./apiHeaders";

/**
 * Fetches the user addresses from the API.
 *
 * @returns {Promise<Array>} The list of user addresses.
 * @throws {Error} If there is an error fetching the addresses.
 */
export const fetchUserAddresses = async () => {

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/address/list-all`,
      { headers: getApiHeaders() }
    );
    return response.data;

  } catch (error) {
    console.error(
      "Erreur lors de la récupération des adresses",
      error
    );
    throw error;
  }
};

/**
 * Creates an address using the provided form data.
 *
 * @param {Object} formData The form data to create the address.
 * @returns {Promise<Object>} The response data.
 * @throws {Error} If there is an error creating the address.
 */
export const createAddress = async (formData) => {

  try {
    const response = await apiInstance.post(`${process.env.REACT_APP_API_URL}/address/create`,
      formData,
      { headers: getApiHeaders() });

    return response.data;
  } catch (error) {
    console.error("Error creating the address:", error);
    throw error;
  }
};

export const fetchAddressById = async (addressId) => {

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/address/list/${addressId}`,
      { headers: getApiHeaders() });

    return response.data;
  } catch (error) {
    console.error("Error fetching the address:", error);
    throw error;
  }
};
