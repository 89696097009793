import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { logoutAction } from '../features/auth/authSlice';
import { verifyToken } from '../api/authAPI';
import toast from 'react-hot-toast';

function PrivateRoute({ children, rolesRequired, fallbackComponent }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toastShown, setToastShown] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const roles = JSON.parse(localStorage.getItem('roles') || "[]");
  const isUserLoggedIn = !!user;

  useEffect(() => {
    if (!isUserLoggedIn && !toastShown) {
      toast.error("Session expirée. Veuillez vous reconnecter.");
      setToastShown(true);
      setTimeout(() => {
        dispatch(logoutAction());
        navigate('/login');
      }, 2000);
      return;
    }

    const checkTokenValidity = async () => {
      try {
        const { isValid } = await verifyToken(navigate);
        if (!isValid && !toastShown) {
          toast.error("Session expirée. Veuillez vous reconnecter.");
          setTimeout(() => dispatch(logoutAction()), 2000)
        }
      } catch (error) {
        console.error("Erreur lors de la vérification du token:", error);
        if (!toastShown) {
          toast.error("Erreur lors de la vérification de la session.");
        }
      }
    };

    if (isUserLoggedIn) {
      checkTokenValidity();
    }
  }, [dispatch, isUserLoggedIn, navigate, toastShown]);

  if (!isUserLoggedIn) {
    return null;
  }

  if (rolesRequired && rolesRequired.length) {
    const hasRequiredRole = rolesRequired.some(role => roles.includes(role));
    if (!hasRequiredRole) {
      if (fallbackComponent) {
        return React.createElement(fallbackComponent);
      }
      return <Navigate to="/error" replace />;
    }
  }

  return children;
}

export default PrivateRoute;
