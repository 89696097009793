import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const localStorageKey = 'preorderEnabled';

// Fonction pour récupérer les données du localStorage en ajoutant un try...catch
const getLocalStorageValue = () => {
  try {
    const storedValue = localStorage.getItem(localStorageKey);
    return storedValue ? JSON.parse(storedValue) : false;
  } catch (error) {
    console.error("Erreur lors de la récupération de la précommande dans le localStorage:", error);
    return false;
  }
};

const getStoredDateTime = () => {
  try {
    const storedDate = localStorage.getItem('selectedDate');
    const storedTime = localStorage.getItem('selectedTime');
    return {
      selectedDate: storedDate ? dayjs(JSON.parse(storedDate)) : null,
      selectedTime: storedTime || null,
      selectedAddress: null,
    };
  } catch (error) {
    console.error("Erreur lors de la récupération de la date et de l'heure dans le localStorage:", error);
    return {
      selectedDate: null,
      selectedTime: null,
      selectedAddress: null,
    };
  }
};

// Action asynchrone pour changer l'adresse
export const setSelectedAddressAsync = (newAddress) => (dispatch) => {
  dispatch(setSelectedAddress(newAddress));
};

// Création du slice
const preorderSlice = createSlice({
  name: 'preorder',
  initialState: {
    preorderEnabled: getLocalStorageValue(),
    ...getStoredDateTime(),
    selectedDate: null,
    selectedTime: null,
    selectedAddress: null,
  },
  reducers: {
    setPreorderEnabled: (state, action) => {
      const newValue = action.payload;
      state.preorderEnabled = newValue;
      localStorage.setItem(localStorageKey, JSON.stringify(newValue));
    },
    setSelectedDate: (state, action) => {
      const newDate = action.payload;
      if (newDate !== null) {
        state.selectedDate = newDate;
        localStorage.setItem('selectedDate', JSON.stringify(newDate));
      }
    },
    setSelectedTime: (state, action) => {
      const newTime = action.payload;
      if (newTime !== null) {
        state.selectedTime = newTime;
        localStorage.setItem('selectedTime', newTime);
      }
    },
    setSelectedAddress: (state, action) => {
      const newAddress = action.payload;
      state.selectedAddress = newAddress;
      localStorage.setItem('selectedAddress', JSON.stringify(newAddress)); // Mise à jour du localStorage
    },
    // Action pour réinitialiser complètement la précommande
    resetPreorder: (state) => {
      state.preorderEnabled = false;
      state.selectedDate = null;
      state.selectedTime = null;
      state.selectedAddress = null;

      localStorage.removeItem(localStorageKey);
      localStorage.removeItem('selectedDate');
      localStorage.removeItem('selectedTime');
      localStorage.removeItem('selectedAddress');
    },
  },
});

// Exportation des actions et du reducer
export const { setPreorderEnabled, setSelectedDate, setSelectedTime, setSelectedAddress, resetPreorder } = preorderSlice.actions;
export default preorderSlice.reducer;
