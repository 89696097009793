import apiInstance from "./api";

/**
 * Creates a menu with the given menu data.
 *
 * @param {Object} menuData - The data for the menu.
 * @returns {Object} - The response data from the API.
 * @throws {Error} - If an error occurs during the creation of the menu.
 */
export const createMenu = async (formData) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.post(`${process.env.REACT_APP_API_URL}/menus/create`, formData, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("c", error);
    throw error;
  }
};

/**
 * Retrieves a menu by its ID.
*
* @param {string} id - The ID of the menu to retrieve.
* @returns {Object} - The retrieved menu data.
* @throws {Error} - If an error occurs during the retrieval of the menu.
*/
export const getMenuById = async (id) => {

  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/menus/list/${id}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération du menu:", error);
    throw error;
  }
};

export const getMenuByUser = async () => {

  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
  const userId = JSON.parse(localStorage.getItem("user"))?.id;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/menus/list-by-user/${userId}`, {
      headers,
    });

    // Si la réponse est vide ou les menus ne sont pas trouvés, retourner une liste vide
    if (!response.data || response.data.length === 0) {
      console.log(`Aucun menu trouvé pour l'utilisateur : ${userId}`);
      return [];  // Retourne une liste vide au lieu de lever une erreur
    }

    return response.data;
  } catch (error) {
    console.error(`Erreur lors de la récupération du menu pour l'utilisateur : ${userId}`, error);
    return [];  // Retourne une liste vide en cas d'erreur
  }
};



/**
* Retrieves all menus from the API.
*
* @returns {Object} - The retrieved menu data.
* @throws {Error} - If an error occurs during the retrieval of the menus.
*/
export const getAllMenus = async () => {

  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/menus/list-all`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des menus:", error);
    throw error;
  }
};


/**
 * Updates a menu with the given ID.
 *
 * @param {string} id - The ID of the menu to update.
 * @param {Object} updatedMenu - The updated menu data.
 * @returns {Object} - The updated menu data.
 * @throws {Error} - If an error occurs during the update of the menu.
 */
export const updateMenuById = async (id, updatedMenu) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;


  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.put(
      `${process.env.REACT_APP_API_URL}/menus/update/${id}`,
      updatedMenu,
      { headers }
    );

  } catch (error) {
    console.error("Erreur lors de la mise à jour du menu:", error);
    throw error;
  }
};


/**
 * Deletes a menu with the given ID.
 *
 * @param {string} id - The ID of the menu to delete.
 * @returns {Object} - The response data after deletion.
 * @throws {Error} - If an error occurs during the deletion of the menu.
 */
export const deleteMenuById = async (id) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;


  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.delete(
      `${process.env.REACT_APP_API_URL}/menus/delete/${id}`,
      { headers }
    );

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression du menu", error);
    throw error;
  }
};

export const countMenuByUserId = async (userId) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  try {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": "fr",
      "x-access-token": token,
    };

    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/menus/count/${userId}`, { headers });
    return response.data;
  } catch (error) {
    console.error(`Erreur lors du compte des recettes pour l'user ${userId}`, error);
    throw error;
  }
};





