//Components
import RecipesTable from "../../../components/admin-dashboard/recipes/RecipesTable";

//Styles
import './Recipes.css';

function Recipes() {

    return (
        <div className="recipes-admin">
            <div className="recipes-admin-title flex-center">
                <h2>Gestion des recettes</h2>
            </div>

            <div className="recipes-admin-table">
                <RecipesTable />
            </div>

        </div>
    )
}

export default Recipes;
