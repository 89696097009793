import apiInstance from "./api";

export const fetchAllAllergies = async () => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/allergy/all`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error while fetching all allergies", error);
    throw error;
  }
};

export const createAllergyByUserId = async (userId, allergies) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
  console.log('allergies', allergies)

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    // Envoyer la requête pour mettre à jour les allergies de l'utilisateur
    const response = await apiInstance.post(
      `${process.env.REACT_APP_API_URL}/users-allergy/create`,
      {
        userId: userId,
        allergyId: allergies
      },
      { headers }
    );

    return response.data;
  } catch (error) {
    console.error("Error while updating user allergies", error);
    throw error;
  }
};

export const updateAllergiesByUserId = async (userId, allergyIds) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    // Envoyer la requête PUT pour mettre à jour les allergies de l'utilisateur
    const response = await apiInstance.put(
      `${process.env.REACT_APP_API_URL}/users-allergy/update`,
      {
        userId: userId,
        allergyIds: allergyIds,
      },
      { headers }
    );

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour des allergies", error);
    throw error;
  }
};
