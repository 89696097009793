import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Autocomplete, TextField, Pagination, CircularProgress, useMediaQuery } from "@mui/material";
import { Stack, Button, Typography } from "@mui/material"; // Utilisation de "@mui/material" pour Stack, Button et Typography
import PopupRecipe from '../../elements/popup/popup-recipe/PopupRecipe';
import ButtonComponent from '../../elements/button/Button';
import MobileRecipeCard from "./mobile/MobileRecipeCard";
import { fetchAllRecipePictures, fetchRecipeByUserId, countRecipeByUserId, createRecipe, uploadRecipeImage } from '../../../api/recipesAPI';
import { fetchDataForRecipe } from "../../../api/stockAPI";
import toast from 'react-hot-toast';
import "./mobile/MobileRecipe.css";

function RecipesTable() {
    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");
    const isDesktop = useMediaQuery("(min-width: 1024px)");
    const subscriptionExists = useSelector((state) => state.subscription.subscriptionExists);
    const subscriptionInfos = useSelector((state) => state.subscription.subscriptionInfos);

    const [recipes, setRecipes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedIngredients, setSelectedIngredients] = useState([]);
    const [ingredientsList, setIngredientsList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6);
    const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);
    const [recipeCount, setRecipeCount] = useState(0);

    const roles = JSON.parse(localStorage.getItem('roles') || "[]");
    const isAdmin = roles.includes("admin");

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const fetchRecipesWithDetails = async () => {
        setLoading(true);
        setHasError(false);
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            if (!user) throw new Error("No user found in localStorage");

            const userId = user.id;
            const countData = await countRecipeByUserId(userId);
            setRecipeCount(countData.recipeCount);

            const data = await fetchRecipeByUserId(userId); // ajout de userId comme argument
            const pictures = await fetchAllRecipePictures();

            const recipesWithDetails = await Promise.all(data.map(async (recipe) => {
                const picture = pictures.find(pic => pic.recipe_id === recipe.id);
                if (picture) {
                    recipe.imageUrl = `${process.env.REACT_APP_API_URL}${picture.path}`;
                }
                const recipeDetails = await fetchDataForRecipe(recipe.id);
                return { ...recipe, linkedProducts: recipeDetails.linkedProducts, allProducts: recipeDetails.allProducts };
            }));

            setRecipes(recipesWithDetails);

            // Récupérer la liste des ingrédients pour le filtre
            const allIngredients = recipesWithDetails.flatMap(recipe => recipe.allProducts || []);
            const uniqueIngredients = [...new Set(allIngredients.map(item => item.label))];
            setIngredientsList(uniqueIngredients);
        } catch (error) {
            console.error("Erreur lors de la récupération des recettes et des détails :", error);
            setHasError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRecipesWithDetails();
    }, []);

    const handleCreateRecipe = async (formData) => {
        if (!formData.name || !formData.description || !formData.price || !formData.image || !formData.portions) {
            toast.error("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        try {
            const newRecipeResponse = await createRecipe(formData);
            if (newRecipeResponse && newRecipeResponse.data && newRecipeResponse.data.id) {
                const recipeId = newRecipeResponse.data.id;
                await uploadRecipeImage(recipeId, formData.image);
                toast.success('Recette créée avec succès');
                fetchRecipesWithDetails();
                setIsCreatePopupOpen(false);
            } else {
                toast.error('Erreur lors de la création de la recette.');
            }
        } catch (error) {
            console.error("Erreur lors de la création de la recette", error);
            toast.error('Erreur lors de la création de la recette.');
        }
    };

    const searchTermLower = searchTerm.toLowerCase();
    const filteredRecipes = recipes.filter(recipe => {
        const recipeName = recipe.name ? recipe.name.toLowerCase() : "";
        const recipeDescription = recipe.description ? recipe.description.toLowerCase() : "";

        const matchesSearchTerm = !searchTerm || 
            recipeName.includes(searchTermLower) || 
            recipeDescription.includes(searchTermLower);

        // Filtrer les recettes pour exclure celles qui contiennent les ingrédients sélectionnés
    const containsExcludedIngredients = selectedIngredients.some(selectedIngredient =>
        Array.isArray(recipe.linkedProducts) && // Vérifier si linkedProducts est un tableau
        recipe.linkedProducts.some(linkedProduct => linkedProduct.productLabel === selectedIngredient)
    );

    // Retourner uniquement les recettes qui correspondent au terme de recherche 
    // ET qui ne contiennent PAS d'ingrédients exclus
    return matchesSearchTerm && !containsExcludedIngredients;
});

    console.log("Filtered Recipes:", filteredRecipes); // Voir la liste des recettes filtrées

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredRecipes.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <section className="recipe-list-mobile">
            <div style={{ textAlign: "right", marginRight: "5rem", marginTop: "2rem" }}>
                {(isAdmin || (subscriptionExists && subscriptionInfos.subscription_type !== "free") || (subscriptionExists && subscriptionInfos.subscription_type === "free" && recipeCount === 0)) ? (
                    <PopupRecipe
                        trigger={
                            <ButtonComponent
                                className="btn-add btn-add-product-table"
                                type="button"
                                text="+ Ajouter une recette"
                                onClick={() => setIsCreatePopupOpen(true)} // Correction pour ouvrir le popup
                            />
                        }
                        isOpen={isCreatePopupOpen} // Ajout de la prop isOpen pour PopupRecipe
                        onClose={() => setIsCreatePopupOpen(false)}
                        onCreate={handleCreateRecipe}
                        title="Ajouter une recette"
                        message="Message de confirmation"
                    />
                ) : (
                    <Button
                        className="btn-upgrade"
                        color="error"
                        size="small"
                        onClick={() => window.open('https://billing.stripe.com/p/login/test_3cs9EfeWEfql7ZKeUU', '_blank')}
                    >
                        Mettre à niveau l'abonnement
                    </Button>
                )}
            </div>

            <div className="recipe-mobile-header">
                <TextField
                    className="search"
                    type="text"
                    placeholder="Rechercher une recette..."
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ width: "100%" }}
                />
               <Autocomplete
                    className="search"
                    multiple
                    options={ingredientsList}
                    value={selectedIngredients}
                    onChange={(event, newValue) => {
                    setSelectedIngredients(newValue);
                    console.log("Selected Ingredients:", newValue); // Voir les éléments sélectionnés
                }}
                    renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Exclure les ingrédients..." />
                )}
            />
        </div>
            <div className="recipe-list flex-center">
            {loading ? (
                    <CircularProgress sx={{ color: 'darkgreen' }} />  
                ) : hasError ? (
                    <Typography variant="body2" color="error">
                        Une erreur est survenue lors du chargement des recettes.
                    </Typography>
                ) : currentItems.length > 0 ? (
                    currentItems.map(recipe => (
                        <Link key={recipe.id} to={`/recipes/${recipe.id}`}>
                            <MobileRecipeCard recipe={recipe} isTablet={isTablet} isDesktop={isDesktop} />
                        </Link>
                    ))
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        Aucun résultat trouvé.
                    </Typography>
                )}
            </div>

            <div className="pagination">
                <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: '20px', marginBottom: "1rem" }}>
                    <Pagination
                        size="small"
                        siblingCount={0}
                        count={Math.ceil(filteredRecipes.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        color="primary"
                        showFirstButton
                        showLastButton
                    />
                </Stack>
            </div>
        </section>
    );
}

export default RecipesTable;
