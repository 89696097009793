import React from 'react';
import { useSelector } from 'react-redux';

function OrderPrice() {
    ///Global state
    const cartItems = useSelector(state => state.cart.items); // Ajustez le chemin si nécessaire
    const preorderEnabled = useSelector(state => state.preorder.preorderEnabled);

    // Total TTC
    const totalTTC = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);

    // Total HT 
    const totalHT = totalTTC / (1 + 0.055);

    // TVA
    const tvaValue = totalTTC - totalHT;

    // Texte à afficher en fonction de preorderEnabled
    const commandeText = preorderEnabled ? "pré-commande" : "commande";

    return (
        <div className="order-validation-total">
            <div className="order-validation-total-ht">
                <h2 className="total-ht-text">Total HT de la {commandeText}</h2>
                <p className="total-ht-price">{totalHT.toFixed(2)}€</p>
            </div>
            <div className="order-validation-tva">
                <h2 className="tva-text">TVA</h2>
                <p className="tva-value">{tvaValue.toFixed(2)}€</p>
            </div>
            <div className="order-validation-ttc">
                <h2 className="ttc-text">Total TTC de la {commandeText}</h2>
                <p className="ttc-price">{totalTTC.toFixed(2)}€</p>
            </div>
        </div>
    );
}

export default OrderPrice;
