import React, { useEffect, useState } from 'react';

//Component
import MenusTable from '../../../components/admin-dashboard/menus/MenusTable';

// Styles
import './Menu.css';

function MenuList() {

    return (
        <div className="menus">
            <div className="menus-title">
                <h1>Vos Menus</h1>
            </div>
            <MenusTable />
        </div>
    );
}

export default MenuList;
