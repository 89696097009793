function Button({ text, type = 'button', onClick, className, disabled = false }) {
    return (
        <button
            type={type}
            onClick={onClick}
            className={`custom-btn ${className}`}
            disabled={disabled}
        >
            {text}
        </button>
    );
}

export default Button;
