import apiInstance from "./api";


export const createShoppingList = async (formData) => {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
  
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "x-access-token": token,
    };
  
    try {
      const response = await apiInstance.post(`${process.env.REACT_APP_API_URL}/shopping-lists/create`, formData, {
        headers,
      });

      return response.data;
    } catch (error) {
      console.error("Erreur pendant la création de la Shopping List", error);
      throw error;
    }
  };

  export const updateShoppingList = async (updatedProductData, id) => {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
  
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "x-access-token": token,
    };
  
    try {
      const response = await apiInstance.put(`${process.env.REACT_APP_API_URL}/shopping-lists/update/${id}`, updatedProductData, {
        headers,
      });
      return response.data;
    } catch (error) {
      console.error("Erreur pendant l'update de la Shopping List'", error);
      throw error;
    }
  };

  export const findProductsByMenuId = async (id) => {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
  
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "x-access-token": token,
    };
  
    try {
      const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/shopping-lists/list/${id}`, {
        headers,
      });
      return response.data;
    } catch (error) {
      console.error("Erreur pendant le fetch de la Shopping List'", error);
      throw error;
    }
  };