export const getApiHeaders = () => {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;


    return {
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": "fr",
        "x-access-token": token,
    };
};
