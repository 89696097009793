import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPreorderEnabled, setSelectedDate, setSelectedTime } from '../../features/preorder/preorderSlice';
import { useNavigate } from 'react-router-dom';
import { setUserPostalCodeAsync } from '../../features/delivery-zones/DeliverySlice';
import PopupGroupOrders from '../../components/elements/popup/popup-group-orders/PopupGroupOrders';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Image
import Picture from "../../assets/recipes/delivery-zone.jpg";

// MUI
import FormControl from '@mui/joy/FormControl';
import Input from '@mui/joy/Input';
import Button from '@mui/material/Button';

// Styles
import './DeliveryZones.css';

function DeliveryZones() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");

    const [postalCode, setPostalCode] = useState('');
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const deliveryZones = ['93220', '93340', '93250', '93370', '93330'];
    const isInDeliveryZone = useSelector(state => state.deliveryZone.isInDeliveryZone);
    const preorderEnabled = useSelector((state) => state.preorder.preorderEnabled);

    const handleInputChange = (e) => {
        setPostalCode(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await dispatch(setUserPostalCodeAsync(postalCode));
        const isInZone = deliveryZones.includes(postalCode);
        sessionStorage.setItem('userPostalCode', postalCode);


        if (isInZone) {
            await dispatch(setUserPostalCodeAsync(postalCode));
            navigate('/home');
        } else {
            const newPreorderValue = !preorderEnabled;
            dispatch(setPreorderEnabled(newPreorderValue));
            localStorage.removeItem('preorderEnabled');
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="delivery-zones grid grid-cols-12">
            <div className="text-container col-span-6 flex flex-col items-center justify-center py-8 px-4">
                <div className="welcome-text mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">Bienvenue chez Mamossa</h1>
                    <p className="delivery-zones-description text-gray-600">
                        Découvrez des recettes 100% végétale cuisinées de manière artisanale <br /> 
                        Avec 80% d'ingrédients bio ou nationnaux,<br />
                        notre offre est disponible 24h sur 7J partout en France.<br /><br />
                        Selon votre localité, certains services peuvent être indisponibles.

                        Bon appétit !
                    </p>
                </div>
                <div className="input-container flex flex-col items-center">
                    <p className="text-gray-700 mb-3">Votre Code postal ? </p>
                    <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-4">
                        <FormControl variant="standard">
                            <Input
                                placeholder="Entrez votre code postal"
                                value={postalCode}
                                onChange={handleInputChange}
                                className="w-full p-2 rounded-md border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                            />
                        </FormControl>
                        <div className="btn-container">
                            <Button type="submit" variant="contained" color="success" sx={{ marginBottom: "2rem" }} className="w-full py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-md">
                                Vérifier
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="image-container col-span-6 h-90vh">
                <img src={Picture} alt="Delivery Zone" className="w-full h-full object-cover" />
            </div>
            <PopupGroupOrders open={open} handleClose={handleClose} />
        </div>
    );
}

export default DeliveryZones;
