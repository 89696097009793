import apiInstance from "./api";
import { fetchCurrentUserOrders } from "./userAPI";

/**
 * Creates an order with the given order data.
 * @param {Object} orderData - The data for the order.
 * @param {string} orderData.userId - The ID of the user placing the order.
 * @param {number} orderData.totalTTC - The total price of the order (total TTC).
 * @returns {Promise<Object>} - The created order.
 */
const createOrder = async (orderData) => {
  console.log("Création d'une commande avec les données suivantes :", orderData);

  // Handle price rounding and validation
  orderData.price = Math.round(parseFloat(orderData.totalTTC));

  if (isNaN(orderData.price)) {
    console.error("Le prix total (totalTTC) est invalide :", orderData.price);
    orderData.price = 0; // Default to 0 if price is invalid
  }

  console.log("Prix envoyé :", orderData.price);

  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    console.error("Utilisateur non authentifié.");
    throw new Error("Utilisateur non authentifié.");
  }

  const token = user.accessToken;
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    console.log("Envoi de la requête POST à /orders avec les headers et le token :", headers);
    const response = await apiInstance.post(
      `${process.env.REACT_APP_API_URL}/orders`,
      orderData,
      { headers }
    );
    console.log("Commande créée avec succès :", response.data);
    localStorage.setItem("currentOrder", JSON.stringify(response.data)); // Stocker la commande créée
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création de la commande :", error.response ? error.response.data : error.message);
    throw error;
  }
};

/**
 * Fetches an order by ID.
 * @param {string} orderId - The ID of the order to retrieve.
 * @returns {Promise<Object>} - The order data.
 */
const fetchOrderById = async (orderId) => {
  console.log(`Récupération de la commande avec l'ID : ${orderId}`);
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    console.error("Utilisateur non authentifié.");
    throw new Error("Utilisateur non authentifié.");
  }

  const token = user.accessToken;
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    console.log("Envoi de la requête GET à /orders avec les headers et le token :", headers);
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/orders/${orderId}`, { headers });
    console.log("Commande récupérée avec succès :", response.data);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération de la commande :", error.response ? error.response.data : error.message);
    throw error;
  }
};

/**
 * Retrieves order details by order number.
 * @param {string} orderNum - The order number to search for.
 * @returns {Promise<Object|null>} - The order details or null if not found.
 */
const getOrderDetailsByOrderNum = async (orderNum) => {
  console.log(`Recherche des détails de la commande avec le numéro : ${orderNum}`);
  try {
    const ordersArray = await fetchCurrentUserOrders();
    console.log("Liste des commandes récupérées pour l'utilisateur :", ordersArray);
    const orderDetails = ordersArray.find((order) => order.order_num === orderNum);
    console.log("Détails de la commande trouvée :", orderDetails);
    return orderDetails || null;
  } catch (error) {
    console.error("Erreur dans getOrderDetailsByOrderNum :", error.response ? error.response.data : error.message);
    return null;
  }
};

/**
 * Updates the payment status of the current order.
 */
const updatePaymentStatus = async () => {
  console.log("Mise à jour du statut de paiement de la commande en cours.");
  const currentOrder = JSON.parse(localStorage.getItem('currentOrder'));
  if (!currentOrder) {
    console.error("Aucune commande active trouvée.");
    throw new Error("Aucune commande active trouvée.");
  }

  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    console.error("Utilisateur non authentifié.");
    throw new Error("Utilisateur non authentifié.");
  }

  const token = user.accessToken;
  const orderNum = currentOrder.order_num;
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    console.log("Envoi de la requête PUT pour mettre à jour le statut de paiement à 1.");
    const response = await apiInstance.put(
      `${process.env.REACT_APP_API_URL}/orders/${orderNum}/payment_status`,
      { payment_status: 1 },
      { headers }
    );

    if (response.data) {
      console.log("Statut de paiement mis à jour avec succès :", response.data);
      localStorage.setItem("currentOrder", JSON.stringify(response.data)); // Mise à jour de la commande
    }

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du statut de paiement :", error.response ? error.response.data : error.message);
    throw error;
  }
};

/**
 * Updates the status of an order by ID (e.g., accepted, completed).
 * @param {string} orderId - The ID of the order to update.
 * @param {string} status - The new status of the order.
 * @returns {Promise<Object>} - The updated order.
 */
const updateOrderStatusbyId = async (orderId, status = "completed") => {
  console.log(`Mise à jour du statut de la commande avec l'ID ${orderId} au statut ${status}.`);
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    console.error("Utilisateur non authentifié.");
    throw new Error("Utilisateur non authentifié.");
  }

  const token = user.accessToken;
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    console.log("Envoi de la requête PUT pour mettre à jour le statut de la commande.");
    const response = await apiInstance.put(
      `${process.env.REACT_APP_API_URL}/orders/${orderId}`,
      { status },
      { headers }
    );

    if (response.data) {
      console.log("Statut de commande mis à jour avec succès :", response.data);
      localStorage.setItem("currentOrder", JSON.stringify(response.data)); // Mise à jour de la commande
    }

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du statut de commande :", error.response ? error.response.data : error.message);
    throw error;
  }
};
/**
 * Updates an order by ID with new details.
 * @param {string} orderId - The ID of the order to update.
 * @param {Object} updatedOrder - The new details of the order.
 * @returns {Promise<Object>} - The updated order data.
 */
const updateOrderById = async (orderId, updatedOrder) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user.accessToken;

    const headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "x-access-token": token
    };

    try {
        console.log("Données envoyées pour la mise à jour de commande:", updatedOrder);  // Log pour voir les données
        const response = await apiInstance.put(
            `${process.env.REACT_APP_API_URL}/orders/${orderId}`,
            { ...updatedOrder, price: parseFloat(updatedOrder.price).toFixed(2) },  // S'assurer que le prix est bien formaté
            { headers }
        );
        console.log("Order updated successfully:", response.data);
        return response.data;
    } catch (error) {
        console.error("Erreur lors de la mise à jour de la commande:", error.response ? error.response.data : error.message);
        throw error;
    }
};
/**
 * Updates the status of an order to 'new' by ID.
 * @param {string} orderId - The ID of the order to update to 'new' status.
 * @returns {Promise<Object>} - The updated order data.
 */
const updateOrderStatusNewbyId = async (orderId, updatedOrder) => {
  console.log(`Mise à jour du statut de la commande avec l'ID ${orderId} à 'new'.`);
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    console.error("Utilisateur non authentifié.");
    throw new Error("Utilisateur non authentifié.");
  }

  const token = user.accessToken;
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.put(
      `${process.env.REACT_APP_API_URL}/orders/${orderId}`,
      { ...updatedOrder, 
       price: updatedOrder.price, 
       status: "new" },
      { headers }
    );
    console.log("Statut de commande mis à jour avec succès à 'new' :", response.data);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du statut de commande à 'new'", error.response ? error.response.data : error.message);
    throw error;
  }
};

/**
 * Deletes an order by ID.
 * @param {string} orderId - The ID of the order to delete.
 * @returns {Promise<Object>} - The deleted order data.
 */
const deleteOrderById = async (orderId) => {
  console.log(`Suppression de la commande avec l'ID : ${orderId}`);
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    console.error("Utilisateur non authentifié.");
    throw new Error("Utilisateur non authentifié.");
  }

  const token = user.accessToken;
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.delete(
      `${process.env.REACT_APP_API_URL}/orders/${orderId}`,
      { headers }
    );
    console.log("Commande supprimée avec succès :", response.data);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de la commande", error.response ? error.response.data : error.message);
    throw error;
  }
};

// Ensure all necessary exports are handled
export {
  createOrder,
  fetchOrderById,
  getOrderDetailsByOrderNum,
  updatePaymentStatus,
  updateOrderStatusbyId,
  updateOrderById,
  updateOrderStatusNewbyId,
  deleteOrderById,
};
