import React, { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import '../popup-edit/popupEdit.css';

export default function PopupEditProduct({ open, onClose, data, onSave, categories, labels }) {
    const [editedData, setEditedData] = useState({});

    const categoriesOptions = [
        { value: 'Fruits et Légumes', label: 'Fruits et Légumes' },
        { value: 'Conserves', label: 'Conserves' },
        { value: 'Epices et Condiments', label: 'Epices et Condiments' },
        { value: 'Céréales et Féculents', label: 'Céréales et Féculents' },
        { value: 'Boissons', label: 'Boissons' },
        { value: 'Huiles et matières grasses', label: 'Huiles et matières grasses' },
        { value: 'Légumineuses', label: 'Légumineuses' },
        { value: 'Sucreries snacks', label: 'Sucreries snacks' },
        { value: 'Oléagineux et Fruits secs', label: 'Oléagineux et Fruits secs' },
        { value: 'Aliments transformés', label: 'Aliments transformés' },
    ]

    const labelsOptions = [
        { value: 'bio', label: 'bio' },
        { value: 'local', label: 'local' },
        { value: 'national', label: 'national' },
    ];

    useEffect(() => {
        if (data) {
            setEditedData(data);
        }
    }, [data]);

    const handleFieldChange = (field, value) => {
        setEditedData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleSave = () => {
        onSave(editedData);
        onClose();
    };

    return (
        <>
            {open && (
                <>
                    <div className="overlay" onClick={onClose}></div>
                    <div className="edit-modal">
                        <div className="edit-modal-title">
                            <h2>Modifier le produit</h2>
                        </div>
                        <div className="edit-modal-form">
                            <div className="edit-fields">
                                <div className="edit-field">
                                    <label htmlFor="name">Nom du produit</label>
                                    <input
                                        id="name"
                                        type="text"
                                        value={editedData.name || ""}
                                        onChange={(e) => handleFieldChange("name", e.target.value)}
                                        className="edit-input"
                                    />
                                </div>
                                <div className="edit-field">
                                    <label htmlFor="price">Prix</label>
                                    <input
                                        id="price"
                                        type="text"
                                        value={editedData.price || ""}
                                        onChange={(e) => handleFieldChange("price", e.target.value)}
                                        className="edit-input"
                                    />
                                </div>
                                <div className="edit-field">
                                    <label htmlFor="totalQuantity">Quantité Totale</label>
                                    <input
                                        id="totalQuantity"
                                        type="text"
                                        value={editedData.totalQuantity || ""}
                                        onChange={(e) => handleFieldChange("totalQuantity", e.target.value)}
                                        className="edit-input"
                                    />
                                </div>
                                <div className="edit-field">
                                    <label htmlFor="category">Catégorie</label>
                                    <select
                                        id="category"
                                        value={editedData.category || ""}
                                        onChange={(e) => handleFieldChange("category", e.target.value)}
                                        className="edit-input"
                                    >
                                        <option value="">Sélectionnez...</option>
                                        {categoriesOptions.map(category => (
                                            <option key={category.value} value={category.value}>{category.label}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="edit-field">
                                    <label htmlFor="label">Label</label>
                                    <select
                                        id="label"
                                        value={editedData.label || ""}
                                        onChange={(e) => handleFieldChange("label", e.target.value)}
                                        className="edit-input"
                                    >
                                        <option value="">Aucun Label</option>
                                        {labelsOptions.map(label => (
                                            <option key={label.value} value={label.value}>{label.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="edit-modal-action">
                                <button
                                    className="btn-full"
                                    variant="contained"
                                    color="success"
                                    onClick={handleSave}
                                >
                                    Enregistrer
                                </button>
                                <button
                                    className="btn-border"
                                    variant="outlined"
                                    color="error"
                                    onClick={onClose}
                                >
                                    Annuler
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
