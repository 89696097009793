import apiInstance from "./api";

export const createGroupParticipant = async (groupParticipantData) => {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

    const headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "x-access-token": token,
    };

    try {
        const response = await apiInstance.post(`${process.env.REACT_APP_API_URL}/group-orders-participants/create`, groupParticipantData, {
            headers,
        });
        return response.data;
    } catch (error) {
        console.error("Erreur lors de la création de la commande de groupe", error);
        throw error;
    }
};

export const fetchGroupOrdersParticipants = async (groupOrderId) => {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

    const headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "x-access-token": token,
    };

    try {
        const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/group-orders-participants/list-all/${groupOrderId}`, {
            headers,
        });
        return response.data;
    } catch (error) {
        console.error("Error while fetching all group orders", error);
        throw error;
    }
};

export const fetchParticipantByUserId = async (userId) => {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

    const headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "x-access-token": token,
    };

    try {
        const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/group-orders-participants/get/${userId}`, {
            headers,
        });
        return response.data;
    } catch (error) {
        console.error("Error while fetching all group orders", error);
        throw error;
    }
};

export const deleteGroupOrderParticipantById = async (userId) => {

    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

    const headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "x-access-token": token,
    };

    try {
        const response = await apiInstance.delete(`${process.env.REACT_APP_API_URL}/group-orders-participants/delete-participant/${userId}`, {
            headers,
        });
        return response.data;
    } catch (error) {
        console.error("Error while deleting group order participant", error);
        throw error;
    }
};
