import apiInstance from "./api";

/**
 * Add recipes to a specific menu
 *
 * @param {Object} menuRecipesData - The data for the menu.
 * @returns {Object} - The response data from the API.
 * @throws {Error} - If an error occurs during the creation of the menu.
 */
export const addRecipesToMenu = async (menuRecipesData) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.post(`${process.env.REACT_APP_API_URL}/menu-recipes/create`, menuRecipesData, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("c", error);
    throw error;
  }
};


/**
 * Retrieves recipes by menu ID from the API.
 *
 * @param {string} menuId - The ID of the menu to retrieve recipes for.
 * @returns {Object} - The retrieved recipe data for the menu.
 * @throws {Error} - If an error occurs during the retrieval of the recipes.
 */
export const getRecipesByMenuId = async (id) => {

  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/menu-recipes/list/${id}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des recettes du menu:", error);
    throw error;
  }
};

export const updateRecipePortionByMenuId = async (menuId, recipeId, updatedPortion) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.put(`${process.env.REACT_APP_API_URL}/menu-recipes/update/${menuId}/${recipeId}`, updatedPortion, { headers });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour des portions:", error);
    throw error;
  }
};



/**
 * Deletes a recipe from a menu by its ID.
 *
 * @param {string} menuId - The ID of the menu from which the recipe will be deleted.
 * @param {string} recipeId - The ID of the recipe to be deleted from the menu.
 * @returns {Object} - The response data after the deletion of the recipe.
 * @throws {Error} - If an error occurs during the deletion of the recipe from the menu.
 */
export const deleteRecipesMenuById = async (menuId, recipeId) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;


  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.delete(
      `${process.env.REACT_APP_API_URL}/menu-recipes/delete/${menuId}/${recipeId}`,
      { headers }
    );

    return response.data;
  } catch (error) {
    console.error("Error while deleting the recipe from the menu", error);
    throw error;
  }
};



