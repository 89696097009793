import React, { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import './popupEdit.css';


export default function EditPopup({ open, onClose, data, onSave, fields, title }) {

    const [editedData, setEditedData] = useState({});


    useEffect(() => {
        if (data) {
            setEditedData(data);
        }
    }, [data]);

    const handleFieldChange = (field, value) => {
        setEditedData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleSave = () => {
        onSave(editedData);
        onClose();
    };

    return (
        <>

            {open && (
                <>
                    <div className="overlay" onClick={onClose}></div>
                    <div className="edit-modal">
                        <div className="edit-modal-title">
                            <h2>{title}</h2>
                        </div>
                        <div className="edit-modal-form">
                            <div className="edit-fields">
                                {fields.map((field) => (
                                    <div key={field.name} className="edit-field">
                                        <label htmlFor={field.name}>{field.label}</label>
                                        <input
                                            id={field.name}
                                            type="text"
                                            value={editedData[field.name] || ""}
                                            onChange={(e) => handleFieldChange(field.name, e.target.value)}
                                            className="edit-input"
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="edit-modal-action">
                                <button
                                    className="btn-full"
                                    variant="contained"
                                    color="success"
                                    onClick={handleSave}
                                >
                                    Enregistrer
                                </button>
                                <button
                                    className="btn-border"
                                    variant="outlined"
                                    color="error"
                                    onClick={onClose}
                                >
                                    Annuler
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
