import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { verifyEmail } from '../../api/authAPI';
import { resetPasswordMail } from '../../api/userAPI';
import toast from 'react-hot-toast';
import Button from '../../components/elements/button/Button';

import './Reset.css';

function MailResetPassword() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Valider le format de l'adresse e-mail
        if (!emailRegex.test(email)) {
            setIsEmailValid(false);
            return;
        }

        try {
            const emailExists = await verifyEmail(email);

            if (emailExists) {
                await resetPasswordMail(email);

                toast.success("Consultez vos mails pour réinitialiser le mot de passe.");
            } else {
                toast.error("Votre compte n'existe pas, inscrivez-vous");
                navigate('/signup');
            }
        } catch (error) {
            toast.error("Erreur lors de la réinitialisation du mot de passe");
            console.error("Erreur lors de la réinitialisation ou vérification de l'email:", error);
        }
    };

    return (
        <div className="reset-password-container">
            <form className="reset-password-mail" onSubmit={handleSubmit}>
                <h2 className="reset-password-title flex-center">Mot de passe oublié</h2>
                <input
                    type="email"
                    placeholder="Entrez votre email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={`reset-password-input ${!isEmailValid ? 'invalid' : ''}`}
                    required
                />
                {!isEmailValid && (
                    <p className="error-message">Veuillez saisir une adresse e-mail valide.</p>
                )}
                <div className="reset-password-action flex-center">
                    <Button className="btn-full" type="submit" text="Réinitialiser le mot de passe" />
                </div>
            </form>
        </div>
    );
}

export default MailResetPassword;
