import { useState, useEffect } from "react";

// Icons
import { GoPlus } from "react-icons/go";

// Toast
import toast from 'react-hot-toast';

// API
import { updateRecipeImage } from "../../../api/recipesAPI";

function RecipeImage({ imageUrl, recipeId, onImageUpdated, recipe }) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageName, setSelectedImageName] = useState("");
    const [currentUser, setCurrentUser] = useState(null);

    // Vérification de l'utilisateur actuel (pour permettre l'édition si propriétaire)
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.id) {
            setCurrentUser(user.id);
        }
    }, []);

    // Log pour vérifier que l'image URL est correctement passée
    useEffect(() => {
        console.log("Image URL dans RecipeImage:", imageUrl);
    }, [imageUrl]);

    // Gérer la sélection d'image
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
        setSelectedImageName(file ? file.name : "");
        console.log("Image sélectionnée:", file);
    };

    // Gérer la mise à jour de l'image via l'API
    const handleImageUpdate = async () => {
        try {
            if (!selectedImage) {
                console.error("Veuillez sélectionner une image");
                return;
            }

            // Appel à l'API pour mettre à jour l'image
            await updateRecipeImage(recipeId, selectedImage);
            console.log("Image mise à jour pour la recette:", recipeId);

            // Si `onImageUpdated` est passé en tant que prop, on force la mise à jour de l'image dans le parent
            if (onImageUpdated) {
                onImageUpdated();
            }

            toast.success('Photo modifiée avec succès');
        } catch (error) {
            console.error("Erreur lors de la mise à jour de l'image:", error);
        }
    };

    // Vérifier si l'utilisateur est le propriétaire de la recette
    const isOwner = currentUser === recipe.user_id;
    console.log("Est propriétaire:", isOwner, "ID utilisateur actuel:", currentUser, "ID utilisateur de la recette:", recipe.user_id);

    return (
        <>
            {isOwner && (
                <div className="recipes-details-img-actions">
                    <label htmlFor="imageInput" className="btn-add">
                        <GoPlus style={{ width: 15, color: "green" }} /> Modifier la photo
                    </label>
                    <input
                        id="imageInput"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                    />
                    {selectedImageName && (
                        <>
                            <div>
                                <span style={{ fontWeight: "bold" }}>Image sélectionnée :</span> {selectedImageName}
                            </div>
                            <button style={{ marginLeft: "1rem" }} className="btn-update btn-add" onClick={handleImageUpdate}>
                                Valider
                            </button>
                        </>
                    )}
                </div>
            )}
            <div className="flex-center">
                <div className="recipes-details-img-container">
                    {imageUrl ? (
                        <img src={imageUrl} alt="recipes-details" className="recipes-details-img" />
                    ) : (
                        <p>Aucune image disponible</p>
                    )}
                </div>
            </div>
        </>
    );
}

export default RecipeImage;
