import apiInstance from "./api";

export const linkProductToAllergy = async (productAllergyData) => {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
  
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "x-access-token": token,
    };
  
    try {
      const response = await apiInstance.post(`${process.env.REACT_APP_API_URL}/product-allergy/create`, productAllergyData, {
        headers,
      });

      return response.data;
    } catch (error) {
      console.error("Error while linking product to Allergy", error);
      throw error;
    }
  };