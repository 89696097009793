import React, { useState, useEffect } from "react";
import {
    fetchCombinedProductInfoById,
    updateProductById,
    updateRecipeProductInfoById,
    deleteRecipeProductInfoById
} from "../../../api/stockAPI";
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {
    fetchRecipeById
} from "../../../api/recipesAPI";
import { createNutriFacts, fetchNutriFacts, updateNutriFacts } from "../../../api/nutriFactsAPI";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    TextField,
    IconButton,
} from "@mui/material";
import Typography from '@mui/joy/Typography';
import { TiDeleteOutline } from 'react-icons/ti';
import { CiEdit } from 'react-icons/ci';
import { FaCheck } from 'react-icons/fa6';
import { GoPlus } from "react-icons/go";
import PopupConfirm from "../../elements/popup/popup-confirm/PopupConfirm";
import PopupChooseProduct from "../../elements/popup/popup-product/PopupChooseProduct";

function RecipeProducts({ recipeId }) {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingProductId, setEditingProductId] = useState(null);
    const [editedFields, setEditedFields] = useState({});
    const [recipeProductInfo, setRecipeProductInfo] = useState({});
    const [recipeDetails, setRecipeDetails] = useState(null);
    const [productData, setProductData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);
    const [updatingNutriFacts, setUpdatingNutriFacts] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    const subscriptionInfos = useSelector((state) => state.subscription.subscriptionInfos);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const recipeData = await fetchRecipeById(recipeId);
                setRecipeDetails(recipeData);
                const productData = await fetchCombinedProductInfoById(recipeId);
                setProducts(productData.productInfos);
                setProductData(productData);
                setRecipeProductInfo(
                    productData.recipeProductInfo.reduce((acc, info) => {
                        acc[info.productId] = info;
                        return acc;
                    }, {})
                );
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [recipeId]);

    useEffect(() => {
        if (recipeDetails && !loading && !error && productData) {
            setDataFetched(true);
        }
    }, [recipeDetails, loading, error, productData]);

    useEffect(() => {
        if (dataFetched && Object.keys(recipeProductInfo).length > 0) {
            const checkAndGenerateNutriFacts = async () => {
                try {
                    const existingNutriFacts = await fetchNutriFacts(recipeId);
                    if (!existingNutriFacts) {
                        const recipeData = {
                            recipe_id: recipeId,
                            recipe_data: {
                                title: recipeDetails.name,
                                ingr: products.map((productInfo) =>
                                    `${recipeProductInfo[productInfo?.id]?.unitQty} ${productInfo?.unitType} ${productInfo?.name}`
                                ),
                                yield: `${recipeDetails.portions}`,
                                time: `${recipeDetails.prep_time} min`,
                            }
                        };
                        await createNutriFacts(recipeData);
                    } else {
                        console.log("Nutritional Facts already exist for this recipe");
                    }
                } catch (error) {
                    console.error("Error checking or generating NutriFacts:", error);
                }
            };
            checkAndGenerateNutriFacts();
        }
    }, [dataFetched, products, recipeDetails, recipeId, recipeProductInfo]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.id) {
            setCurrentUser(user.id);
        }
    }, []);

    const handleProductEdit = (productId, initialFields) => {
        setEditingProductId(productId);
        setEditedFields((prevFields) => ({
            ...prevFields,
            [productId]: { ...initialFields },
        }));
    };

    const handleSaveEdit = async (productId) => {
        try {
            const updatedFields = { ...editedFields[productId] };
            const unitQtyChanged = updatedFields.unitQty !== recipeProductInfo[productId]?.unitQty;
            if (unitQtyChanged && !isNaN(updatedFields.unitQty)) {
                await updateRecipeProductInfoById(productId, {
                    unitQty: updatedFields.unitQty,
                    recipeId: recipeId,
                });
            }
            await updateProductById(productId, {
                name: updatedFields.name,
                unitType: updatedFields.unitType,
            });
            setProducts((prevProducts) =>
                prevProducts.map((product) =>
                    product.id === productId ? { ...product, ...updatedFields } : product
                )
            );
            setRecipeProductInfo((prevInfo) => ({
                ...prevInfo,
                [productId]: { ...prevInfo[productId], ...updatedFields },
            }));
            setEditingProductId(null);
        } catch (error) {
            console.error("Erreur lors de la sauvegarde du produit :", error);
        }
    };

    const handleDeleteProduct = async (productId) => {
        try {
            await deleteRecipeProductInfoById(productId, recipeId);
            const productData = await fetchCombinedProductInfoById(recipeId);
            setProducts(productData.productInfos);
            setRecipeProductInfo(
                productData.recipeProductInfo.reduce((acc, info) => {
                    acc[info.productId] = info;
                    return acc;
                }, {})
            );
        } catch (error) {
            console.error("Error deleting recipe product info:", error);
        }
    };

    const handleUpdateNutriFacts = async () => {
        try {
            const recipeData = {
                title: recipeDetails.name,
                ingr: products.map((productInfo) =>
                    `${recipeProductInfo[productInfo?.id]?.unitQty} ${productInfo?.unitType} ${productInfo?.name}`
                ),
                yield: `${recipeDetails.portions}`,
                time: `${recipeDetails.prep_time} min`,
            };
            await updateNutriFacts(recipeData, recipeId);
            toast.success('Valeurs nutritionnelles créées avec succès');
        } catch (error) {
            toast.error("Les valeurs nutritionnelles n'ont pas été mise à jour");
            console.error("Error updating NutriFacts:", error);
        }
    };

    const isProductEditing = (productId) => {
        return productId === editingProductId;
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const handleChooseClick = () => {
        setShowPopup(true);
    };

    const isOwner = currentUser === recipeDetails?.user_id;

    return (
        <>
            <h2 className="flex-center recipes-products-title" style={{ marginBottom: 1 }}>
                Produits associés à la recette
            </h2>
            <Typography level="body-xs" sx={{ textAlign: "center", color: "b3b2b2", fontStyle: "italic" }}>Par défaut, les ingrédients sont donnés pour {recipeDetails.portions} portions</Typography>
            {isOwner && (
                <div className="recipes-products-create">
                    <PopupChooseProduct
                        trigger={
                            <button className="btn-add" onClick={handleChooseClick}>
                                <GoPlus style={{ width: 15, color: "green" }} /> Ajouter un produit
                            </button>
                        }
                        onSelect={async (selectedProducts) => {
                            const productData = await fetchCombinedProductInfoById(recipeId);
                            setProducts(productData.productInfos);
                            setRecipeProductInfo(
                                productData.recipeProductInfo.reduce((acc, info) => {
                                    acc[info.productId] = info;
                                    return acc;
                                }, {})
                            );
                            setShowPopup(false);
                        }}
                        recipeId={recipeId}
                    />
                </div>
            )}
            <div className="recipes-products-details">
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nom</TableCell>
                                <TableCell>Qté</TableCell>
                                <TableCell>Unité</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.map((productInfo) => (
                                <TableRow key={productInfo.id}>
                                    <TableCell>
                                        {isProductEditing(productInfo.id) ? (
                                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    className="product-name-field"
                                                    label="Nom du produit"
                                                    variant="standard"
                                                    color="success"
                                                    type="text"
                                                    size="small"
                                                    sx={{ width: 60 }}
                                                    value={editedFields[productInfo?.id]?.name || ""}
                                                    onChange={(e) =>
                                                        setEditedFields((prevFields) => ({
                                                            ...prevFields,
                                                            [productInfo?.id]: {
                                                                ...prevFields[productInfo?.id],
                                                                name: e.target.value,
                                                            },
                                                        }))
                                                    }
                                                    disabled
                                                />
                                            </FormControl>
                                        ) : (
                                            productInfo?.name
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {isProductEditing(productInfo?.id) ? (
                                            <FormControl sx={{ mr: 1 }} variant="filled">
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    className="product-unitqty-field"
                                                    variant="standard"
                                                    label="Quantity"
                                                    color="success"
                                                    type="number"
                                                    size="small"
                                                    sx={{ width: 50 }}
                                                    defaultValue={parseFloat(recipeProductInfo[productInfo?.id]?.unitQty)}
                                                    onChange={(e) =>
                                                        setEditedFields((prevFields) => ({
                                                            ...prevFields,
                                                            [productInfo?.id]: {
                                                                ...prevFields[productInfo?.id],
                                                                unitQty: parseFloat(e.target.value),
                                                            },
                                                        }))
                                                    }
                                                />
                                            </FormControl>
                                        ) : (
                                            recipeProductInfo[productInfo?.id]?.unitQty
                                        )}
                                    </TableCell>
                                    <TableCell>{productInfo.unitType}</TableCell>
                                    {isOwner && (
                                        <>
                                            <TableCell>
                                                {isProductEditing(productInfo.id) ? (
                                                    <IconButton onClick={() => handleSaveEdit(productInfo.id)}>
                                                        <FaCheck size={15} color="green" />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        onClick={() => handleProductEdit(productInfo.id, { ...productInfo })}
                                                    >
                                                        <CiEdit size={15} />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <PopupConfirm
                                                    trigger={
                                                        <IconButton style={{ fontSize: '16 !important' }}>
                                                            <TiDeleteOutline style={{ width: 15, color: "red" }} />
                                                        </IconButton>
                                                    }
                                                    onConfirm={() => handleDeleteProduct(productInfo.id)}
                                                    message={`Êtes-vous sûr de vouloir supprimer la recette ${productInfo.name} ?`}
                                                />
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {isOwner && (
                <div className="recipes-products-update-nutri-facts">
                    <button className="btn-full" onClick={handleUpdateNutriFacts}>
                        Mettre à jour les valeurs nutritionnelles
                    </button>
                </div>
            )}
        </>
    );
}

export default RecipeProducts;
