import apiInstance from "./api";
import { getApiHeaders } from "./apiHeaders";

export const createNutriFacts = async (recipeData) => {
  
  try {
    const response = await apiInstance.post(`${process.env.REACT_APP_API_URL}/nutrition/create`, 
    recipeData,
      { headers: getApiHeaders() },
    );
    return response.data;
    
  } catch (error) {
    console.error(
      "Erreur lors de la création des valeurs nutritionnelles de la recette",
      error
    );
    throw error;
  }
};

export const updateNutriFacts = async (recipeData, recipeId) => {
  
  try {
    const response = await apiInstance.put(`${process.env.REACT_APP_API_URL}/nutrition/update/${recipeId}`, 
    recipeData,
      { headers: getApiHeaders() },
    );
    return response.data;
    
  } catch (error) {
    console.error(
      "Erreur lors de la création des valeurs nutritionnelles de la recette",
      error
    );
    throw error;
  }
};

export const fetchNutriFacts = async (recipeId) => {
  
    try {
      const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/nutrition/get/${recipeId}`
      );
      return response.data;
      
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des valeurs nutritionnelles de la recette",
        error
      );
      throw error;
    }
};