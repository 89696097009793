import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

// MUI
import { Button } from "@mui/material";

// Styles
import './RecipesInfos.css';

// API
import { fetchAllRecipePictures, getAllRecipes } from "../../../../api/recipesAPI";
import { fetchNutriFacts } from '../../../../api/nutriFactsAPI';

// Components
import SharedRecipesDetails from '../../../../components/admin-dashboard/recipes/shared-recipes/SharedRecipesDetails';
import SharedRecipeProducts from '../../../../components/admin-dashboard/recipes/shared-recipes/SharedRecipeProducts';
import NutritionFacts from '../../../../components/admin-dashboard/recipes/NutritionFacts';


function SharedRecipe() {
    const [pictures, setPictures] = useState([]);
    const [nutriFacts, setNutriFacts] = useState([]);

    const { id } = useParams();

    useEffect(() => {
        const fetchNutriInfos = async () => {
            try {
                const data = await fetchNutriFacts(id);
                setNutriFacts(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchNutriInfos();
    }, [id]);

    useEffect(() => {
        const getPictures = async () => {
            try {
                const pics = await fetchAllRecipePictures();
                setPictures(pics);


            } catch (error) {
                console.error("Erreur lors de la récupération des images des recettes:", error);
            }
        };

        getPictures();

    }, []);



    return (
        <>
            <div className="recipes-infos">
                <SharedRecipesDetails recipeId={id} pictures={pictures} nutriFacts={nutriFacts} />
            </div>
            <div className="recipes-infos-products">
                <SharedRecipeProducts recipeId={id} />
            </div>
            <div className="recipes-infos-nutri-facts">
                <NutritionFacts recipeId={id} />
            </div>

        </>
    );
}

export default SharedRecipe;
