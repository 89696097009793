import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Badge, styled, Accordion, AccordionSummary, AccordionDetails, Typography, IconButton, Button } from "@mui/material";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PopupConfirm from '../../elements/popup/popup-confirm/PopupConfirm';
import { fetchAllOrders } from "../../../api/userAPI";
import { deleteOrderById, updateOrderById } from "../../../api/ordersAPI";
import toast from 'react-hot-toast';
import { TiDeleteOutline, TiTickOutline } from "react-icons/ti";
import useMediaQuery from '@mui/material/useMediaQuery';
import EditPopup from "../../elements/popup/popup-edit/popupEdit";
import InfoIcon from '@mui/icons-material/Info';
import PopupInfos from "../../elements/popup/popup-infos/PopupInfos";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    marginBottom: "0rem",
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    backgroundColor: "transparent",
    color: theme.palette.common.black,
    fontWeight: "bold",
    cursor: "pointer",
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
}));

const OrdersTable = () => {
    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");

    const [orders, setOrders] = useState([]);
    const [sortedOrders, setSortedOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'descending' });
    const [searchTerm, setSearchTerm] = useState("");
    const [openEditPopup, setOpenEditPopup] = useState(false);
    const [openInfosPopup, setOpenInfosPopup] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const ordersPerPage = isTablet ? 15 : 7;

    const orderFields = [
        { name: 'order_num', label: 'Numéro de commande' },
        { name: 'status', label: 'Statut' },
        { name: 'payment_status', label: 'Statut de paiement' },
        { name: 'userId', label: 'User Id' },
    ];

    useEffect(() => {
        const getAllOrders = async () => {
            try {
                const fetchedOrders = await fetchAllOrders();
                const sortedFetchedOrders = fetchedOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setOrders(sortedFetchedOrders);
                setSortedOrders(sortedFetchedOrders);
            } catch (error) {
                console.error("Erreur lors de la récupération des commandes:", error);
            }
        };

        getAllOrders();
    }, []);

    useEffect(() => {
        const ordersToSort = [...orders];
        ordersToSort.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setSortedOrders(ordersToSort);
    }, [sortConfig, orders]);

    const handleSort = useCallback(key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    }, [sortConfig]);

    const handleNextPage = useCallback(() => {
        if (currentPage < Math.ceil(orders.length / ordersPerPage)) {
            setCurrentPage(prev => prev + 1);
        }
    }, [currentPage, orders, ordersPerPage]);

    const handlePreviousPage = useCallback(() => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    }, [currentPage]);

    const handleEditClick = (order) => {
        setSelectedOrder(order);
        setOpenEditPopup(true);
    };

    const handleSaveOrder = async (updatedOrderData) => {
        try {
            await updateOrderById(updatedOrderData.id, updatedOrderData);
            setOpenEditPopup(false);
            toast.success('Commande mise à jour avec succès');

            const updatedOrders = orders.map(order =>
                order.id === updatedOrderData.id ? updatedOrderData : order
            ).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setOrders(updatedOrders);
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la commande", error);
            toast.error('Erreur lors de la mise à jour de la commande');
        }
    };

    const handleInfosClick = (order) => {
        setSelectedOrder(order);
        setOpenInfosPopup(true);
    };

    const handleConfirmDelete = useCallback(async (orderId) => {
        try {
            await deleteOrderById(orderId);
            toast.success('Commande supprimée avec succès');
            const updatedOrders = orders.filter(order => order.id !== orderId)
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setOrders(updatedOrders);
        } catch (error) {
            toast.error('Erreur lors de la suppression de la commande');
        }
        fetchAllOrders();
    }, [orders]);

    const filteredOrders = useMemo(() => sortedOrders.filter(order =>
        (order.id?.toString() || "").includes(searchTerm) ||
        (order.order_num?.toString() || "").includes(searchTerm) ||
        order.status?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (order.payment_status ? "payé" : "échec").toLowerCase().includes(searchTerm.toLowerCase()) ||
        (order.userId?.toString() || "").includes(searchTerm) ||
        new Date(order.createdAt).toLocaleDateString('fr-FR').includes(searchTerm)
    ), [sortedOrders, searchTerm]);

    return (
        <div className="orders-table">
            <div className="orders-table-title flex-center">
                <h1 style={{ margin: "1rem 0" }}>Liste des commandes</h1>
            </div>
            <div className="flex-center" style={{ "display": "flex", "marginBottom": "3rem" }}>
                <input
                    type="text"
                    placeholder="Rechercher..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    style={{ "width": 300, "padding": ".85rem", "marginRight": "-1rem", borderTop: "none", borderRight: "none", borderLeft: "none", backgroundColor: "transparent", outline: "none" }}
                />
            </div>
            {filteredOrders.slice((currentPage - 1) * ordersPerPage, currentPage * ordersPerPage).map(order => (
                <StyledAccordion key={order.id}>
                    <StyledAccordionSummary>
                        <IconButton size="small" style={{ color: 'rgb(50, 89, 62)', fontSize: 16 }}>
                            <Typography level="body-ms" color="neutral">
                                {order.order_num}
                            </Typography>
                        </IconButton>
                        <IconButton size="small" style={{ color: 'rgb(50, 89, 62)', fontSize: 16 }}>
                            <Typography level="body-ms" color="neutral">
                                le {new Date(order.createdAt).toLocaleDateString('fr-FR')}
                            </Typography>
                            {sortConfig.key === 'order_num' && (sortConfig.direction === 'ascending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
                        </IconButton>
                        <IconButton style={{ marginLeft: "2rem" }}>{order.payment_status ? (
                            <Badge color="success" badgeContent="Payé"></Badge>
                        ) : (
                            <Badge color="error" badgeContent="Echec"></Badge>
                        )}</IconButton>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                        <Typography variant="outlined" color="neutral">Id commande: {order.id}</Typography>
                        <Typography variant="outlined" color="neutral">Id du client: {order.userId}</Typography>
                        <Typography variant="outlined" color="neutral">Prix: {order.price + " €"}</Typography>
                        <Typography variant="outlined" color="neutral">Numéro de commande: {order.order_num}</Typography>
                        <Typography variant="outlined" color="neutral">Statut de commande: {order.status}</Typography>

                        <Typography variant="outlined" color="neutral"> Statut du paiement:
                            <IconButton style={{ marginLeft: "2rem" }}>{order.payment_status ? (
                                <Badge color="success" badgeContent="Payé"></Badge>
                            ) : (
                                <Badge color="error" badgeContent="Echec"></Badge>
                            )}</IconButton>
                        </Typography>
                        <Typography variant="outlined" color="neutral">Pre Order:
                            <IconButton style={{ marginLeft: "2rem" }}>{order.preorder ? (
                                <TiTickOutline size={15} style={{ "color": "green" }} />
                            ) : (
                                <TiDeleteOutline size={15} style={{ "color": "red" }} />
                            )}</IconButton>
                        </Typography>
                        <Typography variant="outlined" color="neutral" onClick={() => handleInfosClick(order)} sx={{ fontWeight: "500" }}>Voir les items de commandes</Typography>
                        <PopupInfos
                            open={openInfosPopup}
                            onClose={() => setOpenInfosPopup(false)}
                            data={selectedOrder}
                            fields={orderFields}
                            title="Informations de commande"
                        />
                        <Typography style={{ display: "flex", alignItems: "center", marginTop: "1rem", justifyContent: "space-between" }}>
                            <IconButton style={{ fontSize: 14 }} onClick={() => handleEditClick(order)}>Modifier la commande</IconButton>
                            <EditPopup
                                open={openEditPopup}
                                onClose={() => setOpenEditPopup(false)}
                                data={selectedOrder}
                                onSave={handleSaveOrder}
                                fields={orderFields}
                                title="Modifier la commande"
                            />
                            <PopupConfirm
                                trigger={<IconButton style={{ fontSize: 14, color: "red" }}>Supprimer la commande</IconButton>}
                                onConfirm={() => handleConfirmDelete(order.id)}
                                message={`Êtes-vous sûr de vouloir supprimer la commande numéro ${order.order_num} ?`}
                            />
                        </Typography>
                    </StyledAccordionDetails>
                </StyledAccordion>
            ))}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", marginTop: "3rem" }}>
                <Button style={{ border: "green 1px solid", color: "green" }} disabled={currentPage === 1} onClick={handlePreviousPage}>
                    <ArrowBackIosNewIcon />
                </Button>
                <span>Page {currentPage}</span>
                <Button style={{ border: "green 1px solid", color: "green" }} disabled={currentPage === Math.ceil(orders.length / ordersPerPage)} onClick={handleNextPage}>
                    <ArrowForwardIosIcon />
                </Button>
            </div>
        </div>
    );
}

export default OrdersTable;
