import { useState, useEffect, useMemo } from "react";

//Components
import SharedRecipeHeader from "./SharedRecipeHeader";
import SharedRecipeImage from "./SharedRecipeImage";
import RecipeNameForm from "../RecipeNameForm";
import SharedRecipeDetailsContainer from "./SharedRecipeDetailsContainer";

// API
import { fetchRecipeById, updateRecipeById, updateRecipeImage } from "../../../../api/recipesAPI";


const partIdLabels = {
    1: "1 — Entrées",
    2: "2 — Plats",
    3: "3 — Desserts",
    4: "4 — Boissons",
};

function SharedRecipesDetails({ recipeId, pictures, nutriFacts }) {

    //Local States
    const [recipe, setRecipe] = useState(null);
    const [imageKey, setImageKey] = useState(0);

    const [editedFields, setEditedFields] = useState({
        name: "",
        price: "",
        barcode: "",
        partId: "",
        visibility: false,
        description: "",
    });


    // Get the recipe image URL
    const imageUrl = useMemo(() => {
        // Filter images by recipe ID
        const filteredPictures = pictures.filter(pic => pic.recipe_id == recipeId);
        // Check if there are filtered images
        if (filteredPictures.length > 0) {
            // Return the full URL of the first filtered image
            return `${process.env.REACT_APP_API_URL}${filteredPictures[0].path}`;
        }
        return undefined;
    }, [recipeId, pictures]);


    // Fetch recipe data from API when the component mounts
    useEffect(() => {

        const getRecipeData = async () => {
            try {
                const recipeData = await fetchRecipeById(recipeId);
                setRecipe(recipeData);


            } catch (error) {
                console.error("Erreur lors de la récupération de la recette :", error);
            }
        };

        getRecipeData();
    }, [recipeId]);


    if (!recipe) {
        return <div>Loading...</div>;
    }

    return (
        <section className="recipes-details container">
            <SharedRecipeHeader recipe={recipe} nutriFacts={nutriFacts} />

            <SharedRecipeImage key={imageKey} pictures={pictures} imageUrl={imageUrl} />
            <SharedRecipeDetailsContainer
                recipe={recipe}
                partIdLabels={partIdLabels}
            />
        </section >
    );
}

export default SharedRecipesDetails;