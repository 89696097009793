import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

//Redux
import store from "./store";

//Routes
import AppRoutes from "./routes/AppRoutes";

//Components
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import ToasterConfig from "./components/toaster/toasterConfig";

//Style
import "./App.css";

function App() {
  return (
    <>
    <div className="app">
      <Router>
        <Navbar />
        <div className="container">
          <AppRoutes />
          </div>
        <Footer />
      </Router>
      </div>
      <ToasterConfig />
    </>
  );
}

export default App;
