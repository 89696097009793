import React, { useState } from 'react';
import Popup from 'reactjs-popup';

// toast
import toast from 'react-hot-toast';

function PopupAddMenu({ trigger, onCreate }) {
    const userId = parseInt(JSON.parse(localStorage.getItem("user")).id, 10);

    // Local States
    const [formData, setFormData] = useState({
        user_id: userId,
        name: "",
        status: "A prévoir",
        portions: 1,
    });

    const handleSubmit = async (event, close) => {
        event.preventDefault();

        if (!formData.name) {
            toast.error('Veuillez choisir un nom pour continuer');
            return;
        }

        setFormData({ ...formData });

        onCreate(formData);
        close();
    };

    const handleStatusChange = (event) => {
        setFormData({ ...formData, status: event.target.value });
    };

    return (
        <Popup
            trigger={trigger}
            position="right center"
            modal
            contentStyle={{ zIndex: 99999 }}
        >
            {(close) => (
                <div className="popup-confirm-content">
                    <div className="popup-title">
                        <h1>Nouveau Menu</h1>
                    </div>
                    <div className="popup-recipe-form">
                        <form onSubmit={(e) => handleSubmit(e, close)}>
                            <input
                                id="outlined-basic"
                                label="Nom du menu"
                                variant="outlined"
                                type="text"
                                className="popup-recipe-name"
                                placeholder="Nom du menu"
                                color="success"
                                value={formData.name}
                                onChange={(e) =>
                                    setFormData({ ...formData, name: e.target.value })
                                }
                                required
                            />

                            <select
                                defaultValue={"A prevoir"}
                                value={formData.status}
                                onChange={(e) =>
                                    setFormData({ ...formData, status: e.target.value })
                                }
                                style={{ width: 300, padding: '1rem', borderRadius: 5 }}
                            >
                                <option value="A prevoir">A prévoir</option>
                                <option value="Acheté">Acheté</option>
                            </select>


                            <div className="popup-actions flex-center">
                                <button type="submit" className="btn-full btn-sm">
                                    Créer
                                </button>
                                <button className="btn-border btn-sm" onClick={close}>
                                    Annuler
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Popup>
    );
}

export default PopupAddMenu;
