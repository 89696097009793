import React, { useEffect } from 'react';
import './PopupWelcome.css';

const PopupWelcome = ({ message, open, onClose }) => {

    useEffect(() => {
        if (open) {
            console.log('Ouverture du popup de bienvenue');
        }
    }, [open]);
    return (
        <>
            {open && (
                <>
                    <div className="welcome-overlay" onClick={onClose}></div>
                    <div className='popup-welcome'>
                        <div className="popup-welcome-content">
                            <div className="popup-welcome-text">{message}</div>
                            <div className="popup-welcome-actions flex-center">
                                <button className="btn-full btn-sm" onClick={onClose}>
                                    C'est parti
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default PopupWelcome;
