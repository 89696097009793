
//Components
import UsersList from "../../../components/admin-dashboard/user-list/UsersList"



export default function AllUsers() {


    return (
        <div className="user-table">
            <div className="user-table-title flex-center">
                <h1 style={{ margin: "1rem 0" }}>Liste des utilisateurs</h1>
            </div>
            <UsersList />

        </div >
    );
}
