

//Components
import ProductTable from "../../../components/admin-dashboard/stock/ProductTable";

//CSS
import './Stock.css'


const Stock = () => {

    return (
        <div className="products-table">
            <div className="products-table-title flex-center">
                <h1 style={{ margin: "1rem 0" }}>Liste des Produits</h1>
            </div>

            <ProductTable />
        </div>
    );
};

export default Stock;
