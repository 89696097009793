import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutAction } from '../../../features/auth/authSlice';
import { useState, useEffect } from 'react'; // <- Ajout de useState et useEffect

//Styles
import './Dropdown.css';

function Dropdown({ closeDropdown }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //Local States
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("user"));

    useEffect(() => {
        const handleStorageChange = () => {
            setIsLoggedIn(!!localStorage.getItem("user"));
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const handleLinkClick = () => {
        if (closeDropdown) {
            closeDropdown();
        }
    };

    const handleLogout = () => {
        dispatch(logoutAction());
        navigate('/login');
    };

    return (
        <div className="navbar-dropdown">
            {
                isLoggedIn ?
                    (
                        <>
                            <Link to="/account" onClick={handleLinkClick}>Mon compte</Link>
                            <button className="logout-btn" onClick={handleLogout}>Se déconnecter</button>
                        </>
                    ) :
                    (
                        <>
                            <Link to="/login" onClick={handleLinkClick}>Se connecter</Link>
                            <Link to="/signup" onClick={handleLinkClick}>S'inscrire</Link>
                        </>
                    )
            }
        </div>
    );
}

export default Dropdown;
