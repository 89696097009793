import React, { useState, useEffect } from "react";
import {
    styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton,
    InputAdornment, Badge,
} from "@mui/material";
import { Button as Btn } from '@mui/joy';

//Icons
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import InfoIcon from '@mui/icons-material/Info';
import { CiEdit } from 'react-icons/ci';
import { TiDeleteOutline, TiTickOutline } from 'react-icons/ti';
import SearchIcon from '@mui/icons-material/Search';

//API
import { fetchAllOrders, findUserbyId } from "../../../api/userAPI";
import { deleteOrderById, updateOrderById } from "../../../api/ordersAPI";
import { fetchPikizyTokenAndCheckIn } from "../../../api/pikizyAPI";

//Element
import PopupConfirm from '../../elements/popup/popup-confirm/PopupConfirm';
import PopupInfos from "../../elements/popup/popup-infos/PopupInfos";
import EditPopup from "../../elements/popup/popup-edit/popupEdit";

//toast
import toast from 'react-hot-toast';

//Style
import '../../../views/admin-dashboard/stock/Stock.css'


export default function OrdersTable() {

    //Local States
    const [orders, setOrders] = useState([]);
    const [userEmails, setUserEmails] = useState({});
    const [sortedOrders, setSortedOrders] = useState([]);
    const [openEditPopup, setOpenEditPopup] = useState(false);
    const [openInfosPopup, setOpenInfosPopup] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'descending' });
    const [searchTerm, setSearchTerm] = useState("");
    const ordersPerPage = 8;

    // Styles for table cells and rows
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        "&.MuiTableCell-head": {
            backgroundColor: "rgb(50, 89, 62)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            cursor: "pointer",
        },
        "&.MuiTableCell-body": {
            fontSize: 12,
        },
    }));


    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    }));

    const tableStyles = {
        minWidth: "550px",
        width: "850px"
    };


    /**
         * Fetches all orders from the API and sorts them by creation date in descending order.
         * @returns {Promise<Array>} The fetched orders.
         * @throws {Error} If there is an error fetching the orders.
     */
    useEffect(() => {

        const getAllOrders = async () => {
            try {
                const fetchedOrders = await fetchAllOrders();
                setOrders(fetchedOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));

                // Create an object to store user emails
                const emails = {};

                // Retrieve emails for each order
                for (const order of fetchedOrders) {
                    const userId = order.userId;
                    if (userId && !emails[userId]) {
                        try {
                            const userData = await findUserbyId(userId);
                            emails[userId] = userData.email;
                        } catch (error) {
                            console.error(`Error fetching user with ID ${userId}`, error);
                        }
                    }
                }

                // Update local state with user emails
                setUserEmails(emails);

            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        };


        getAllOrders();
    }, []);


    useEffect(() => {
        const ordersToSort = [...orders];
        ordersToSort.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setSortedOrders(ordersToSort);
    }, [sortConfig, orders]);

    // Filter orders based on the search term
    const filteredOrders = sortedOrders.filter(order =>
        (order.id?.toString() || "").includes(searchTerm) ||
        (order.order_num?.toString() || "").includes(searchTerm) ||
        (order.type?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
        (order.status?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
        (order.payment_status ? "payé" : "échec").toLowerCase().includes(searchTerm.toLowerCase()) ||
        (order.payment_status ? "paye" : "echec").toLowerCase().includes(searchTerm.toLowerCase()) ||
        (order.userId?.toString() || "").includes(searchTerm)
    );


    // Handles sorting of orders based on the sort key
    const handleSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Handles pagination for next page
    const handleNextPage = () => {
        if (currentPage < Math.ceil(orders.length / ordersPerPage)) {
            setCurrentPage(prev => prev + 1);
        }
    };

    // Handles pagination for previous page
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    //Update order in DB
    const handleEditClick = (order) => {
        setSelectedOrder(order);
        setOpenEditPopup(true);
    };

    const handleInfosClick = (order) => {
        setSelectedOrder(order);
        setOpenInfosPopup(true);

    };



    // Fields for order data
    const orderFields = [
        { name: 'order_num', label: 'Numéro de commande' },
        { name: 'status', label: 'Statut' },
        { name: 'payment_status', label: 'Statut de paiement' },
        { name: 'userId', label: 'User Id' },
    ];

    // Save Order updates in DB
    const handleSaveOrder = async (updatedOrderData) => {

        try {

            await updateOrderById(updatedOrderData.id, updatedOrderData);
            setOpenEditPopup(false);
            toast.success('Commande mise à jour avec succès');


            const updatedOrders = orders.map(order =>
                order.id === updatedOrderData.id ? updatedOrderData : order
            );
            setOrders(updatedOrders);
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la commande", error);
            toast.error('Erreur lors de la mise à jour de la commande');
        }
    };




    //Delete order from DB
    const handleConfirmDelete = async (orderId) => {
        try {

            await deleteOrderById(orderId);
            toast.success('Commande supprimée avec succès');
            const updatedOrders = orders.filter(order => order.id !== orderId);
            setOrders(updatedOrders);

        } catch (error) {
            toast.error('Erreur lors de la suppression de la commande');
        }
        fetchAllOrders();

    }

    const handleOpenFridge = async (orderId) => {
        try {

            await fetchPikizyTokenAndCheckIn(orderId);
            toast.success(`Le frigo a bien été ouvert pour la commande ${orderId}`)

        } catch (error) {

            toast.error(`Erreur lors de l'ouverture du frigo`)

        }
    }

    return (
        <div className="orders-table">

            {/*----------------------------------------- Search Bar --------------------------------------------*/}

            <div className="flex-center" style={{ "display": "flex", "marginBottom": "1rem" }}>
                <input
                    placeholder="Rechercher"
                    variant="outlined"
                    className="search-bar"
                    color="success"
                    fullWidth
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" >
                                <SearchIcon style={{ "fontSize": "15px" }} />
                            </InputAdornment>

                        ),
                    }}
                    style={{ margin: '10px', width: '99%', backgroundColor: "transparent" }}
                />

            </div>
            <TableContainer component={Paper} >
                <Table style={tableStyles} aria-label="orders table">


                    {/*----------------------------------------- Colums --------------------------------------------*/}

                    <TableHead>
                        <TableRow>
                            {[
                                { key: 'id', label: 'ID' },
                                { key: 'order_num', label: 'Numéro de commande' },
                                { key: 'type', label: 'Type' },
                                { key: 'price', label: 'Montant' },
                                { key: 'status', label: 'Statut' },
                                { key: 'payment_status', label: 'Paiement' },
                                { key: 'preorder', label: 'Preorder' },
                                { key: 'email', label: 'Mail client' },
                                { key: 'createdAt', label: 'Date de création' },
                                // { key: 'open', label: '' },
                                { key: 'edit', label: '' },
                                { key: 'delete', label: '' },
                            ].map(column => (
                                <StyledTableCell key={column.key} style={column.key === 'id' ? { width: '20px !important' } : {}}>
                                    <IconButton size="small" onClick={() => handleSort(column.key)} style={{ color: 'white', fontSize: 13 }}>
                                        {column.label}
                                        {sortConfig.key === column.key && (sortConfig.direction === 'ascending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
                                    </IconButton>
                                </StyledTableCell>

                            ))}
                        </TableRow>
                    </TableHead>


                    {/*----------------------------------------- Rows --------------------------------------------*/}


                    <TableBody >
                        {filteredOrders.slice((currentPage - 1) * ordersPerPage, currentPage * ordersPerPage).map(order => (
                            <StyledTableRow key={order.id}>
                                <TableCell style={{ fontSize: 13, width: "20px !important" }}>{order.id}</TableCell>
                                <TableCell style={{ fontSize: 13 }}>
                                    <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                        <InfoIcon style={{ fontSize: 15, color: "#B5B5B5", cursor: "pointer" }} onClick={() => handleInfosClick(order)} />
                                        {" " + order.order_num}
                                        <PopupInfos
                                            open={openInfosPopup}
                                            onClose={() => setOpenInfosPopup(false)}
                                            data={selectedOrder}
                                            fields={orderFields}
                                            title="Informations de commande"
                                        />
                                    </div>
                                </TableCell>
                                <TableCell style={{ fontSize: 13 }}>{order.type}</TableCell>
                                <TableCell style={{ fontSize: 13 }}>{order.price + " €"}</TableCell>
                                <TableCell style={{ fontSize: 13 }}>{order.status}</TableCell>
                                <TableCell style={{ fontSize: 13, textAlign: "center" }}>{order.payment_status ? <Badge badgeContent={"Payé"} color="success" /> : <Badge badgeContent={"Echec"} color="error" />}</TableCell>
                                <TableCell style={{ fontSize: 13, textAlign: "center" }}>{order.preorder ? <TiTickOutline size={15} style={{ "color": "green" }} /> : <TiDeleteOutline size={15} style={{ "color": "red" }} />}</TableCell>

                                <TableCell style={{ fontSize: 13 }}>{userEmails[order.userId]}</TableCell>
                                <TableCell style={{ fontSize: 13 }}>
                                    {new Date(order.createdAt).toLocaleString('fr-FR', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}
                                </TableCell>
                                {/* <TableCell style={{ fontSize: 13 }}>
                                    <Btn
                                        color="success"
                                        style={{ fontVariantCaps: "small-caps" }}
                                        variant="plain"
                                        onClick={handleOpenFridge}>
                                        ouvrir
                                    </Btn>
                                </TableCell> */}
                                <TableCell>
                                    <IconButton onClick={() => handleEditClick(order)}>
                                        <CiEdit size={15} />
                                    </IconButton>

                                    <EditPopup
                                        open={openEditPopup}
                                        onClose={() => setOpenEditPopup(false)}
                                        data={selectedOrder}
                                        onSave={handleSaveOrder}
                                        fields={orderFields}
                                        title="Modifier la commande"
                                    />
                                </TableCell>
                                <TableCell>
                                    <PopupConfirm
                                        trigger={
                                            <TiDeleteOutline
                                                size={15}
                                                className='item-delete-icon'
                                                style={{ "color": "red" }}
                                            />
                                        }
                                        onConfirm={() => handleConfirmDelete(order.id)}
                                        message={`Êtes-vous sûr de vouloir supprimer la commande numéro ${order.order_num} ?`}
                                    />

                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >


            {/*----------------------------------------- Pagination --------------------------------------------*/}


            < div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", marginTop: "2rem" }
            }>
                <Button style={{ border: "green 1px solid", color: "green", padding: ".5rem" }} disabled={currentPage === 1} onClick={handlePreviousPage}>
                    <ArrowBackIosNewIcon style={{ "fontSize": "15px" }} />
                </Button>
                <span>Page {currentPage}</span>
                <Button style={{ border: "green 1px solid", color: "green", padding: ".5rem" }} disabled={currentPage === Math.ceil(orders.length / ordersPerPage)} onClick={handleNextPage}>
                    <ArrowForwardIosIcon style={{ "fontSize": "15px" }} />
                </Button>
            </div >
        </div >
    );
}