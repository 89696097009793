import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Checkbox, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import { updateShoppingList } from '../../../api/shoppingListAPI';

function MobileShoppingList({ productsInfos, menuInfos, updateShoppingListProduct }) {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [filters, setFilters] = useState({});
    const [selectedProducts, setSelectedProducts] = useState({});

    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setAnchorEl(null);
    };

    const handleFilterChange = (category) => (event) => {
        setFilters({ ...filters, [category]: event.target.checked });
    };



    useEffect(() => {
        // Initialiser l'état des produits sélectionnés en fonction de la propriété 'tobuy'
        const initialSelectedProducts = {};
        productsInfos.forEach(product => {
            if (product.tobuy === 0) {
                initialSelectedProducts[product.id] = true;
            } else {
                initialSelectedProducts[product.id] = false;
            }
        });
        setSelectedProducts(initialSelectedProducts);
    }, [productsInfos]);

    const filteredCategories = Object.keys(filters).filter(key => filters[key]);

    // Regrouper les produits par catégorie
    const groupedProducts = productsInfos.reduce((acc, product) => {
        if (!acc[product.category]) {
            acc[product.category] = [];
        }
        acc[product.category].push(product);
        return acc;
    }, {});

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', marginBottom: '10px' }}>
                <IconButton onClick={handleFilterClick}>
                    <FilterListIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleFilterClose}
                >
                    {productsInfos.map(product => product.category)

                        .filter((category, index, self) => self.indexOf(category) === index)
                        .map((category, index) => (
                            <MenuItem key={index}>
                                <Checkbox
                                    checked={filters[category] || false}
                                    onChange={handleFilterChange(category)}
                                />
                                <ListItemText primary={category} />
                            </MenuItem>
                        ))}
                </Menu>
            </div>
            {Object.entries(groupedProducts).map(([category, products]) => (
                <div key={category}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>{category}</Typography>
                    <List>
                        {products
                            .filter(product =>
                                (!selectedCategory || product.category === selectedCategory) &&
                                (filteredCategories.length === 0 || filteredCategories.includes(product.category))
                            )
                            .map((product, index) => (
                                <ListItem key={index}>
                                    <ListItemText
                                        primary={product.name}
                                        secondary={
                                            <React.Fragment>
                                                <Typography component="span" variant="body2" color="text.primary">
                                                    {product.unitQty} {product.unitType} - {product.price} €/kg
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <Checkbox
                                            checked={product.tobuy === false}
                                            onChange={() => updateShoppingListProduct(product.id)}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                    </List>
                </div>
            ))}
        </div>
    );
}

export default MobileShoppingList;
