// MUI
import { useState, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem, TextField, Switch, Button } from "@mui/material";
import Textarea from '@mui/joy/Textarea';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Import de DOMPurify
import DOMPurify from 'dompurify';

// Icons
import { CiEdit } from "react-icons/ci";

function RecipesDetailsContainer({
    recipe,
    isEditing,
    editingField,
    editedFields,
    handleEditClick,
    handleSaveClick,
    handleInputChange,
    handleVisibilityChange,
    partIdLabels,
}) {
    const [currentUser, setCurrentUser] = useState(null);
    const [currentUserRole, setCurrentUserRole] = useState(null);
    const [checked, setChecked] = useState(recipe?.visibility || false);

    // Fetch the current user and role from local storage on mount
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user?.id) setCurrentUser(user.id);
        if (user?.roleId) setCurrentUserRole(user.roleId);
    }, []);

    // Determine if the user is an admin
    const isAdmin = currentUserRole === 1;

    // Determine if the user is the owner of the recipe or an admin
    const canEdit = isAdmin || currentUser === recipe?.user_id;

    // Toggle the visibility switch and automatically save to the database
    const handleToggle = () => {
        const newValue = !checked;
        setChecked(newValue);
        handleInputChange("visibility", newValue);
        handleVisibilityChange(newValue);
        handleSaveClick();
    };

    const renderEditField = (label, field, value, type = "text") => (
        <>
            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                {type === "textarea" ? (
                    <Textarea
                        label={`Editer ${label}`}
                        color="success"
                        multiline
                        rows={4}
                        value={value}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                        required
                        sx={{ mb: 1 }}
                    />
                ) : (
                    <TextField
                        label={`Editer ${label}`}
                        color="success"
                        type={type}
                        value={value}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                    />
                )}
            </FormControl>
            <Button onClick={handleSaveClick} variant="contained" color="success" sx={{ mt: 1 }}>
                Save
            </Button>
        </>
    );

    if (!recipe) {
        return <div>La recette n'est pas définie</div>;
    }

    return (
        <div className="recipes-details-text">
            {/* Display Price only for admin users */}
            {isAdmin && (
                <div className="recipes-details-price-container">
                    <div className="recipes-details-price">
                        <div className="recipes-details-price-title">
                            <h2>Prix</h2>
                            {canEdit && <button onClick={() => handleEditClick("price")} className="edit-btn"><CiEdit className="edit-icon" size={15} /></button>}
                        </div>
                        {isEditing && editingField === "price"
                            ? renderEditField("prix", "price", editedFields.price, "number")
                            : <div className="recipes-details-price">{recipe.price} €</div>}
                    </div>
                </div>
            )}
    
            {/* Display Barcode only for admin users */}
            {isAdmin && (
                <div className="recipes-details-barcode">
                    <div className="recipes-details-barcode-title">
                        <h2>Code Barre</h2>
                        {canEdit && <button onClick={() => handleEditClick("barcode")} className="edit-btn"><CiEdit className="edit-icon" size={15} /></button>}
                    </div>
                    {isEditing && editingField === "barcode"
                        ? renderEditField("code-barres", "barcode", editedFields.barcode)
                        : <div className="recipes-details-barcode">{recipe.barcode}</div>}
                </div>
            )}
    
            {/* Recipe Part Section */}
            <div className="recipes-details-partid-container">
                <div className="recipes-details-partid">
                    <div className="recipes-details-partid-title">
                        <h2>Type de recette</h2>
                        {canEdit && <button onClick={() => handleEditClick("partId")} className="edit-btn"><CiEdit className="edit-icon" size={15} /></button>}
                    </div>
                    {isEditing && editingField === "partId" ? (
                        <>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                <InputLabel id="partId-label" color="success">Editer le nombre de parts</InputLabel>
                                <Select
                                    labelId="partId-label"
                                    id="partId-select"
                                    value={editedFields.partId}
                                    color="success"
                                    onChange={(e) => handleInputChange("partId", e.target.value)}
                                >
                                    {Object.entries(partIdLabels).map(([key, value]) => (
                                        <MenuItem key={key} value={key}>{value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Button onClick={handleSaveClick} variant="contained" color="success" sx={{ mt: 1 }}>
                                Save
                            </Button>
                        </>
                    ) : (
                        <div className="recipes-details-partid">
                            {partIdLabels[recipe.partId] ? partIdLabels[recipe.partId].split("—")[1] : "Part ID not found"}
                        </div>
                    )}
                </div>
            </div>
    
            <div className="recipes-details-description-container">
                <div className="recipes-details-description">
                    <div className="recipes-details-description-title">
                        <h2>Description</h2>
                        {canEdit && <button onClick={() => handleEditClick("description")} className="edit-btn"><CiEdit className="edit-icon" size={15} /></button>}
                    </div>
                    {isEditing && editingField === "description"
                        ? renderEditField("description", "description", editedFields.description, "textarea")
                        : (
                            <div
                                className="recipes-details-description-content"
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(recipe.description) }}
                                style={{ textAlign: "justify" }}
                            />
                        )}
                </div>
                <div className="recipes-details-prep_time">
                    <div className="recipes-details-prep_time-title">
                        <h2>Temps de préparation en minutes</h2>
                        {canEdit && <button onClick={() => handleEditClick("prep_time")} className="edit-btn"><CiEdit className="edit-icon" size={15} /></button>}
                    </div>
                    {isEditing && editingField === "prep_time"
                        ? renderEditField("temps de préparation", "prep_time", editedFields.prep_time)
                        : <div className="recipes-details-prep_time">{recipe.prep_time}</div>}
                </div>
            </div>
    
            {/* Steps Section */}
            <div className="recipes-details-steps">
                <div className="recipes-details-steps-title">
                    <h2>Procédé de préparation</h2>
                    {canEdit && <button onClick={() => handleEditClick("steps")} className="edit-btn"><CiEdit className="edit-icon" size={15} /></button>}
                </div>
                {isEditing && editingField === "steps" ? (
                    <>
                        <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                            <ReactQuill
                                id="outlined-multiline-static"
                                className="recipes-details-steps-field" 
                                label="Editer les étapes"
                                color="success"
                                multiline
                                rows={4}
                                defaultValue={recipe.steps}
                                value={editedFields ? editedFields.steps : ''}
                                onChange={(value) => handleInputChange("steps", value)}
                                required
                                sx={{ mb: 1, width: "100%" }}
                            />
                        </FormControl>
                        <Button onClick={handleSaveClick} variant="contained" color="success" sx={{ mt: 1 }}>
                            Save
                        </Button>
                    </>
                ) : (
                    <div
                        className="recipes-details-steps-content"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(recipe.steps) }}
                        style={{ textAlign: "justify" }}
                    />
                )}
            </div>
    
            {/* Display Visibility only for admin users */}
            {isAdmin && (
                <div className="recipes-details-visibility">
                    <div className="recipes-details-visibility-title">
                        <h2>Visibilité</h2>
                        {canEdit && <button onClick={() => handleEditClick("visibility")} className="edit-btn"><CiEdit className="edit-icon" size={15} /></button>}
                    </div>
                    {isEditing && editingField === "visibility" ? (
                        <Switch
                            checked={checked}
                            onChange={handleToggle} // Automatic save on toggle
                            color="success"
                            inputProps={{ 'aria-label': 'toggle visibility' }}
                        />
                    ) : (
                        <div className="recipes-details-visibility">
                            {recipe.visibility ? "Visible" : "Invisible"}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default RecipesDetailsContainer;
