import { useState, useEffect } from "react";

import dayjs from "dayjs";

//MUI components
import {
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    TextField,
    InputAdornment
} from "@mui/material";

//Icons
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { CiEdit } from 'react-icons/ci';
import { TiDeleteOutline } from 'react-icons/ti';
import SearchIcon from '@mui/icons-material/Search';

//Element
import PopupConfirm from '../../elements/popup/popup-confirm/PopupConfirm';
import EditPopup from "../../elements/popup/popup-edit/popupEdit";

//API
import { fetchAllUsers, updateUserbyId, deleteUserbyId } from "../../../api/userAPI";


//toast
import toast from 'react-hot-toast';

//Style
import '../../../views/admin-dashboard/stock/Stock.css'

export default function UsersTable() {

    //Local States
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [openEditPopup, setOpenEditPopup] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'ascending' });
    const [searchTerm, setSearchTerm] = useState("");



    //Number of users per page
    const usersPerPage = 8;

    //Filters for search
    const filteredUsers = users.filter(user =>
        user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.id.toString().includes(searchTerm) ||
        (user.role && user.role.name.toLowerCase().includes(searchTerm.toLowerCase()))
    );




    /**
     * Fetches all users and stores them in the 'users' state.
     * This function is executed only once, on component mount.
     */
    useEffect(() => {
        const getAllUsers = async () => {
            try {
                const fetchedUsers = await fetchAllUsers();
                setUsers(fetchedUsers);
            } catch (error) {
                console.error("Erreur lors de la récupération des utilisateurs:", error);
            }
        };

        getAllUsers();
    }, []);

    /**
     * Sorts the users based on the specified key and direction.
     * This function is executed whenever the sortConfig changes.
     * @param {string} key - The key to sort the users by.
     */
    useEffect(() => {
        const sortedUsers = [...users];
        sortedUsers.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setUsers(sortedUsers);
    }, [sortConfig]);

    /**
     * Resets the current page to 1 whenever the search term changes.
     */
    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm]);


    //To sort columns
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    //Pages
    const handleNextPage = () => {
        if (currentPage < Math.ceil(users.length / usersPerPage)) {
            setCurrentPage(prev => prev + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    // Fields for user data
    const userFields = [
        { name: 'firstname', label: 'Prénom' },
        { name: 'lastname', label: 'Nom' },
        { name: 'email', label: 'Email' },
        { name: 'roleId', label: 'Rôle' },
    ];


    const handleEditClick = (user) => {
        setSelectedUser(user);
        setOpenEditPopup(true);
    };

    // Save User's updates in DB
    const handleSaveUser = async (selectedUser, id) => {
        try {
            await updateUserbyId(selectedUser.id, selectedUser);
            setOpenEditPopup(false);
            toast.success('Utilisateur mise à jour avec succès');
            const updatedUser = users.map(user =>
                user.id === selectedUser.id ? selectedUser : user
            );
            setUsers(updatedUser);
        } catch (error) {
            console.error("Erreur lors de la mise à jour de l'utilisateur", error);
            toast.error("Erreur lors de la mise à jour de l'utilisateur");
        }
        fetchAllUsers();
    };


    //Delete user from DB
    const handleConfirmDelete = async (userId) => {
        try {

            await deleteUserbyId(userId);
            toast.success('Utilisateur supprimé avec succès');
            const updatedUsers = users.filter(user => user.id !== userId);
            setUsers(updatedUsers);

        } catch (error) {
            toast.error('Erreur lors de la suppression de l’utilisateur');
        }
        fetchAllUsers();

    }



    /*----------------------------------------- Table Styles --------------------------------------------*/

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        "&.MuiTableCell-head": {
            backgroundColor: "rgb(50, 89, 62)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            cursor: "pointer",
        },
        "&.MuiTableCell-body": {
            fontSize: 12,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    }));

    return (
        <>
            <div className="user-table-search flex-center" style={{ "display": "flex", "marginBottom": "1rem" }}>
                <input
                    placeholder="Rechercher"
                    className="search-bar"
                    variant="outlined"
                    color="success"
                    fullWidth
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" >
                                <SearchIcon style={{ "fontSize": "15px" }} />
                            </InputAdornment>

                        ),
                    }}
                    style={{ margin: '10px', width: '99%', backgroundColor: "transparent" }}
                />

            </div>


            <TableContainer component={Paper}>
                <Table aria-label="users table" style={{ maxWidth: "850px", minWidth: "850px" }}>
                    {/*----------------------------------------- Colums --------------------------------------------*/}
                    <TableHead>
                        <TableRow>
                            {[
                                { key: 'id', label: 'ID' },
                                { key: 'firstname', label: 'Prénom' },
                                { key: 'lastname', label: 'Nom' },
                                { key: 'email', label: 'Email' },
                                { key: 'createdAt', label: 'Inscrit le' },
                                { key: 'role.name', label: 'Rôle' },
                                { key: 'edit', label: '' },
                                { key: 'delete', label: '' },
                            ].map(column => (
                                <StyledTableCell key={column.key}>
                                    <IconButton size="small" onClick={() => handleSort(column.key)} style={{ color: 'white', fontSize: 13 }}>
                                        {column.label} {sortConfig.key === column.key && (sortConfig.direction === 'ascending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
                                    </IconButton>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {/*----------------------------------------- Rows --------------------------------------------*/}

                    <TableBody>
                        {currentUsers.map((user) => (
                            <StyledTableRow key={user.id}>
                                <TableCell style={{ width: 50 }}>{user.id}</TableCell>
                                <TableCell style={{ overflow: "hidden", width: 150, maxWidth: 150, fontSize: 13 }}>{user.firstname}</TableCell>
                                <TableCell style={{ overflow: "hidden", width: 150, maxWidth: 150, fontSize: 13 }}>{user.lastname}</TableCell>
                                <TableCell style={{ overflow: "hidden", width: 200, maxWidth: 200, fontSize: 13 }}>{user.email}</TableCell>
                                <TableCell style={{ overflow: "hidden", width: 200, maxWidth: 200, fontSize: 13 }}>{dayjs(user.createdAt).format('DD/MM/YYYY')}</TableCell>
                                <TableCell style={{ width: 50, fontSize: 13 }}>{user.role ? user.role.name : "N/A"}</TableCell>
                                <TableCell style={{ width: 20, fontSize: 13 }}>
                                    <IconButton onClick={() => handleEditClick(user)}>
                                        <CiEdit size={15} />
                                    </IconButton>

                                    <EditPopup
                                        open={openEditPopup}
                                        onClose={() => setOpenEditPopup(false)}
                                        data={selectedUser}
                                        onSave={handleSaveUser}
                                        fields={userFields}
                                        title={`Modifier l'utilisateur ${selectedUser ? selectedUser.firstname : ''}`}
                                    />
                                </TableCell>
                                <TableCell style={{ width: 20 }}>

                                    <PopupConfirm
                                        trigger={
                                            <TiDeleteOutline
                                                size={20}
                                                className='item-delete-icon'
                                                style={{ "color": "red" }}
                                            />
                                        }
                                        onConfirm={() => handleConfirmDelete(user.id)}
                                        message={`Êtes-vous sûr de vouloir supprimer l'utilisateur ${user.firstname + " " + user.lastname} ?`}
                                    />


                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/*----------------------------------------- Pagination --------------------------------------------*/}

            <div className="pagination" style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", marginTop: "2rem" }}>
                <Button
                    style={{ border: "green 1px solid", color: "green", padding: ".5rem" }}
                    disabled={currentPage === 1}
                    onClick={handlePreviousPage}
                    variant="outlined"
                >
                    <ArrowBackIosNewIcon style={{ "fontSize": "15px" }} />
                </Button>
                <span>Page {currentPage}</span>
                <Button
                    style={{ border: "green 1px solid", color: "green", padding: ".5rem" }}
                    disabled={currentPage === Math.ceil(users.length / usersPerPage)}
                    onClick={handleNextPage}
                    variant="outlined"
                >
                    <ArrowForwardIosIcon style={{ "fontSize": "15px" }} />
                </Button>
            </div>
        </>
    );
}
