import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubscription } from "../../api/subsciptionAPI";
import { setSubscriptionExists, resetSubscription } from '../../features/subscriptions/subscriptionSlice';
import { fetchCurrentUserInfos, fetchCurrentUserOrders } from "../../api/userAPI";
import { fetchParticipantByUserId } from "../../api/groupOrdersParticipantsAPI";
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import PopupFridge from "../../components/elements/popup/popup-fridge/PopupFridge";
import "../admin-dashboard/AdminDashboard.css";

function ClientMobile() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Accéder aux informations d'abonnement depuis Redux
    const subscriptionExists = useSelector((state) => state.subscription.subscriptionExists);
    const subscriptionInfos = useSelector((state) => state.subscription.subscriptionInfos);

    const [userInfo, setUserInfo] = useState({});
    const [unpaidOrdersExist, setUnpaidOrdersExist] = useState(false);
    const [unpaidOrderNum, setUnpaidOrderNum] = useState(null);
    const [readyOrderExist, setReadyOrderExist] = useState(false);
    const [showPopupFridge, setShowPopupFridge] = useState(false);
    const [readyOrderNum, setReadyOrderNum] = useState(null);
    const [groupOrderExist, setGroupOrderExist] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [orderError, setOrderError] = useState(null);

    // Fetch user info
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const data = await fetchCurrentUserInfos();
                setUserInfo(data);
            } catch (error) {
                console.error("Erreur lors de la récupération des informations de l'utilisateur:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserInfo();
    }, []);

    // Fetch subscription info and orders/participants once user info is available
    useEffect(() => {
        const fetchUserOrderAndParticipant = async () => {
            if (userInfo.id) {
                try {
                    const data = await fetchCurrentUserOrders();
                    const userId = userInfo.id;
                    const isParticipant = await fetchParticipantByUserId(userId);

                    if (data && data.length > 0) {
                        const lastOrder = data[0];

                        // Set unpaid and ready orders
                        setUnpaidOrdersExist(lastOrder.payment_status === false);
                        setUnpaidOrderNum(lastOrder.order_num);

                        if (lastOrder.status === "accepted" && (!isParticipant || lastOrder.id !== isParticipant?.order_id)) {
                            setReadyOrderExist(true);
                            setReadyOrderNum(lastOrder.order_num);
                        } else {
                            setReadyOrderExist(false);
                        }

                        // Group order visibility
                        if (isParticipant && lastOrder.id === isParticipant?.order_id && lastOrder.payment_status !== "paid") {
                            setGroupOrderExist(true);
                        } else {
                            setGroupOrderExist(false);
                        }
                    } else {
                        setUnpaidOrdersExist(false);
                        setReadyOrderExist(false);
                        setGroupOrderExist(false);
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération des commandes et des informations du participant :", error);
                    setOrderError("Impossible de charger vos commandes. Veuillez réessayer plus tard.");
                }
            }
        };

        if (userInfo.id) {
            fetchUserOrderAndParticipant();
        }

        const getUserSubscription = async () => {
            try {
                const response = await fetchSubscription(userInfo.id);
                if (response.status === 200 && response.data) {
                    dispatch(setSubscriptionExists({
                        exists: true,
                        subscriptionInfos: response.data
                    }));
                } else {
                    dispatch(resetSubscription());
                }
            } catch (error) {
                console.error("Erreur lors de la récupération de l'abonnement", error);
                dispatch(resetSubscription());
            }
        };

        if (userInfo.id) {
            getUserSubscription();
        }
    }, [userInfo, dispatch]);

    // Log subscription details
    useEffect(() => {
        console.log("Subscription Exists: ", subscriptionExists);
        console.log("Subscription Infos: ", subscriptionInfos);
    }, [subscriptionExists, subscriptionInfos]);

    const handleUserClick = () => {
        navigate("/account/profil");
    };

    const handleOrderClick = () => {
        navigate("/account/my-orders");
    };

    const handleGroupOrderClick = () => {
        if (groupOrderExist) {
            navigate("/account/orders-tracking");
        }
    };

    const handleSubscriptionClick = () => {
        navigate("/account/subscriptions");
    };

    const handleRecipeClick = () => {
        if (subscriptionExists && subscriptionInfos?.active) {
            navigate("/recipes");
        } else {
            navigate("/account/subscriptions");
        }
    };

    const handleMenuMakerClick = () => {
        if (subscriptionExists && subscriptionInfos?.active) {
            navigate("/account/menulist");
        } else {
            navigate("/account/subscriptions");
        }
    };

    const handleStockClick = () => {
        if (subscriptionExists && subscriptionInfos?.active && subscriptionInfos.subscription_type === "pro_chef") {
            navigate("/account/stock");
        } else {
            navigate("/account/subscriptions");
        }
    };

    const handleButtonClick = () => {
        if (unpaidOrdersExist && unpaidOrderNum) {
            localStorage.setItem("order_num", unpaidOrderNum);
            navigate("/checkout");
        } else if (readyOrderExist) {
            setShowPopupFridge(true);
        } else {
            navigate("/home");
        }
    };

    const handleActionSuccess = () => {
        setReadyOrderExist(false);
    };

    // Gérer l'état de chargement ou les erreurs
    if (isLoading) {
        return <div className="loading-screen"><h2>Chargement...</h2></div>;
    }

    if (orderError) {
        return <div className="error-message">{orderError}</div>;
    }

    return (
        <>
            <div className="admin-mobile-header">
                <h2>Hello {userInfo.firstname} 👋</h2>
                <Typography level="body-ms" textColor={"#f5f5f5"} paddingLeft={'1rem'}>Que voulez-vous faire aujourd'hui?</Typography>
            </div>
            <div className="admin-mobile-actions">
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleUserClick}>
                    Profil
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleOrderClick}>
                    Commandes
                </Button>
                {groupOrderExist && (
                    <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleGroupOrderClick}>
                        Commandes groupées
                    </Button>
                )}
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleSubscriptionClick}>
                    Abonnements
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleStockClick}>
                    Produits
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleRecipeClick}>
                    Recettes
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleMenuMakerClick}>
                    Menus
                </Button>
                <Button color="danger" sx={{ width: "270px", height: '50px', marginBottom: "3rem" }} variant="outlined" size="lg"
                    onClick={handleButtonClick}>
                    {unpaidOrdersExist
                        ? "Payer la commande"
                        : readyOrderExist
                            ? "Ouvrir le frigo"
                            : "Commander un repas"}
                </Button>
            </div>
            {showPopupFridge && (
                <PopupFridge
                    isOpen={showPopupFridge}
                    onConfirm={() => setShowPopupFridge(false)}
                    onActionSuccess={handleActionSuccess}
                    readyOrderNum={readyOrderNum}
                />
            )}
        </>
    );
}

export default ClientMobile;

const buttonStyle = {
    borderColor: "#006d26",
    color: "#006d26",
    width: "270px",
    height: '50px'
};
