import apiInstance from "./api";
import toast from "react-hot-toast"

/**
 * Inscrit l'utilisateur.
 * @param {string} firstname - Le prénom de l'utilisateur.
 * @param {string} lastname - Le nom de famille de l'utilisateur.
 * @param {string} email - L'adresse email de l'utilisateur.
 * @param {string} password - Le mot de passe de l'utilisateur.
 * @returns {Promise} Une promesse qui se résout avec les données de réponse.
 */

export const signup = async (firstname, lastname, email, password) => {
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr"
  };
  try {
    const response = await apiInstance.post(
      `${process.env.REACT_APP_API_URL}/auth/signup`,
      {
        firstname,
        lastname,
        email,
        password
      },
      { headers: headers }
    );

    if (response.data) {
      await login(email, password)
      return response.data;
    } else {
      console.error(
        "Erreur lors de la tentative d'inscription: Le jeton d'accès n'a pas été renvoyé dans la réponse."
      );
      throw new Error("Inscription échouée");
    }
  } catch (error) {
    console.error("Erreur lors de la tentative d'inscription:", error);
    throw error;
  }
};





/**
 * Logs in the user.
 * @param {string} email - The user's email.
 * @param {string} password - The user's password.
 * @param {function} dispatch - The dispatch function from the Redux store.
 * @returns {Promise} A promise that resolves with the response data.
 */
export const login = async (email, password, dispatch) => {
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr"
  };

  try {
    const response = await apiInstance.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
      email,
      password
    }, { headers: headers });

    if (response.data.accessToken) {
      localStorage.setItem("user", JSON.stringify(response.data));

      const tokenHeaders = {
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": "fr",
        "x-access-token": response.data.accessToken
      };

      const rolesResponse = await apiInstance.get(`${process.env.REACT_APP_API_URL}/user/roles`, { headers: tokenHeaders });



      if (rolesResponse.data) {
        localStorage.setItem('roles', JSON.stringify(rolesResponse.data));
      }
    }

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la tentative de connexion:", error);
    throw error;
  }
};



/**
 * Checks if the email is already in use.
 * @param {string} email - The email to check.
 * @returns {Promise<boolean>} A promise that resolves with true if the email is already in use, false otherwise.
 */
export const verifyEmail = async (email) => {

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr"
  };

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/auth/check-email?email=${email}`, { headers: headers });
    if (response.data.message.includes("is already in use")) {
      // If the email is already in use, return true
      return true;
    } else {
      // If the email is available, return false
      return false;
    }
  } catch (error) {
    console.error("Error while checking email:", error);
    throw error;
  }
};


/**
 * Logs out the user.
 * @returns {Promise} A promise that resolves when the user is logged out.
 */
export const logout = async () => {
  // Optionnel : si votre backend prend en charge une action de déconnexion,
  // vous pouvez faire une requête pour lui signaler que l'utilisateur est déconnecté.
  // Ceci est utile si vous voulez par exemple invalider le token côté serveur.
  try {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": "fr",
      "x-access-token": token
    };

    await apiInstance.post(`${process.env.REACT_APP_API_URL}/auth/logout`, {}, { headers: headers });

    // Supprimez l'utilisateur et le token du localStorage
    localStorage.removeItem("user");

    return { success: true };
  } catch (error) {
    console.error("Erreur lors de la déconnexion :", error);
    return { success: false, error };
  }
};



export const refreshToken = async () => {
  try {
    // Supposons que votre refreshToken est stocké dans localStorage
    const refreshToken = localStorage.getItem('user').refreshToken;

    // Vérifiez d'abord si refreshToken existe
    if (!refreshToken) {
      console.error('No refresh token available');
      throw new Error('Login required');
    }

    // Faites la requête de rafraîchissement
    const response = await apiInstance.post('/auth/refresh', { refreshToken });

    localStorage.setItem('accessToken', response.data.accessToken);


    return response.data.accessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

export const verifyToken = async (navigate) => {
  const userStr = localStorage.getItem('user');
  const user = userStr ? JSON.parse(userStr) : null;
  const token = user ? user.accessToken : null;

  if (!token) {
    toast.error("Session expirée. Veuillez vous reconnecter.");
    setTimeout(() => navigate('/login'), 2000); // Retarder la navigation
    return { isValid: false };
  }

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/auth/validate-token`, {
      headers: { "x-access-token": token }
    });
    return { isValid: true };
  } catch (error) {
    console.error("Error validating token:", error);
    if (error.response && error.response.status === 403) {
      toast.error("Session expirée. Veuillez vous reconnecter.")
      setTimeout(() => navigate('/login'), 2000);
    }
    return { isValid: false };
  }
};




