import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import authReducer from './features/auth/authSlice';
import cartReducer from './features/cart/cartSlice';
import popupReducer from './features/popup/popupSlice';
import preorderReducer from './features/preorder/preorderSlice';
import subscriptionReducer from './features/subscriptions/subscriptionSlice';
import deliveryZoneReducer from './features/delivery-zones/DeliverySlice'



const store = configureStore({
    reducer: {
        auth: authReducer,
        cart: cartReducer,
        popup: popupReducer,
        preorder: preorderReducer,
        subscription: subscriptionReducer,
        deliveryZone: deliveryZoneReducer,
    },
    middleware: [thunk],
});

export default store;