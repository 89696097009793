
import { useSelector, useDispatch } from 'react-redux';
import { setPreorderEnabled, setSelectedDate, setSelectedTime } from '../../../features/preorder/preorderSlice';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';

//Ants Design Components
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { TimePicker } from 'antd';
// import { DatePicker } from 'antd';

function PreorderDate() {
    //Global States
    const selectedDate = useSelector((state) => state.preorder.selectedDate);
    const selectedTime = useSelector((state) => state.preorder.selectedTime);


    const dispatch = useDispatch();
    const dateFormat = "DD-MM-YYYY";
    const format = 'HH:mm:ss';

    const currentDay = dayjs();
    const dayPlus1 = currentDay.add(1, 'day');

    const handleDateChange = (date) => {
        const formattedDate = date.format(dateFormat);
        dispatch(setSelectedDate(formattedDate));
        localStorage.setItem('selectedDate', formattedDate);
    };

    const shouldDisableDate = (date) => {
        return date.isBefore(dayPlus1);
    };


    const handleTimeChange = (time) => {
        const formattedTime = time ? time.format(format) : null;
        dispatch(setSelectedTime(formattedTime));
        localStorage.setItem('selectedTime', formattedTime);
    };

    return (
        <section className='preorder-date'>
            <div className="preorder-date-title">
                <h2>Quand voulez-vous être livré ?</h2>
            </div>
            <div className="preorder-date-description">
                <p>
                    Vous pouvez maintenant précommander tous vos plats préferés, même lorsqu'ils ne sont pas disponibles dans le frigo.
                    Pour répondre au mieux à vos demandes et vous permettre de vous régaler avec des plats frais, saints et délicieux, Mamossa a besoin d'un <span>délai de 24h</span> pour les pré-commandes.</p>
            </div>
            <div className="preorder-date-pickers">
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DemoContainer components={['DatePicker', 'TimePicker']}>
                        <DatePicker
                            placeholder="Date"
                            value={selectedDate ? dayjs(selectedDate, dateFormat) : null}
                            onChange={handleDateChange}
                            format={dateFormat}
                            className="datepicker"
                            shouldDisableDate={shouldDisableDate}
                        />

                        <TimePicker
                            placeholder="Heure"
                            format={format}
                            value={selectedTime ? dayjs(selectedTime, format) : null}
                            onChange={handleTimeChange}
                            ampm={false}
                            className="timepicker"
                            variant="standard"
                            />
                    </DemoContainer>
                </LocalizationProvider>
            </div>
        </section>
    )
}

export default PreorderDate;
