import React from 'react';

const BackButton = ({ onClick }) => {
    // Vérifie si la largeur de l'écran est inférieure à 640px
    const isMobile = window.innerWidth < 640;

    return (
        <button
            onClick={onClick}
            style={{
                display: isMobile ? 'flex' : 'none', 
                backgroundColor: 'rgb(50, 89, 62)',
                border: 'none',
                color: 'white',
                fontWeight: 'bold',
                padding: '10px',
                borderRadius: '5px',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            Retour
        </button>
    );
};

export default BackButton;
