import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Checkbox } from "@mui/material";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { getRecipesByMenuId } from '../../../api/menuRecipesAPI';
import { fetchRecipeById } from "../../../api/recipesAPI"
import { useSelector } from 'react-redux';

function ShoppingListTables({ productsInfos, menuInfos, updateShoppingListProduct }) {
    const [recipesPortions, setRecipesPortions] = useState({});
    const [recipesDetails, setRecipesDetails] = useState({});


    const subscriptionExists = useSelector((state) => state.subscription.subscriptionExists);
    const subscriptionInfos = useSelector((state) => state.subscription.subscriptionInfos);
    const shouldDisplayCostColumns = !(subscriptionExists &&
        (subscriptionInfos.subscription_type === "free" || subscriptionInfos.subscription_type === "home_cook"));

    useEffect(() => {
        const fetchRecipePortionsAndDetails = async () => {
            try {
                const recipes = await getRecipesByMenuId(menuInfos.id);

                // Fetch recipe portions
                const recipesPortionsResult = recipes.reduce((acc, recipe) => {
                    acc[recipe.recipe_id] = recipe.portions;
                    return acc;
                }, {});
                setRecipesPortions(recipesPortionsResult);

                // Fetch each recipe's details and store them in state
                const recipeDetailsPromises = recipes.map(async (recipe) => {
                    const recipeDetails = await fetchRecipeById(recipe.recipe_id);
                    return { recipeId: recipe.recipe_id, details: recipeDetails };
                });

                const resolvedDetails = await Promise.all(recipeDetailsPromises);

                // Create a new object with recipe IDs as keys and details as values
                const recipesDetailsResult = resolvedDetails.reduce((acc, { recipeId, details }) => {
                    acc[recipeId] = details;
                    return acc;
                }, {});

                setRecipesDetails(recipesDetailsResult);  // Store recipe details in state
            } catch (error) {
                console.error('Error fetching recipes for menuId', menuInfos.id, ':', error);
            }
        };

        if (menuInfos.id) {
            fetchRecipePortionsAndDetails();
        }
    }, [menuInfos.id]);

    // Logging the details to verify the fetched recipe details
    useEffect(() => {
        console.log("Recipes Details: ", recipesDetails);
    }, [recipesDetails]);


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#004618',
            color: theme.palette.common.white,
            fontWeight: 'bold'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    // Group products by category
    const groupedProducts = productsInfos.reduce((acc, product) => {
        if (!acc[product.category]) {
            acc[product.category] = [];
        }
        acc[product.category].push(product);
        return acc;
    }, {});



    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Table stickyHeader aria-label="shopping list table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Acheté</StyledTableCell>
                            <StyledTableCell>Nom du Produit</StyledTableCell>
                            <StyledTableCell align="right">Quantité ajustée</StyledTableCell>
                            <StyledTableCell align="right">Unités</StyledTableCell>
                            <StyledTableCell align="right">Prix/kg(€)</StyledTableCell>

                            {shouldDisplayCostColumns && (
                                <>
                                    <StyledTableCell align="right">Coût (€)</StyledTableCell>
                                </>
                            )}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(groupedProducts).map(([category, products]) => (
                            <React.Fragment key={category}>
                                <TableRow>
                                    <StyledTableCell colSpan={6} style={{ textAlign: 'left', backgroundColor: '#017529', color: '#fff' }}>
                                        {category}
                                    </StyledTableCell>
                                </TableRow>
                                {products.map((product, index) => {
                                    // Adjusted unit quantity based on recipe details and menu portions
                                    const adjustedUnitQty = (product.unitQty / (recipesDetails[product.recipesId]?.portions || 1)) * (recipesPortions[product.recipesId] || 1);

                                    // Log the details for debugging
                                    console.log(`
        Product: ${product.name},
        Unit Quantity: ${product.unitQty},
        Recipe Portions: ${recipesDetails[product.recipesId]?.portions || 'N/A'},
        Menu Portions: ${recipesPortions[product.recipesId] || 'N/A'},
        Adjusted Unit Quantity: ${adjustedUnitQty}
    `);

                                    // Price per unit calculation
                                    const pricePerGram = product.price / 1000;
                                    const costPerPortion = (pricePerGram * adjustedUnitQty).toFixed(2);
                                    const totalCost = (costPerPortion * (menuInfos.portions || 1)).toFixed(2);

                                    console.log(`
        Price per Kilo: ${product.price},
        Price per Gram: ${pricePerGram},
        Cost per Portion: ${costPerPortion},
        Total Cost for Menu Portions: ${totalCost}
    `);

                                    return (
                                        <TableRow key={index}>
                                            <StyledTableCell padding="checkbox">
                                                <Checkbox
                                                    checked={product.tobuy === false}
                                                    onChange={() => updateShoppingListProduct(product.id)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {product.name}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {adjustedUnitQty}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {product.unitType}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {product.price}
                                            </StyledTableCell>

                                            {shouldDisplayCostColumns && (
                                                <>
                                                    <StyledTableCell align="right">
                                                        {costPerPortion}
                                                    </StyledTableCell>

                                                </>
                                            )}
                                        </TableRow>
                                    );
                                })}

                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default ShoppingListTables;
