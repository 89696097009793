import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Autocomplete, TextField, Pagination, CircularProgress, useMediaQuery, Stack, Button, Typography } from "@mui/material";
import { fetchRecipeByUserId, fetchAllRecipePictures } from '../../../../api/recipesAPI';
import { fetchDataForRecipe } from "../../../../api/stockAPI";
import MobileRecipeCard from "./MobileRecipeCard";
import "./MobileRecipe.css";

function MobileRecipeList() {
    const { id } = useParams();
    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");

    const [recipes, setRecipes] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [showFilters, setShowFilters] = useState(false);
    const [selectedIngredients, setSelectedIngredients] = useState([]);
    const [ingredientsList, setIngredientsList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [loading, setLoading] = useState(true);

    // Changer de page
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // Récupération des recettes et détails
    const fetchRecipesWithDetails = async () => {
        try {
            setLoading(true);
            const data = await fetchRecipeByUserId();
            const pictures = await fetchAllRecipePictures();

            const detailedRecipes = await Promise.all(
                data.map(async (recipe) => {
                    const picture = pictures.find(pic => pic.recipe_id === recipe.id);
                    if (picture) {
                        recipe.imageUrl = `${process.env.REACT_APP_API_URL}${picture.path}`;
                    }

                    const recipeDetails = await fetchDataForRecipe(recipe.id);
                    return {
                        ...recipe,
                        linkedProducts: recipeDetails.linkedProducts,
                        allProducts: recipeDetails.allProducts,
                    };
                })
            );

            setRecipes(detailedRecipes);

            // Extraction d'ingrédients uniques pour le filtre
            const allIngredients = detailedRecipes.flatMap(recipe => recipe.allProducts || []);
            const uniqueIngredients = [...new Set(allIngredients.map(item => item.label))];
            setIngredientsList(uniqueIngredients);
        } catch (error) {
            console.error("Erreur lors de la récupération des recettes et des détails:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRecipesWithDetails();
    }, []);

    // Filtrer les recettes en fonction du terme de recherche et des ingrédients sélectionnés
    const filteredRecipes = recipes.filter(recipe => {
        const searchTermLower = searchTerm.toLowerCase();

        // Vérifie le terme de recherche
        const matchesSearchTerm = recipe.name.toLowerCase().includes(searchTermLower) ||
                                  recipe.description.toLowerCase().includes(searchTermLower);

        // Vérifie les ingrédients exclus
        const containsExcludedIngredients = selectedIngredients.some(selectedIngredient =>
            recipe.linkedProducts?.some(linkedProduct => linkedProduct.productLabel === selectedIngredient)
        );

        return matchesSearchTerm && !containsExcludedIngredients;
    });

    // Applique la pagination aux recettes filtrées
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredRecipes.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <section className="recipe-list-mobile">
            <div className="recipe-mobile-header">
                <h2>Gestion des recettes</h2>
                <TextField
                    type="text"
                    placeholder="Rechercher une recette..."
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ width: "100%" }}
                />
                <Button variant="plain" color="success" onClick={() => setShowFilters(!showFilters)}>
                    Filtres
                </Button>

                {showFilters && (
                    <Autocomplete
                        multiple
                        options={ingredientsList}
                        value={selectedIngredients}
                        onChange={(event, newValue) => setSelectedIngredients(newValue)}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" placeholder="Exclure les ingrédients..." />
                        )}
                        sx={{ width: "100%", marginTop: "1rem" }}
                    />
                )}
            </div>

            <div className="mobile-recipe-list">
                {loading ? (
                    <CircularProgress sx={{ color: 'darkgreen' }} />
                ) : currentItems.length > 0 ? (
                    currentItems.map(recipe => (
                        <Link key={recipe.id} to={`/recipes/${recipe.id}`}>
                            <MobileRecipeCard recipe={recipe} isTablet={isTablet} />
                        </Link>
                    ))
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        Aucun résultat trouvé.
                    </Typography>
                )}
            </div>

            <div className="pagination">
                <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: '20px', marginBottom: "1rem" }}>
                    <Pagination
                        size="small"
                        siblingCount={0}
                        count={Math.ceil(filteredRecipes.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        color="primary"
                        showFirstButton
                        showLastButton
                    />
                </Stack>
            </div>
        </section>
    );
}

export default MobileRecipeList;
