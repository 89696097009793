import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Badge, styled, Accordion, AccordionSummary, AccordionDetails, Typography, IconButton, Button } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PopupConfirm from '../../elements/popup/popup-confirm/PopupConfirm';
import EditPopup from "../../elements/popup/popup-edit/popupEdit";
import { fetchAllUsers, deleteUserbyId, updateUserbyId } from "../../../api/userAPI";
import toast from 'react-hot-toast';
import useMediaQuery from '@mui/material/useMediaQuery';

import "./AllUsers.css";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    marginBottom: "0rem",
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    color: theme.palette.common.black,
    fontWeight: "bold",
    cursor: "pointer",
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
}));

const UsersTable = () => {

    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [openEditPopup, setOpenEditPopup] = useState(false);
    const [sortedUsers, setSortedUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'ascending' });
    const [searchTerm, setSearchTerm] = useState("");
    const usersPerPage = isTablet ? 15 : 7;

    useEffect(() => {
        const getAllUsers = async () => {
            try {
                const fetchedUsers = await fetchAllUsers();
                setUsers(fetchedUsers);
            } catch (error) {
                console.error("Erreur lors de la récupération des utilisateurs:", error);
            }
        };

        getAllUsers();
    }, []);

    useEffect(() => {
        const usersToSort = [...users];
        usersToSort.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setSortedUsers(usersToSort);
    }, [sortConfig, users]);

    const filteredUsers = useMemo(() => sortedUsers.filter(user =>
        user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.id.toString().includes(searchTerm) ||
        (user.role && user.role.name.toLowerCase().includes(searchTerm.toLowerCase()))
    ), [sortedUsers, searchTerm]);


    const handleNextPage = useCallback(() => {
        if (currentPage < Math.ceil(users.length / usersPerPage)) {
            setCurrentPage(prev => prev + 1);
        }
    }, [currentPage, users, usersPerPage]);

    const handlePreviousPage = useCallback(() => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    }, [currentPage]);

    const userFields = [
        { name: 'firstname', label: 'Prénom' },
        { name: 'lastname', label: 'Nom' },
        { name: 'email', label: 'Email' },
        { name: 'roleId', label: 'Rôle' },
    ];


    const handleEditClick = (user) => {
        setSelectedUser(user);
        setOpenEditPopup(true);
    };

    const handleUserClick = (e, user) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleSaveUser = async (selectedUser, id) => {
        try {
            await updateUserbyId(selectedUser.id, selectedUser);
            setOpenEditPopup(false);
            toast.success('Utilisateur mise à jour avec succès');
            const updatedUser = users.map(user =>
                user.id === selectedUser.id ? selectedUser : user
            );
            setUsers(updatedUser);
        } catch (error) {
            console.error("Erreur lors de la mise à jour de l'utilisateur", error);
            toast.error("Erreur lors de la mise à jour de l'utilisateur");
        }
        fetchAllUsers();
    };

    const handleConfirmDelete = useCallback(async (userId) => {
        try {
            await deleteUserbyId(userId);
            toast.success('Utilisateur supprimé avec succès');
            const updatedUsers = users.filter(user => user.id !== userId);
            setUsers(updatedUsers);
        } catch (error) {
            toast.error('Erreur lors de la suppression de l’utilisateur');
        }
        fetchAllUsers();
    }, [users]);

    return (
        <div className="users-table">
            <div className="users-table-title flex-center">
                <h1 style={{ margin: "1rem 0" }}>Liste des utilisateurs</h1>
            </div>
            <div className="flex-center" style={{ "display": "flex", "marginBottom": "3rem" }}>
                <input
                    type="text"
                    placeholder="Rechercher..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    style={{ "width": 300, "padding": ".85rem", "marginRight": "-1rem", borderTop: "none", borderRight: "none", borderLeft: "none", backgroundColor: "transparent", outline: "none" }}
                />
            </div>
            {filteredUsers.slice((currentPage - 1) * usersPerPage, currentPage * usersPerPage).map(user => (
                <StyledAccordion key={user.id} >
                    <StyledAccordionSummary sx={{ width: isTablet ? "500px" : "100%" }}>
                        <IconButton size="small" onClick={(e) => handleUserClick(e, user)} style={{ color: 'rgb(50, 89, 62)', fontSize: 16 }}>
                            <b>{user.id + " — " + user.firstname + " " + user.lastname}</b>
                        </IconButton>
                        <IconButton style={{ marginLeft: "2rem" }}>
                            {user.role ? (
                                <Badge
                                    color={
                                        user.role.name === "admin"
                                            ? "success"
                                            : user.role.name === "client"
                                                ? "secondary"
                                                : user.role.name === "livreur"
                                                    ? "info"
                                                    : "error"
                                    }
                                    badgeContent={
                                        user.role.name === "admin"
                                            ? "Admin"
                                            : user.role.name === "client"
                                                ? "Client"
                                                : user.role.name === "livreur"
                                                    ? "Livreur"
                                                    : "N/A"
                                    }
                                ></Badge>
                            ) : (
                                <Badge color="error" badgeContent="N/A"></Badge>
                            )}
                        </IconButton>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                        <Typography variant="outlined" color="neutral">
                            Prénom — {user.firstname}
                        </Typography>
                        <Typography variant="outlined" color="neutral">
                            Nom —  {user.lastname}
                        </Typography>
                        <Typography variant="outlined" color="neutral">
                            Email — {user.email}
                        </Typography>
                        <Typography style={{ display: "flex", alignItems: "center", marginTop: "1rem", justifyContent: "space-between" }}>
                            <IconButton style={{ fontSize: 14 }} onClick={() => handleEditClick(user)}>Modifier l'utilisateur</IconButton>
                            <EditPopup
                                open={openEditPopup}
                                onClose={() => setOpenEditPopup(false)}
                                data={selectedUser}
                                onSave={handleSaveUser}
                                fields={userFields}
                                title={`Modifier l'utilisateur ${selectedUser ? selectedUser.firstname : ''}`}
                            />
                            <PopupConfirm
                                trigger={<IconButton style={{ fontSize: 14, color: "red" }}>Supprimer l'utilisateur</IconButton>}
                                onConfirm={() => handleConfirmDelete(user.id)}
                                message={`Êtes-vous sûr de vouloir supprimer l'utilisateur ${user.firstname + " " + user.lastname} ?`}
                            />
                        </Typography>
                    </StyledAccordionDetails>
                </StyledAccordion>
            ))}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", marginTop: "3rem" }}>
                <Button style={{ border: "green 1px solid", color: "green" }} disabled={currentPage === 1} onClick={handlePreviousPage}>
                    <ArrowBackIosNewIcon />
                </Button>
                <span>Page {currentPage}</span>
                <Button style={{ border: "green 1px solid", color: "green" }} disabled={currentPage === Math.ceil(users.length / usersPerPage)} onClick={handleNextPage}>
                    <ArrowForwardIosIcon />
                </Button>
            </div>
        </div>
    );
}

export default UsersTable;
