import { Typography, Card, CardContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function ShoppingListInsights({ menuInfos, productsInfos }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const totalProducts = productsInfos.length;

    return (
        <div className='insights'>
            <div className="c">
                <Card sx={{ minWidth: 170, borderRadius: 8 }}>
                    <CardContent sx={{ textAlign: "center" }}>
                        <Typography variant={isMobile ? "h4" : "h2"} component="div" sx={{ fontWeight: 900, margin: "1rem 0", fontFamily: "'Montserrat', sans- serif" }} >
                            {menuInfos.price + "€"}
                        </Typography>
                        {isMobile ? (
                            <Typography variant="body2" color="text.secondary">
                                Coût total
                            </Typography>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                Coût total pour {menuInfos.portions} portions
                            </Typography>
                        )}
                    </CardContent>
                </Card>
            </div>
            <div className="insights-products">
                <Card sx={{ minWidth: 170, borderRadius: 8 }}>
                    <CardContent sx={{ textAlign: "center" }}>
                        <Typography variant={isMobile ? "h4" : "h2"} component="div" sx={{ fontWeight: 900, margin: "1rem 0", fontFamily: "'Montserrat', sans- serif" }} >
                            {totalProducts}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Nombre de produits
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}

export default ShoppingListInsights;
