import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

// API imports
import { updateOrderById } from '../../api/ordersAPI';
import { createInstantPayment } from '../../api/paygreenAPI';
import { fetchCurrentUserInfos, fetchCurrentUserOrders } from '../../api/userAPI';

// Component imports
import Button from '../../components/elements/button/Button';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';

import CartItem from '../../components/side-cart/CartItem';
import OrderPrice from '../../components/orders/OrderPrice';
import OrderCondition from '../../components/orders/OrderCondition';
import PreorderDate from '../../components/orders/preorders/PreorderDate';
import Addresses from '../../components/orders/Addresses';
import TypeOfDelivery from '../../components/orders/TypeOfDelivery';

import '../../components/orders/Order.css';
import 'reactjs-popup/dist/index.css';

function OrderValidation() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { selectedDate, selectedTime, selectedAddress, preorderEnabled } = useSelector(state => state.preorder);
    const cartItems = useSelector(state => state.cart.items);
    
    const storedDate = localStorage.getItem('selectedDate');
    const storedTime = localStorage.getItem('selectedTime');
    
    const finalSelectedDate = selectedDate || storedDate;
    const finalSelectedTime = selectedTime || storedTime;
    
    const isInDeliveryZone = JSON.parse(sessionStorage.getItem('isInDeliveryZone') || "false");
    const [userInfos, setUserInfos] = useState({});
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    // Calcul du total TTC en euros pour affichage
    const totalTTC = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
    
    // Conversion du total en centimes pour la base de données
    const totalInCents = Math.round(totalTTC * 100);

    useEffect(() => {
        async function init() {
            try {
                const userInfo = await fetchCurrentUserInfos();
                setUserInfos(userInfo);
                console.log("User info loaded:", userInfo);
                
                // Fetch the user's current orders
                const orders = await fetchCurrentUserOrders(userInfo.id);
                const currentOrder = orders.find(order => order.status === 'accepted');
                
                // If an 'accepted' order is found, reset it to 'new'
                if (currentOrder) {
                    const resetOrder = { ...currentOrder, status: 'new' };
                    await updateOrderById(currentOrder.id, resetOrder);
                    console.log("Order status reset to 'new'");
                }
            } catch (error) {
                console.error("Failed to fetch user info or reset order:", error);
                toast.error("Une erreur est survenue lors du chargement des informations.");
            }
        }
        init();
    }, []);    

    const validateUserAndAddress = (user, isChecked, selectedAddress, isInDeliveryZone) => {
        if (!user.id) {
            toast.error('Veuillez vous connecter pour continuer');
            return false;
        }
        if (!isChecked) {
            toast.error('Acceptez les conditions pour continuer');
            return false;
        }
        if (!selectedAddress && preorderEnabled) {
            toast.error('Veuillez sélectionner une adresse de livraison');
            return false;
        }
        if (!isInDeliveryZone && preorderEnabled) {
            toast.error('Votre adresse de livraison n’est pas dans la zone de livraison');
            return false;
        }
        if (!finalSelectedDate && preorderEnabled) {
            toast.error('Veuillez sélectionner une date de livraison.');
            return false;
        }
        return true;
    };

    const getFormattedDeliveryTime = (selectedDate, selectedTime) => {
        if (!selectedDate || !selectedTime) {
            console.error("Delivery date or time not specified");
            return null;
        }

        const formattedDate = finalSelectedDate ? dayjs(finalSelectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : null;
        const formattedTime = finalSelectedTime ? dayjs(finalSelectedTime, 'HH:mm').format('HH:mm:ss') : null;
        const datetime = `${formattedDate} ${formattedTime}`;

        const formattedDateTime = dayjs(datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');

        if (!dayjs(formattedDateTime).isValid()) {
            console.error("Invalid date or time provided");
            return null;
        }

        return formattedDateTime;
    };

    const buildOrderData = (userId, stripeAmount, cartItems, preorderState, selectedAddress) => {
        const cartitems = cartItems.map(item => ({
            recipeId: item.id,
            quantity: item.quantity
        }));

        const deliveryTime = preorderEnabled ? getFormattedDeliveryTime(finalSelectedDate, finalSelectedTime) : null;

        const orderData = {
            userId: userId,
            price: stripeAmount,  // Montant en centimes
            type: "pickup",
            cartitems: cartitems,
            addressId: selectedAddress,
            deliveryTime: deliveryTime
        };

        console.log("Order data created:", orderData);
        return orderData;
    };

    const updateLastNewOrder = async (userId, stripeAmount) => {
        try {
            console.log("Fetching current user orders for user ID:", userId);
            const orders = await fetchCurrentUserOrders(userId);
            console.log("Current orders:", orders);

            const currentNewOrder = orders.find(order => order.status === 'new');
            if (currentNewOrder) {
                const updatedOrder = {
                    ...currentNewOrder,
                    status: 'accepted',
                    price: stripeAmount, // Montant en centimes
                    items: cartItems.map(item => ({ 
                        recipeId: item.id,
                        quantity: item.quantity,
                        price: item.price
                    }))
                };
                console.log("the updated Order: ", updatedOrder);

                const updatedOrderResult = await updateOrderById(currentNewOrder.id, updatedOrder);
                console.log("Order updated successfully:", updatedOrderResult);
                return currentNewOrder.id;
            } else {
                console.error("No 'new' order found for user.");
                return null;
            }
        } catch (error) {
            console.error("Error updating the order:", error);
            toast.error("An error occurred while updating the order.");
            setLoading(false);
            return null;
        }
    };

    const handleStripePayment = async () => {
        setLoading(true);
        
        const validation = validateUserAndAddress(userInfos, isChecked, selectedAddress, isInDeliveryZone);
        if (!validation) {
            setLoading(false);
            return;
        }

        try {
            console.log('Total TTC before conversion:', totalTTC);
            const stripeAmount = Math.round(totalTTC * 100); // Convertir en centimes
            console.log('Stripe Amount (in cents):', stripeAmount);

            const orders = await fetchCurrentUserOrders(userInfos.id);
            const currentNewOrder = orders.find(order => order.status === 'new');
            
            if (currentNewOrder) {
                const updatedOrder = {
                    ...currentNewOrder,
                    status: 'accepted',
                    price: stripeAmount,
                    items: cartItems.map(item => ({ 
                        id: item.id, 
                        name: item.name,
                        quantity: item.quantity, 
                        price: item.price 
                    }))
                };
                
                const updatedOrderResult = await updateOrderById(currentNewOrder.id, updatedOrder);
                console.log("Commande mise à jour avec succès:", updatedOrderResult);
            } else {
                console.error("Aucune commande avec le statut 'new' n'a été trouvée.");
                setLoading(false);
                return;
            }

            navigate('/checkout');
            toast.success('Paiement initié avec succès');
            
        } catch (error) {
            console.error("Erreur lors du traitement du paiement Stripe:", error);
            toast.error("Le paiement Stripe a échoué. Veuillez réessayer.");
        } finally {
            setLoading(false);
        }
    };

    const handlePaygreenPayment = async () => {
        try {
            setLoading(true);
            
            if (!validateUserAndAddress(userInfos, isChecked, selectedAddress, isInDeliveryZone)) {
                toast.error('Validation échouée. Veuillez vérifier vos informations.');
                return;
            }
            
            const paymentAmount = Math.round(totalTTC * 100); // Conversion en centimes
            if (isNaN(paymentAmount) || paymentAmount <= 0) {
                toast.error("Le montant total est invalide ou nul.");
                return;
            }

            const orderId = await updateLastNewOrder(userInfos.id, paymentAmount);
            if (!orderId) {
                toast.error("Erreur lors de la mise à jour de la commande.");
                return;
            }

            const paymentResult = await createInstantPayment(paymentAmount, "Paiement pour commande", orderId);
            if (paymentResult && paymentResult.instantPayment.data.hosted_payment_url) {
                toast.success('Redirection vers la passerelle de paiement...');
                window.location.href = paymentResult.instantPayment.data.hosted_payment_url;
            } else {
                throw new Error("Échec de la création du paiement Paygreen.");
            }

        } catch (error) {
            console.error("Erreur lors du traitement du paiement Paygreen:", error);
            toast.error("Le paiement avec Paygreen a échoué. Veuillez réessayer.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="order-validation-container">
            {loading && <div className="loader-overlay"><CircularProgress color="success" /></div>}
            <div className="order-validation-title flex-center"><h1>Voici votre récapitulatif {userInfos.firstname}</h1></div>
            <div className="order-validation-action flex-center">
                <Button className="btn-border btn-xl" text="Changer la commande" onClick={() => navigate('/home')} />
            </div>
            <div className="order-validation-items">
                {cartItems.length ? cartItems.map(item => <CartItem key={item.id} item={item} />) : <p>Votre panier est vide</p>}
            </div>
            <div className="order-validation-price flex-center"><OrderPrice /></div>
            <div className="flex-center"><TypeOfDelivery preorderEnabled={preorderEnabled} /></div>
            {preorderEnabled && <div className="order-validation-preorder">
                <div className="order-validation-date flex-center"><PreorderDate /></div>
                <div className="order-validation-addresses flex-center"><Addresses /></div>
            </div>}
            {!isInDeliveryZone && <div>
                <div className="order-validation-addresses flex-center"><Addresses postalCode={null} setLoading={setLoading} /></div>
            </div>}
            <div className="order-validation-condition flex-center">
                <OrderCondition isChecked={isChecked} setIsChecked={setIsChecked} />
            </div>
            <div className="order-validation-checkout flex-center">
               {/* <Button className="btn-border btn-xl" text="Payer par carte bancaire" onClick={handleStripePayment} /> */}
                <Button className="btn-border btn-xl" text="Régler la commande" onClick={handlePaygreenPayment} />
            </div>
        </div>
    );
}

export default OrderValidation;
