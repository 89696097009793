import React, { useState, useEffect } from "react";
import { useNavigate, Route, Routes, useParams } from "react-router-dom";

// Components
import AdminNavbar from "../../components/admin-dashboard/navbar/AdminNavBar";
import AllUsers from "./user-list/AllUsers";
import AllOrders from "./orders/AllOrders";
import Stock from "./stock/Stock";
import Recipes from "./recipes/Recipes";
import MenuList from "./menu-maker/MenuList";
import MenuMaker from "./menu-maker/menu/MenuMaker";
import UserTable from "../../components/admin-dashboard/user-list/MobileAllUsers"
import OrdersTable from "../../components/admin-dashboard/order-list/MobileAllOrders";
import MobileProduct from "../../components/admin-dashboard/stock/MobileProduct";
import MobileRecipeList from "../../components/admin-dashboard/recipes/mobile/MobileRecipeList";
import MobileMenuList from "../../components/admin-dashboard/menus/MobileMenuList";
import GroupOrders from "./group-orders/GroupOrders";

import useMediaQuery from '@mui/material/useMediaQuery';

// Styles
import "./AdminDashboard.css";

function AdminDashboard() {

    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");


    const { id } = useParams();

    const [selectedSection, setSelectedSection] = useState("users");

    const renderSection = () => {
        switch (selectedSection) {
            case "users":
                return isTablet ? (
                    <UserTable
                    />
                ) : (
                    <AllUsers
                    />
                );
            case "orders":
                return isTablet ? (
                    <OrdersTable
                    />
                ) : (
                    <AllOrders
                    />
                );
            case "group-orders":
                return isTablet ? (
                    <GroupOrders
                    />
                ) : (
                    <GroupOrders
                    />
                );
            case "stock":
                return isTablet ? (
                    <MobileProduct
                    />
                ) : (
                    <Stock
                    />
                );
            case "recipes":
                return isTablet ? (
                    <MobileRecipeList
                    />
                ) : (
                    <Recipes
                    />
                );
            case "menulist":
                return isTablet ? (
                    <MobileMenuList
                    />
                ) : (
                    <MenuList
                    />
                );
            case "menumaker":
                return <MenuMaker menuId={id} />;
            default:
                return null;
        }
    };

    return (
        <section className="admin-panel">
            <AdminNavbar onSelectSection={(section) => setSelectedSection(section)} />
            <section className="admin-dashboard container">
                {renderSection()}
            </section>
        </section>
    );
}

export default AdminDashboard;
