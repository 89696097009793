//Components
import GroupOrdersTable from "../../../components/admin-dashboard/group-orders/GroupOrdersTable";
import GroupOrdersMobile from "../../../components/admin-dashboard/group-orders/GroupOrdersMobile";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function GroupOrders() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");


    return (
        <div className="orders-table">
            <div className="orders-table-title flex-center">
                <h1 style={{ margin: "1rem 0", textAlign: isMobile ? "center" : "inherit" }}>
                    Liste des commandes groupées
                </h1>
            </div>
            {isMobile ? (
                <GroupOrdersMobile />
            ) : (
                <GroupOrdersTable />
            )}
        </div>

    );
}
