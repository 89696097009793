import React from 'react';
import Popup from 'reactjs-popup';

import './PopupConfirm.css'

function PopupConfirm({ trigger, onConfirm, message }) {
    return (
        <Popup
            trigger={trigger}
            position="right center"
            modal
            overlayStyle={{ background: 'rgba(0,0,0,0.7)' }}
            contentStyle={{ zIndex: 99999 }}

        >
            {close => (
                <div className="popup-confirm-content">
                    <div className="popup-text">{message}</div>
                    <div className="popup-actions flex-center">
                        <button className='btn-full btn-sm' onClick={() => { onConfirm(); close(); }}>Oui</button>
                        <button className='btn-border btn-sm' onClick={close}>Non</button>
                    </div>

                </div>
            )}
        </Popup>
    );
}

export default PopupConfirm;