import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPreorderEnabled, setSelectedDate, setSelectedTime } from '../../features/preorder/preorderSlice';
import { FormGroup, FormControlLabel, Switch, Tooltip, Button, Typography, ThemeProvider } from "@mui/material";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { createTheme } from '@mui/material/styles'
import { IoIosInformationCircle } from "react-icons/io";
import dayjs from 'dayjs';



function PreOrder() {
    const dateFormat = "DD-MM-YYYY";
    const format = 'HH:mm:ss';
    const dispatch = useDispatch();

    // Global States
    const preorderEnabled = useSelector((state) => state.preorder.preorderEnabled);
    const selectedDate = useSelector((state) => state.preorder.selectedDate);
    const selectedTime = useSelector((state) => state.preorder.selectedTime);

    const currentDay = dayjs();
    const dayPlus2 = currentDay.add(1, 'day');


    // Effect to run on initial render to fetch values from localStorage
    useEffect(() => {
        const storedDate = localStorage.getItem('selectedDate');
        const storedTime = localStorage.getItem('selectedTime');

        // If values exist in localStorage, dispatch actions to set them in the Redux state
        if (storedDate) {
            dispatch(setSelectedDate(storedDate));
        }

        if (storedTime) {
            dispatch(setSelectedTime(storedTime));
        }
    }, [dispatch]);



    const handleSwitchChange = () => {
        const newPreorderValue = !preorderEnabled;
        dispatch(setPreorderEnabled(newPreorderValue));
    };

    const handleDateChange = (date) => {
        const formattedDate = date.format(dateFormat);
        dispatch(setSelectedDate(formattedDate));
        localStorage.setItem('selectedDate', formattedDate);
    };

    const shouldDisableDate = (date) => {
        // Convert date format
        const formattedDate = date.format(dateFormat);
        return date.isBefore(dayPlus2);
    };


    const handleTimeChange = (time) => {
        const formattedTime = time ? time.format(format) : null;
        dispatch(setSelectedTime(formattedTime));
        localStorage.setItem('selectedTime', formattedTime);
    };

    return (
        <div className="recipes-nav-preorder flex-center">
            <FormGroup className='preoder-actions'>
                <div className='flex-center'>
                    <FormControlLabel
                        control={<Switch checked={preorderEnabled} onChange={handleSwitchChange} />}
                        label={(
                            <span className='preoder-infos' >
                                <Tooltip
                                    title={
                                        <>
                                            <div className='preorder-tooltip'>
                                                <Typography color="inherit">Les précommandes sont dispo 🥳</Typography>
                                                <em>
                                                    {"Votre recette préférée est indisponible ? Pas de panique. Vous pouvez à présent"}
                                                    <b>{' pré-commander'}</b> {'sur Mamossapp'}.{' '}
                                                    <br /><br />
                                                    <b>{"Comment faire ?"}</b>
                                                    <br />{"Activez la précommande en cliquant sur le bouton, choisissez votre créneau et ajoutez les  recettes au panier"}
                                                </em>
                                            </div>
                                        </>
                                    }
                                >
                                    <Button className='preoder-info-btn'>Pré-commande{" "}
                                        <IoIosInformationCircle />
                                    </Button>
                                </Tooltip>
                            </span>
                        )}
                        className='recipes-nav-switch'
                    />
                </div>


                {preorderEnabled && (
                    <div className='date-time-pickers'>

                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DemoContainer components={['DatePicker', 'TimePicker']}>
                                <DatePicker placeholder="Date"
                                    value={dayjs(selectedDate)}
                                    onChange={handleDateChange}
                                    format={dateFormat}
                                    className="white-datepicker"
                                    shouldDisableDate={shouldDisableDate}
                                />
                                <TimePicker placeholder="Heure"
                                    format={format}
                                    value={selectedTime}
                                    onChange={handleTimeChange}
                                    ampm={false}
                                    variant="standard"
                                    className="white-timepicker"
                                />
                            </DemoContainer>
                        </LocalizationProvider>

                    </div>
                )}
            </FormGroup>
        </div>
    );
}

export default PreOrder;